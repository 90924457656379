//React
import React from 'react';
//Fluent UI
import { Text } from "@fluentui/react";

export default class TableColumns{

  constructor(props){
    this.props = props;
  }


  getColumns = () =>{
    return [           
    {
      key: 'column1',
      name: 'Pozíció száma',
      fieldName: 'pozicio',
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.pozicio}</Text>)
    },
    {
      key: 'column2',
      name: 'Mennyiség (Darab)',
      fieldName: 'mennyiseg',
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.mennyiseg}</Text>)
    },
    {
      key: 'column3',
      name: 'Probléma leírása',
      fieldName: 'leiras',
      minWidth: 350,
      maxWidth: 350,
      isResizable: true,
      isPadded: true,
      data: 'string',
      onRender: (item) => (<Text>{item.leiras}</Text>)
    },
    {
      key: 'column4',
      name: 'Probléma kezelésének módja',
      fieldName: 'problemaKezelese',
      minWidth: 350,
      maxWidth: 350,
      isResizable: true,
      isPadded: true,
      data: 'string',
      onRender: (item) => (<Text>{item.problemaKezelese}</Text>)
    },
    {
      key: 'column5',
      name: 'Jóváhagyó',
      fieldName: 'jovahagyoNev',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isPadded: true,
      data: 'string',
      onRender: (item) => (<Text>{item.jovahagyoNev}</Text>)
    },
    {
      key: 'column6',
      name: 'Dátum',
      fieldName: 'ido',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isPadded: true,
      data: 'string',
      onRender: (item) => (<Text>{item.ido}</Text>)
    },
    ]
  }
}