import React, { Component } from 'react';
//Material UI
import { 
    Grid,
    Button,
    ButtonGroup,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    CircularProgress,
    DialogContentText 
} from "@mui/material";
//Material UI Icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
//Components
import {EditWorkPage, ViewWorkPage} from 'components';
import { DataTable } from 'components/Lists';
import { FilterPanel } from 'components/Surfaces';
import { Workpages, EditGroup, NewGroup, Attachments } from './components';
//Models
import {FilterModel} from 'components/Models';
//Table columns
import TableColumns from './table_data';
//Api
import { apiRequest } from './apiRequest';


class SetUpGroup extends Component {


    state = {
        selected:[],
        request:false,
        items:[],
        filters:[new FilterModel('id','Azonosító','','text'),
        new FilterModel('name','Megnevezés','','text')],
        loading: true,
        columns:[],    
        deleteAction:null,
        editItem:null,
        workpages:null,
        dialogs:{edit:null,view:null,attachments:null},
        newItem:null
    }

    componentDidMount() {
        const {selected,request} = this.state;
        this.setColumns(new TableColumns({onWorkpages:this.setWorkpages,checkboxProps:{selected,deleteRequest:request,onSelectChange:this.handleSelectChange},onEdit:this.setEditItem,onDialog:this.handleDialog}).getColumns());

        apiRequest.populateGroups(this,{}).then(res=>{
            this.setLoading(false)
        });
    }

    componentDidUpdate(prevProps,prevState){
        const {filters,selected,request,items} = this.state;
    
        if(prevState.selected!=selected){
          this.setColumns(new TableColumns({onWorkpages:this.setWorkpages,checkboxProps:{selected,deleteRequest:request,onSelectChange:this.handleSelectChange},onEdit:this.setEditItem,onDialog:this.handleDialog}).getColumns());
        }
    
        if(prevState.request!=request){
          this.setColumns(new TableColumns({onWorkpages:this.setWorkpages,checkboxProps:{selected,deleteRequest:request,onSelectChange:this.handleSelectChange},onEdit:this.setEditItem,onDialog:this.handleDialog}).getColumns());
        }
    
        if(prevState.items!=items){
          if(items.length<1){
            this.setSelected([]);
          }
       
        }
    }

    /*==== Setter methods ====*/
        setRequest = (request) =>{
            this.setState({
                request
            });
        }

        setColumns = (columns) =>{
            this.setState({
            columns
            });
        }

        setItems = (items) =>{
            this.setState({
            items
            });
        }

        setSelected = (selected) =>{
            this.setState({
            selected
            });
        }

        setDeleteAction = (deleteAction) =>{ 
            this.setState({
            deleteAction
            })
        }

        setLoading = (loading) =>{
            this.setState({
            loading
            });
        }

        setFilters = (filters) =>{
            this.setState({
                filters
            });
        }

        setWorkpages = (workpages) =>{
            this.setState({
                workpages
            });
        }

        setEditItem = (editItem) =>{
            this.setState({
                editItem
            });
        }

        setNewItem = (newItem) =>{
            this.setState({
                newItem
            });
        }
    /*=========================*/

    /*==== Helper methods ====*/
        _refresh=()=>{
            const {filters} = this.state;
        
            //Set filter model
            let model;
            filters.map(item=>{ model = {...model,[item.name]:item.value}});
        
            this.setLoading(true);
        
            apiRequest.populateGroups(this,model).then(res =>       
              this.setLoading(false)
            )
          }
    /*=========================*/

    /*==== Handle user interactions ====*/
        handleSelectChange = (checked,item) =>{
            var result = this.state.selected;
            if(checked){
                result.push(item.id);
            }else{
                var index = result.indexOf(item.id)
                if (index > -1) {
                result.splice(index, 1);
                }
            }
        
            this.setSelected(result);
        }

        handleCancelDelete=()=>{
            this.setDeleteAction(null);
        }

        handleDialog = (props, value) => {
            this.setState(prevState=>({
                dialogs:{
                    ...prevState.dialogs,
                    [props]:value 
                }
            }));
        }
    /*===================================*/

    /*==== Handle events ====*/
    handleDelete = () =>{
        const {selected} = this.state;
        this.setRequest(true);
        
        //Call api
        apiRequest.deleteGroups(this,selected).then(res=>{   
          this.setDeleteAction(null);    
          this.setSelected([]);
          this._refresh();
          this.setRequest(false);           
        }).catch(error=>{
          this.setRequest(false);
        });
    }
    /*========================*/


    render() {
        const { selected, loading, filters, items, columns, deleteAction, request, workpages, dialogs, editItem, newItem } = this.state;
        const { snackbar } = this.props;

        return ( 
            <div>
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <FilterPanel 
                            loading={loading}
                            onSearch={this._refresh}
                            onChange={this.setFilters} 
                            filters={filters}/>  
                        <div style={{ boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)' }}>
                            <div style={{ display:'flex',aligItems:'center',justifyContent:'space-between',padding:'1rem',backgroundColor:'white' }}>
                                <div>
                                <ButtonGroup size="small">
                                    <Button 
                                        onClick={this.setNewItem}  
                                        endIcon={<AddCircleIcon/>} 
                                        color="primary" 
                                        size="small" 
                                        variant="contained"
                                    >
                                        Új gyártás
                                    </Button>
                                    <Button 
                                        onClick={this.setDeleteAction} 
                                        disabled={selected.length<1} 
                                        endIcon={<DeleteIcon/>} 
                                        color="error" 
                                        size="small" 
                                        variant="contained"
                                    >
                                        Törlés
                                    </Button>
                                </ButtonGroup>
                                </div>
                                <div>
                                    <ButtonGroup size="small">
            
                                    <Button 
                                        onClick={this.openInfoCallout} 
                                        size="small" 
                                        variant="text"
                                    >
                                        <InfoOutlinedIcon color='info' fontSize='small'/>
                                    </Button>
                                    <Button 
                                        onClick={this._refresh} 
                                        size="small" 
                                        variant="text"
                                    >
                                        <RefreshOutlinedIcon color='info'  fontSize='small'/>
                                    </Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                        </div>  
                        <DataTable
                            loading={loading} 
                            pagination={50} 
                            items={items}
                            length={items.length} 
                            selected={selected} 
                            columns={columns}
                            setItems={this.setItems}
                        />
                    </Grid> 
                </Grid>
                {newItem!=null && <NewGroup parentRefresh={this._refresh} onClose={()=>this.setNewItem(null)}/>}
                {dialogs.attachments != null && <Attachments opened={dialogs.attachments}  open={true} onClose={(event)=>this.handleDialog('attachments',null)}/>}
                {dialogs.open != null && 
                <ViewWorkPage 
                    opened={dialogs.open} 
                    open={true} 
                    handleClose={(event)=>this.handleDialog('open',null)} />} 
                {dialogs.edit != null && 
                <EditWorkPage 
                    addsOn={{workpage:false,position:true}} 
                    snackbar={snackbar} 
                    opened={dialogs.edit} 
                    open={true} 
                    handleClose={(event) => this.handleDialog('edit', null)} />} 
                {editItem!=null && 
                <EditGroup 
                    refreshParent={this._refresh} 
                    editable={editItem} 
                    onClose={()=>this.setEditItem(null)}/>}
                {workpages!==null && 
                <Workpages 
                        onView={this.handleDialog} 
                        files={workpages.item.pages} 
                        workpages={workpages} 
                        onClose={()=>this.setWorkpages(null)}/>}
                <Dialog open={deleteAction!==null}>
                    <DialogTitle>
                        Megerősítés
                    </DialogTitle>
                    <DialogContent dividers={true} style={{textAlign:'center'}} >
                    <DialogContentText>
                        {request ? 'Törlés folyamatban...' : 'Biztos törölni szeretnéd ?'}
                    </DialogContentText>
                        {request  && <CircularProgress size={'2.0rem'}/>}
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            disabled={request} 
                            size='small' 
                            variant='contained' 
                            color='error' 
                            onClick={this.handleCancelDelete}
                        >
                        Nem
                        </Button>
                        <Button 
                            disabled={request} 
                            size='small' 
                            variant='contained' 
                            color="primary" 
                            onClick={this.handleDelete}
                        >
                        Igen
                        </Button>
                    </DialogActions>
                </Dialog>                                                    
            </div>)
    }
}
export default SetUpGroup;