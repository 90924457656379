import React, { createRef } from 'react';
///Material UI
import { 
    Button,
    Typography,
    ButtonGroup 
} from '@mui/material';
//Fluent UI
import Dropzone from 'react-dropzone';
//Components
import {
    ViewWorkPage,
    UploadDialog,
    ErrorDialog,
    ShowMore
} from './components';
//Components
import { DataTable } from 'components/Lists';
import { apiRequest } from './apiRequest';
//Table columns
import TableColumns from './table_data';
//Icons
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';





const Store = (props) => {

    const { onRefresh } = props;

    const [files, setFiles] = React.useState([]);
    const [uploadSucces, setUploadSucces] = React.useState([]);
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [uploadOpen, setUploadOpen] = React.useState(false);
    const [uploadProgress, setUploadProgress] = React.useState([]);

    const [viewFile, setViewFile] = React.useState(null);
    const [selected, setSelected] = React.useState([]);
    const [showMore,setShowMore] = React.useState(null);

    const [columns,setColumns] = React.useState(new TableColumns({showMore,onShowMore:setShowMore,onDelete:handleDelete,checkboxProps:{selected,onSelectChange:handleSelectChange}}).getColumns())

    React.useEffect(()=>{
        setColumns(new TableColumns({showMore,onShowMore:setShowMore,onDelete:handleDelete,checkboxProps:{selected,onSelectChange:handleSelectChange}}).getColumns());
    },[showMore,selected])

    React.useEffect(()=>{
        setColumns(new TableColumns({showMore,onShowMore:setShowMore,onDelete:handleDelete,checkboxProps:{selected,onSelectChange:handleSelectChange}}).getColumns());
    },[files])


   
    /*==== Drop zone function ====*/
        const dropzoneRef = createRef();

        const onDropAccepted = (acceptedFiles) => {
            var temp = [...files];
            for (var i = 0; i < acceptedFiles.length; i++) {
                const file = acceptedFiles[i];
                const name = acceptedFiles[i].name.split('.').reverse()[1];
                const filtered = temp.find(item => item.name === name);
                const index = temp.indexOf(filtered);
                if (index > -1) {
                    if (temp[index].subFiles.find(item => item.name === acceptedFiles[i].name) === undefined) {
                        temp[index].subFiles.push(file);
                    }
                } else {
                    const model = { name, isValid: false, missing: null, subFiles: [file] }
                    temp.push(model);
                }
            }
            temp.map(item => {
                if (item.subFiles.length === 2) {
                    item.isValid = true;
                } else {
                    var pdfResult = item.subFiles.find(item => item.name.includes('.pdf'));
                    if (pdfResult !== undefined) {
                        var csvResult = item.subFiles.find(item => item.name.includes('.csv'));
                        if (csvResult === undefined) {
                            item.missing = 'csv';
                        }
                    } else {
                        item.missing = 'pdf';
                    }
                    item.isValid = false;

                }
            });
            setFiles(temp);
        };

        const onDropRejected = (rejectedFiles) => {
            if (rejectedFiles.length > 0) {
                setErrorOpen(true);
            } else {
                setErrorOpen(false);
            }
        }

        
        const openDialog = () => {
            // Note that the ref is set async,
            // so it might be null at some point 
            if (dropzoneRef.current) {
                dropzoneRef.current.open()
            }
        };

    /*=============================*/

  
  
    /*==== Handle user interactions ====*/

        function handleSelectChange(checked,item){
            var result = [...selected];
            if(checked){
                result.push(item.name);
            }else{
                var index = result.indexOf(item.name)
                if (index > -1) {
                result.splice(index, 1);
                }
            }
            setSelected(result);
        }


        const handleUpload = (event) => {
            let localFiles = [...files];
            if (selected.length>0) {
                var temp = [];
                localFiles.map(file => {
                    if (selected.filter(selectedFile => file.name == selectedFile).length>0) {
                        temp.push(file);
                    }
                });
                send(temp);
                
            } else {
                send(temp);
            }
            setUploadOpen(true);      
        }

        const handleErrorClose = () => {
            setErrorOpen(false);
        }
    
        const handleUploadClose = () => {
            setUploadOpen(false);
        }

        const handleClose = () => {
            setViewFile(null);
        }
    
        const handleView = (file) => {
            setViewFile(file);
        }
    
        function handleDelete(file){
            const temp = [...files].filter(item => file.name !== item.name);
            setFiles(temp);    
        }

        const handleSubDelete = (file, subFile) => {

            var temp = [...files];
            const index = temp.indexOf(file);
            const subFiles = temp[index].subFiles.filter(item => item !== subFile);
            if (subFiles.length > 0) {
                temp[index].subFiles = subFiles;
                temp.map(item => {
                    if (item.subFiles.length === 2) {
                        item.isValid = true;
                    } else {
                        var pdfResult = item.subFiles.find(item => item.name.includes('.pdf'));
                        if (pdfResult !== undefined) {
                            var csvResult = item.subFiles.find(item => item.name.includes('.csv'));
                            if (csvResult === undefined) {
                                item.missing = 'csv';
                            }
                        } else {
                            item.missing = 'pdf';
                        }
                        item.isValid = false;
    
                    }
                });
                setShowMore({...showMore,item:temp[index]});
            } else {
                setShowMore(null);
                temp = [...files].filter(item => file !== item);
            }
            setFiles(temp);
        }
    
        const handleSelectedDelete = () => {
            var temp = [];
            [...files].map(file => {
                if (selected.filter(selectedFile => file.name == selectedFile).length < 1) {
                    temp.push(file);
                }
            });         

            setFiles(temp);
            setSelected([]);
        }    

    /*===================================*/

    
    /*==== Send files to the server ====*/
        const uploadPost = async (file, uploadProgress) => { 
            var data = new FormData();
            data.append('name', file.name);
            file.subFiles.map(subFile => {
                data.append('files[]', subFile);
            });

            await apiRequest.upload(data, uploadProgress, uploadSucces, setUploadProgress, setUploadSucces);
        }

        async function queueAsyncFns(fns) {
            const values = [];

            await fns.reduce((previous, current, index, array) => {
                const thenable = index === 1 ? previous() : previous;
                return thenable.then(value => {
                    values.push(value);
                    return index === array.length - 1 ? current().then(value => values.push(value)) : current();
                });
            });

            return values;
        }

        const send = (_files) =>{
            var uploadProgress = _files.map(file => { return { name: file.name, success: null, progressMessage:null, warningMessage:null, } });
            setUploadProgress(uploadProgress);
            if(_files.length==1){
                uploadPost(_files[0],uploadProgress).then(()=>{ let difference = [...files].filter(x => [...uploadSucces].filter(y=>y==x.name).length<1);
                let selectedDifference = [...selected].filter(x => [...uploadSucces].filter(y=>y==x).length<1);
                setFiles(difference);
                setSelected(selectedDifference);
                setUploadSucces([]);
                onRefresh();
            });
            }else{
                queueAsyncFns(_files.map((file) => () => uploadPost(file,uploadProgress))).then(()=>{    
                let difference = [...files].filter(x => [...uploadSucces].filter(y=>y==x.name).length<1);
                let selectedDifference = [...selected].filter(x => [...uploadSucces].filter(y=>y==x).length<1);
                setFiles(difference);
                setSelected(selectedDifference);
                setUploadSucces([]);
                onRefresh();
            });
            }          
        }

    /*==================================*/
    return (<div>
                <Dropzone  
                    onDropRejected={onDropRejected} 
                    onDropAccepted={onDropAccepted} 
                    accept={'.pdf, .csv'} 
                    ref={dropzoneRef} 
                    multiple 
                    noClick 
                    noKeyboard
                >
                    {({ getRootProps, fileRejections, isDragActive, getInputProps }) => {
                        return (<div>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        {
                                            isDragActive ?
                                            <div style={{ backgroundColor: 'white',boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',textAlign:'center' }}>
                                                <div style={{borderBottom:'1px solid #eeeeee',backgroundColor:'#fafafa',padding:'0.5rem'}}>
                                                    <Typography style={{ fontWeight:'600',fontSize:'1rem'}}>
                                                        Dobd a file-okat ide...
                                                    </Typography>
                                                    <Typography color="secondary" style={{ fontWeight:'400',fontSize:'0.8rem'}}>
                                                        Elfogadott formátumok: .pdf, .csv
                                                    </Typography>
                                                </div>
                                                <div style={{minHeight:'10rem',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#ecfacc'}}>
                                                    <FileCopyIcon />
                                                </div>
                                            </div> :
                                            <div style={{ backgroundColor: 'white',boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)', textAlign:'center' }}>
                                                <div style={{borderBottom:'1px solid #eeeeee',backgroundColor:'#fafafa',padding:'0.5rem'}}>
                                                    <Typography style={{ fontWeight:'600',fontSize:'1rem'}}>
                                                        Húzd ide a file-okat a feltöltéshez vagy kattints ide:
                                                    </Typography>                                   
                                                    <Button
                                                        style={{padding:'1rem 1rem'}} 
                                                        startIcon={<AddCircleOutlineIcon/>} 
                                                        color="primary" 
                                                        variant='contained' 
                                                        onClick={openDialog} 
                                                        size="medium"
                                                    >
                                                        File(ok) kiválasztása
                                                    </Button>
                                                    <Typography color="secondary" style={{ fontWeight:'400',fontSize:'0.8rem'}}>
                                                        Elfogadott formátumok: .pdf, .csv
                                                    </Typography>
                                                </div>
                                                <div style={{minHeight:'10rem',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                    <FileCopyIcon />
                                                </div>
                                            </div>
                                        }
                                </div>
                                <ErrorDialog 
                                    handleClose={handleErrorClose} 
                                    fileRejections={fileRejections} 
                                    open={errorOpen} />
                            </div>
                        );
                    }}
                </Dropzone>
                   
                <div style={{marginTop:'2rem'}}>
                {files.length>0 && 
                <div style={{ display:'flex',aligItems:'center',justifyContent:'space-between',padding:'1rem',backgroundColor:'white' }}>
                    <div>
                    </div>
                    <div>
                      {selected.length>0 && 
                      <ButtonGroup size="small">
                          <Button
                            onClick={handleSelectedDelete} 
                            style={{fontSize:'0.75rem'}}                         
                            startIcon={<DeleteIcon fontSize='small'/>}
                            size="small" 
                            color="error"
                            variant="contained"
                          >
                            Törlés
                          </Button>
                          <Button 
                            onClick={handleUpload}
                            style={{fontSize:'0.75rem'}}
                            endIcon={ <UploadIcon fontSize='small'/>}
                            size="small" 
                            color="primary"
                            variant="contained"
                          >
                           Feltöltés
                          </Button>
                        </ButtonGroup>}
                    </div>
                </div>}
                    <DataTable
                        pagination={10} 
                        items={files}
                        setItems = {setFiles}
                        length={files.length} 
                        selected={selected} 
                        columns={columns}
                    />
                    {showMore!==null && 
                        <ShowMore 
                            onView={handleView} 
                            onSubDelete={handleSubDelete}  
                            files={showMore.item.subFiles} 
                            showMore={showMore} 
                            onClose={()=>setShowMore(null)}/>}
                </div>
                {viewFile !== null && 
                <ViewWorkPage 
                    file={viewFile} 
                    open={true} 
                    handleClose={handleClose} />}
                < UploadDialog
                    data={uploadProgress} 
                    open={uploadOpen} 
                    handleClose={handleUploadClose} />
            </div>
        );
};

export default Store;
