import React, { Component } from "react";
//Fluent UI
import { Pivot, PivotItem, Label } from '@fluentui/react';
//Material UI
import { Icon } from "@mui/material";
//Components
import { PlasticFromGroup, MetalFromGroup, FFFromGroup } from './components';
//Material UI Icons
import StoreIcon from '@mui/icons-material/Store';


class FromGroup extends Component{

  constructor(props){
    super(props);

    this.state={
      selectedKey: "1"
    }
  }

  handleChange = (item,event) =>{
    this.setState({
      selectedKey: item.props.itemKey
    })
  }

    render(){
      const {isDesktop} = this.props;
      const {selectedKey} = this.state;

        return( 
          <Pivot onLinkClick={this.handleChange} selectedKey={selectedKey}>
             {isDesktop && <PivotItem itemKey="0" headerText="Összekészítés" onRenderItemLink={_customRenderer}  headerButtonProps={{'disabled': true}}/>}
            <PivotItem itemKey="1"  headerText="Műanyag gyártás">
              <PlasticFromGroup/>
            </PivotItem>
            <PivotItem itemKey="2" headerText="Alumínium gyártás">
              <MetalFromGroup/>
            </PivotItem>
            <PivotItem itemKey="3" headerText="Függönyfal gyártás">
              <FFFromGroup/>
            </PivotItem>
          </Pivot>
        )
    }
}

function _customRenderer(link,defaultRenderer){
  if (!link || !defaultRenderer) {
    return null;
  }

  return (
    <span style={{display:'flex', alignItems:'center', gap:'0.5rem' }}>
      <Icon style={{height:'100%'}} color="primary">
          <StoreIcon/>
      </Icon>
      <Label style={{fontWeight:'bold', fontSize:'1rem'}}>
          {link.headerText}
      </Label>
    </span>
  );
}

export default FromGroup;