import {Api,handleResponse,handleError} from 'api';
const Actions = {
    OpenPdfWithName:'mMNumbers/pdf', //+{pdfName}
    OpenPdfWithId: 'mMNumbers/pdf', //+{Id}
    OpenMetalPdfWithName:'metal/mMNumbers/pdf', //+{pdfName}
    OpenMetalPdfWithId: 'metal/mMNumbers/pdf', //+{Id}
    OpenFFPdfWithName:'curtain-wall/mMNumbers/pdf', //+{pdfName}
    OpenFFPdfWithId: 'curtain-wall/mMNumbers/pdf', //+{Id}
}

export const apiRequest = {
    populatePdf,
    populateMetalPdf,
    populateFFPdf
};

async function populatePdf(parent) {
    const { workpageId } = parent.props;
    if (isNaN(workpageId)) {
        await new Api().call.get(`${Actions.OpenPdfWithName}/${workpageId}`).then(handleResponse)
            .then((value) => {
                //Create arraybuffer
                if (value.file != "") {
                    parent.initPdf(value)
                }
            }).catch(handleError);
    } else {
        await new Api().call.get(`${Actions.OpenPdfWithId}/${workpageId}`).then(handleResponse)
            .then((value) => {
                //Create arraybuffer
                if (value.file != "") {
                    parent.initPdf(value)
                }
            }).catch(handleError);
    }
}

async function populateMetalPdf(parent) {
    const { workpageId, typeId } = parent.props;
    if (isNaN(workpageId)) {
        await new Api().call.get(`${Actions.OpenMetalPdfWithName}/${typeId}/${workpageId}`).then(handleResponse)
            .then((value) => {
                //Create arraybuffer
                if (value.file != "") {
                    parent.initPdf(value)
                }
            }).catch(handleError);
    } else {
        await new Api().call.get(`${Actions.OpenMetalPdfWithId}/${typeId}/${workpageId}`).then(handleResponse)
            .then((value) => {
                //Create arraybuffer
                if (value.file != "") {
                    parent.initPdf(value)
                }
            }).catch(handleError);
    }
}

async function populateFFPdf(parent) {
    const { workpageId, typeId } = parent.props;
    if (isNaN(workpageId)) {
        await new Api().call.get(`${Actions.OpenFFPdfWithName}/${typeId}/${workpageId}`).then(handleResponse)
            .then((value) => {
                //Create arraybuffer
                if (value.file != "") {
                    parent.initPdf(value)
                }
            }).catch(handleError);
    } else {
        await new Api().call.get(`${Actions.OpenFFPdfWithId}/${typeId}/${workpageId}`).then(handleResponse)
            .then((value) => {
                //Create arraybuffer
                if (value.file != "") {
                    parent.initPdf(value)
                }
            }).catch(handleError);
    }
}





