//Theme
import { createTheme } from '@mui/material/styles';
import { colors } from '@mui/material';

const white = '#FFFFFF';
const black = '#000000';
const appMain ='#84BD00';
const appSecondary ='#8A8A8D';

const theme = createTheme({
    palette: {
        black,
        white,
        appMain: {
            contrastText: white,
            dark: '#598000',
            main: appMain,
            light: '#A1E600'
        },
        appSecondary: {
            contrastText: white,
            dark: '#7D7D80',
            main: appSecondary,
            light: '#E1E1E6'
        },
        primary: {
          contrastText: white,
            dark: '#598000',
            main: appMain,
            light: '#A1E600'
        },
        secondary: {
          contrastText: white,
            dark: '#7D7D80',
            main: appSecondary,
            light: '#E1E1E6'
        },
        success: {
          contrastText: white,
          dark: colors.green[900],
          main: colors.green[600],
          light: colors.green[400]
        },
        info: {
          contrastText: white,
          dark: colors.blue[900],
          main: colors.blue[600],
          light: colors.blue[400]
        },
        warning: {
          contrastText: white,
          dark: colors.orange[900],
          main: colors.orange[600],
          light: colors.orange[400]
        },
        menuButton:{
          contrastText: white,
          dark: colors.blueGrey[900],
          main: colors.blueGrey[800],
          light: colors.blueGrey[600]
        },
        error: {
          contrastText: white,
          dark: colors.red[900],
          main: colors.red[600],
          light: colors.red[400]
        },
        text: {
          primary: colors.blueGrey[900],
          secondary: colors.blueGrey[600],
          link: colors.blue[600]
        },
        background: {
          default: '#F4F6F8',
          paper: white
        },
        icon: colors.blueGrey[600],
        divider: colors.grey[200]
    },
    typography: {
      fontFamily: [
        'Nunito', 'sans-serif'
      ].join(','),
    },
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            borderRadius: '0rem',
          },
        },
      },
      MuiDialog:{
        styleOverrides: {
          // Name of the slot
          paper: {
            // Some CSS
            borderRadius: '0rem',
          },
        },
      },
      MuiDialogTitle:{
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            backgroundColor: appMain,
            color:'white',
            boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
            padding:'0.2rem 1rem', 
            display:'flex',
            alignItems:'center',
            justifyContent:'space-between'
          },
        },
      },
      MuiOutlinedInput:{
        styleOverrides: {
          // Name of the slot
          root: {
            borderRadius:'0px'
          }
        }
      }
    },
  });

export default theme;