import React,{Component} from 'react'
//FluentUI
import { IconButton } from 'office-ui-fabric-react';
import { Label } from '@fluentui/react';
//Material UI
import { Divider } from "@mui/material";
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import LastPageOutlinedIcon from '@mui/icons-material/LastPageOutlined';
import FirstPageOutlinedIcon from '@mui/icons-material/FirstPageOutlined';
//Style
import './style.css';


class Pagination extends Component{

    constructor(props){
        super(props);
    }

    validateNext = () =>{
    //const {last} = this.state;
    const {length,last} = this.props; 
        if(last<length){
            return false;
        }
      return true;
    }

    validatePrevious = () =>{
        const {page} = this.props;
            if(page>1){
                return false;
            }
        return true;
    }

    render(){
        //const {page,first,last} = this.state;
        const {length,loading,first,last,parent} = this.props; 
        return(         
             <div className='pagination-container'> 
             {/*loading && <Spinner className='pagination-loading' label="Betöltés..." ariaLive="assertive" labelPosition="right" />*/}
                 <div className='pagination-container-pages'>
                    <Label >Össz: {length}</Label>   
                    <Divider orientation="vertical" flexItem/>
                    <Label>{`${first}-${last}`}</Label>
                    <Divider orientation="vertical" flexItem/>
                </div>
                <div className='pagination-container-nav'>
                    <IconButton
                    disabled={this.validatePrevious()} 
                    onClick={parent.handleFirst}  
                    title="Legelső" ariaLabel="First" 
                    >
                        <FirstPageOutlinedIcon fontSize='small'/>
                    </IconButton>
                    <IconButton
                    disabled={this.validatePrevious()}
                    onClick={parent.handlePrevious}  
                    title="Előző" ariaLabel="Left" 
                    >
                        <ArrowCircleLeftOutlinedIcon fontSize='small'/>
                    </IconButton>
                    <IconButton
                    disabled={this.validateNext()}
                    onClick={parent.handleNext} 
                    title="Következő" ariaLabel="Right" 
                    >
                        <ArrowCircleRightOutlinedIcon fontSize='small'/>
                    </IconButton>
                    <IconButton
                    disabled={this.validateNext()}  
                    onClick={parent.handleLast}   
                    title="Utolsó" ariaLabel="Last" 
                    >
                        <LastPageOutlinedIcon fontSize='small'/>
                    </IconButton>             
                </div>
             </div>);
    }
}

export default Pagination;