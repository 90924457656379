import React, { Component } from 'react';
import {Constants} from 'stores';
//Api
import { apiRequest } from './apiRequest';


export default class Workpage extends Component {

    constructor(props) {
        super(props);

    }
    componentDidMount() {
        if(this.props.withPositions){
            apiRequest.populatePdf(this);
        }else{
            apiRequest.populateOnePdf(this);
        }
      
    }

    componentDidUpdate() {
        if(this.props.withPositions){
            apiRequest.populatePdf(this);
        }else{
            apiRequest.populateOnePdf(this);
        }
    
    }

    handleBack = () => {
        this.props.history.goBack();
    };

    initPdf(value) {
        var binary_string = window.atob(value.file);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        //show pdf document from arraybuffer 
        var adobeDCView = new window.AdobeDC.View({ clientId: Constants.getPdfClient(), divId: "adobe-dc-view" });
        adobeDCView.previewFile({
            content: { promise: Promise.resolve(bytes.buffer) },
            metaData: { fileName: this.props.workpageName }
        }, { defaultViewMode: "FIT_WIDTH", dockPageControls: false, showAnnotationTools: false })
    }

    render() {
        return (<div id="adobe-dc-view" style={{ height: "100%",minHeight:'800px' }}></div>)
    }

  
}
