//React
import React from 'react';
//Fluent UI
import { 
  Text
} from "@fluentui/react";
//Material UI
import { 
    Typography,
    Tooltip
} from "@mui/material";
//Material UI Icons
import WarningIcon from '@mui/icons-material/Warning';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


export default class TableColumns{

  constructor(props){
    this.props = props;
  }


  getColumns = () =>{
    const {onOpen} = this.props;
    return [
      {
      key: 'column1',
      name: '#',
      fieldName: 'id',
      minWidth: 20,
      maxWidth: 20,
      isRowHeader: true,
      isResizable: true,
      data: 'number',
      isPadded: true,
      onRender: (item) => (<Text>{item.id}</Text>)
    },             
    {
      key: 'column2',
      name: 'Megnevezés',
      fieldName: 'name',
      minWidth: 120,
      maxWidth: 120,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (<Text>{item.name}</Text>)
    },
    {
      key: 'column3',
      name: 'Szabász',
      fieldName: 'cutter',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (
        <span style={{margin:'auto'}}>
          {item.cutter==100 && <CheckCircleOutlineIcon color="success" fontSize='small'/>}
          {(item.cutter===undefined || item.cutter===null) && 
          <Tooltip title="Hiba a lekérdezés során">
            <WarningIcon color='warning' fontSize='small'/>
          </Tooltip>}
          {item.cutter==-1 && 
            <Tooltip title="Nincs munkafolyamat">
              <ErrorOutlineIcon color='info' fontSize='small'/>
            </Tooltip>}
          {(item.cutter!==100 && item.cutter!==-1 && item.cutter!==undefined && item.cutter!==null) && 
          <Typography style={{margin:'auto'}}>
            {item.cutter+' %'}
          </Typography>}
        </span>)
    },
    {
      key: 'column4',
      name: 'Stancoló',
      fieldName: 'stancolo',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (
        <span style={{margin:'auto'}}>
          {item.stancolo==100 && <CheckCircleOutlineIcon color="success" fontSize='small' />}
          {(item.stancolo===undefined || item.stancolo===null) && 
          <Tooltip title="Hiba a lekérdezés során">
            <WarningIcon color='warning' fontSize='small'/>
          </Tooltip>}
          {item.stancolo==-1 && 
          <Tooltip title="Nincs munkafolyamat">
            <ErrorOutlineIcon color='info' fontSize='small' />
          </Tooltip>}
          {(item.stancolo!==100 && item.stancolo!==-1 && item.stancolo!==undefined && item.stancolo!==null) && 
          <Typography style={{margin:'auto'}}>
            {item.stancolo+' %'}
          </Typography>}
        </span>)
    },
    {
      key: 'column5',
      name: 'Összeállító',
      fieldName: 'composer',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (
        <span style={{margin:'auto'}}>
          {item.composer==100 && <CheckCircleOutlineIcon color="success" fontSize='small'/>}
          {(item.composer===undefined || item.composer===null) && 
          <Tooltip title="Hiba a lekérdezés során">
            <WarningIcon color='warning' fontSize='small' />
          </Tooltip>}
          {item.composer==-1 && 
          <Tooltip title="Nincs munkafolyamat">
            <ErrorOutlineIcon color='info' fontSize='small' />
          </Tooltip>}
          {(item.composer!==100 && item.composer!==-1 && item.composer!==undefined && item.composer!==null) && 
          <Typography style={{margin:'auto'}}>
            {item.composer+' %'}
          </Typography>}
        </span>)
    },
    {
      key: 'colum6',
      name: 'Vasaló',
      fieldName: 'iron',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (
      <span style={{margin:'auto'}}>
        {item.iron==100 && <CheckCircleOutlineIcon color="success" fontSize='small'/>}
        {(item.iron===undefined || item.iron===null) && 
        <Tooltip title="Hiba a lekérdezés során">
          <WarningIcon color='warning' fontSize='small'/>
        </Tooltip>}
        {item.iron==-1 && 
        <Tooltip title="Nincs munkafolyamat">
          <ErrorOutlineIcon color='info' fontSize='small' />
        </Tooltip>}
        {(item.iron!==100 && item.iron!==-1 && item.iron!==undefined && item.iron!==null) && 
        <Typography style={{margin:'auto'}}>
          {item.iron+' %'}
        </Typography>}
      </span>)
    },
    {
      key: 'column7',
      name: 'Üvegező',
      fieldName: 'glazing',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (
      <span style={{margin:'auto'}}>
        {item.glazing==100 && <CheckCircleOutlineIcon color="success" fontSize='small'/>}
        {(item.glazing===undefined || item.glazing===null) && 
        <Tooltip title="Hiba a lekérdezés során">
          <WarningIcon color='warning' fontSize='small'/>
        </Tooltip>}
        {item.glazing==-1 && 
        <Tooltip title="Nincs munkafolyamat">
          <ErrorOutlineIcon color='info' fontSize='small'/>
        </Tooltip>}
        {(item.glazing!==100 && item.glazing!==-1 && item.glazing!==undefined && item.glazing!==null) && 
        <Typography style={{margin:'auto'}}>
          {item.glazing+' %'}
        </Typography>}
      </span>)
    },
    {
      key: 'column8',
      name: 'Összes',
      fieldName: 'allResult',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (
        item.allResult==100 ? 
        <CheckCircleOutlineIcon color="success" fontSize='small'/> :
        (item.allResult===undefined || item.allResult===null ? 
        <Tooltip title="Hiba a lekérdezés során">
          <WarningIcon color='warning' fontSize='small'/>
        </Tooltip> :
        <Typography style={{margin:'auto'}}>
          {item.allResult+' %'}
        </Typography>))
    },
    ]
  }
}