import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
//Material UI
import { Grid, Typography,Button } from '@mui/material';
//Style
import style from './style';
//Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const NotFound = () => {
    const classes = style();

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="center"
        spacing={4}
      >
        <Grid
          item
          lg={6}
          xs={12}
        >
          <div className={classes.content}>
            <Typography variant="h1">
              404: A keresett oldal nem található
            </Typography>
            <Typography variant="subtitle2">
            </Typography>
            <img
              alt="404"
              className={classes.image}
              src="/images/404.png"
            />
          </div>
          <div  className={classes.center}>
            <Button
                startIcon={<ArrowBackIcon />}
                color="primary"
                component={RouterLink}
                to="/"
                variant="contained"
            >
                Kezdőlap
            </Button> 
          </div>       
        </Grid>
      </Grid>
    </div>
  );
};

export default NotFound;
