import React from 'react';
import PropTypes from 'prop-types';
//Material UI
import {
    Avatar,
    Typography,
    IconButton,
    Tooltip,
    Hidden
} from  '@mui/material';
//Material UI Icons
import InputIcon from '@mui/icons-material/Input';

const Profile = props => {
    const { className, userInfo, onLogoutDialogOpen, ...rest } = props;

    const user = userInfo;
  return (
    <div {...rest} style={{  display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: 'fit-content'}}>
          <Tooltip title={user.fullName}>
     <IconButton>
      <Avatar
        style={{ width: 60, height: 60}}
        src={(user.avatar !== null && user.avatar !== undefined) ? user.avatar : ''}/>
      </IconButton>
     </Tooltip>
      <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>
        {user.fullName}
      </Typography>
          <Typography style={{ fontSize: '0.9rem', textAlign:'center' }} variant="body2">{user.title}</Typography>
          <Hidden lgUp>
              <Tooltip title="Kijelentkezés">
                  <IconButton
                      color="menuButton"
                      onClick={onLogoutDialogOpen}
                  >
                      <InputIcon />
                  </IconButton>
              </Tooltip>
          </Hidden>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
