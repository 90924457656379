import {Api,handleResponse,handleError} from 'api';
//Actions
const Actions = {
    Request:'curtain-wall/warehouse/virtual-producing'
}

export const apiRequest = {
    virtual
};

async function virtual(model) {
    await new Api().call.post(Actions.Request,model).then(handleResponse)
        .then((value) => {
        }).catch(handleError);
}
