import {Api,handleResponse,handleError} from 'api';
//Action
const Actions = {
    Send: 'uploadMM/upload-files',
}


export const apiRequest = {
    upload
};

async function upload(data, uploadProgress, uploadSucces, setUploadProgress, setUploadSucces) {
    await new Api().call.post(`${Actions.Send}`, data).then(handleResponse)
        .then(res => {
            var temp = [...uploadProgress];
            temp.map(row => {
                if (row.name == res.mmNumber) {
                    row.success = res.success;
                    row.progressMessage = res.progressMessage;
                    row.warningMessage = res.warningMessage;
                };
                return row;
            })
            setUploadProgress(temp);
            if (res.success == true) {
                setUploadSucces(uploadSucces.push(res.mmNumber));
            }

        }).catch(handleError);
}

