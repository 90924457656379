import React, { Component } from 'react';
//Components
import {Constants} from 'stores';

export default class Workpage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            array: null,
            reader: new FileReader(),
        }

    }
    componentDidMount() {
        const { reader } = this.state;
        const { file } = this.props;
        const that = this;
        reader.onload = function (e) {
            that.setState({
                array: reader.result
            });
        }
        reader.readAsArrayBuffer(file);
    }

    componentDidUpdate(prevProps, prevState) {
        const { array } = this.state;
        if (prevState.array != array) {
            if (array!==null) {
                this.initPdf(array);
            }
        }
    }

    handleBack = () => {
        this.props.history.goBack();
    };

    initPdf(array) {
        //show pdf document from arraybuffer    
        var adobeDCView = new window.AdobeDC.View({ clientId: Constants.getPdfClient(), divId: "adobe-dc-view" });
        adobeDCView.previewFile({
            content: { promise: Promise.resolve(array) },
            metaData: { fileName: this.props.file.name }
        }, { defaultViewMode: "FIT_WIDTH", dockPageControls: false, showAnnotationTools: false })
    }

    render() {
        return (<div id="adobe-dc-view" style={{ height: "100%" }}></div>)
    }
}
