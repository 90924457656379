import { NotificationBar } from 'components';
import {Api,handleResponse,handleError} from 'api';

//Actions
const Actions = {
    Send: 'schedule/groups/send'
}

export const apiRequest = {
    sendGroup
};


async function sendGroup(parent) {
    const { sended } = parent.props;
    await new Api().call.post(`${Actions.Send}/${sended.id}`, null).then(handleResponse)
        .then((value) => {
            NotificationBar('A továbbítás megtörtént', 'top', 'right', 3000, 'success', null);
        }).catch(handleError);

}
