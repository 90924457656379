import React from 'react';
import PropTypes from 'prop-types';
//Material UI
import {
    Divider,
    Drawer,
    CircularProgress
} from '@mui/material';
//Services
import {UserStore} from 'stores';
//Components
import {
    Profile,
    SidebarNav,
    HelperPlan,
} from './components';
import PerfectScrollbar from 'react-perfect-scrollbar'
import Footer from '../Footer';
//Styles
import './style.css';
//Data
import Data from './data';


const Sidebar = props => {
    const { open, pathName, variant, onClose, onLogoutDialogOpen, className, ...rest } = props;
    const [isLoading, setIsLoading] = React.useState(false);
    const [pages, setPages] = React.useState([]);
    const [userInfo, setUserInfo] = React.useState({});

    const handleSetIsLoading = (value) => {
        setIsLoading(value);
    }

    const handleSetPages = (value) => {
        setPages(value);
    }

    const handleSetUserInfo = (value) => {
        setUserInfo(value);
    }

    const currentUser = UserStore.getUserValue();
    return (
        <div>
            <Data handleSetIsLoading={handleSetIsLoading} handleSetUserInfo={handleSetUserInfo} handleSetPages={handleSetPages} />
            {isLoading === false ?
                (<Drawer
                    className='nav-side-bar'
                    anchor="left"          
                    onClose={onClose}
                    open={open}
                    variant={variant}
                >
                    <PerfectScrollbar>
                        <div {...rest} style={{backgroundColor:'white', display: 'flex', flexDirection: 'column', height: '100%', padding:'1rem'}}>
                            <Profile userInfo={currentUser} onLogoutDialogOpen={onLogoutDialogOpen} />
                            <Divider style={{margin: '1rem 0rem'}} />
                            <SidebarNav
                                pathName={pathName}
                                style={{ marginBottom:'1rem'}}
                                pages={pages}
                            />
                            <Divider style={{margin: '1rem 0rem'}} />
                            <HelperPlan />
                            <Footer />
                        </div>
                    </PerfectScrollbar>
                </Drawer>) : <CircularProgress color="primary" />}
        </div>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
