import React, { Component } from 'react';
//Material UI 
import { Grid } from '@mui/material';
//Material UI Icons
import HomeIcon from '@mui/icons-material/Home';
//Components
import { PageTitle } from 'components/Surfaces';

export default class Home extends Component {

    render() {
        return (  
            <div style={{padding:'2rem'}}>
                <Grid container spacing={4}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <PageTitle 
                            title="Kezdőlap" 
                            icon={<HomeIcon fontSize='large' id="header-icon" color="primary"/> } />     
                        </Grid>
                    <Grid item md={3} xs={12}>
                    </Grid>
                </Grid>
            </div>
        )
    }
}
