import {Api,handleResponse,handleError,Haders} from 'api';
const Actions = {
    OpenPdf: 'mMNumbers/pdf/position', //+{mmId}
    OpenMetalPdf: 'metal/mMNumbers/pdf/position', //+{mmId}
    OpenFFPdf: 'curtain-wall/mMNumbers/pdf/position', //+{mmId}
}

export const apiRequest = {
    populatePdf,
    populateMetalPdf,
    populateFFPdf
};


async function populatePdf(parent) {
    await new Api().call.get(`${Actions.OpenPdf}/${parent.props.workpageId}`).then(handleResponse)
        .then((value) => {
            //Create arraybuffer
            if (value.file != "") {
                parent.initPdf(value)
            }
        }).catch(handleError);

}

async function populateMetalPdf(parent) {
    await new Api().call.get(`${Actions.OpenMetalPdf}/${parent.props.typeId}/${parent.props.workpageId}`).then(handleResponse)
        .then((value) => {
            //Create arraybuffer
            if (value.file != "") {
                parent.initPdf(value)
            }
        }).catch(handleError);

}

async function populateFFPdf(parent) {
    await new Api().call.get(`${Actions.OpenFFPdf}/${parent.props.typeId}/${parent.props.workpageId}`).then(handleResponse)
        .then((value) => {
            //Create arraybuffer
            if (value.file != "") {
                parent.initPdf(value)
            }
        }).catch(handleError);

}


