//React
import React from 'react';
//Fluent UI
import { Text } from "@fluentui/react";
//Material UI
import {
  Button, 
  Chip, 
  IconButton
} from "@mui/material";
//Enums
import {Statuses} from 'enums';
import { Status } from 'components';
//Material UI Icons
import DoneIcon from '@mui/icons-material/Done';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import FactCheckIcon from '@mui/icons-material/FactCheck';

const statuses =[
  new Status(Statuses.Kesz,'warning','Kiszállítható','small',<InfoIcon/>),
  new Status(Statuses.Kiszallitva,'success','Kiszállítva','small',<LocalShippingIcon/>),
  new Status(Statuses.Kiszamlazva,'info','Kiszámlázva','small',<DoneIcon/>)
]

 
 /*==== Render status chip ====*/
  const StatusChip = (props) =>{
    const {item} = props;

    if(item.statuszId==Statuses.Kesz && !item.kiszallithato){
      return new Status(Statuses.VegellenorzesreVar,'secondary','Végellenőrzésre vár','small',<FactCheckIcon/>).getChip();
    }

    switch(item.statuszId){
      case Statuses.Kesz:
       return statuses.find(item=>item.id==Statuses.Kesz).getChip();
    case Statuses.Kiszallitva:
      return statuses.find(item=>item.id==Statuses.Kiszallitva).getChip();
    case Statuses.KiszamlazasVisszavonva:
      return statuses.find(item=>item.id==Statuses.Kiszallitva).getChip();
    case Statuses.Kiszamlazva:
      return statuses.find(item=>item.id==Statuses.Kiszamlazva).getChip();
    default:
      return null
    }
  }
/*=====================================*/


/*==== Render action container ====*/
const ActionContainer = (props) =>{
  const { item, onOpen, loading } = props;
  return (
    <div style={{dsiplay:'flex',margin:'auto',alignContent:'center'}}>
      {(item.statuszId===Statuses.Kiszallitva || item.statuszId===Statuses.KiszamlazasVisszavonva) ? 
      <Button 
        disabled={loading}
        style={{fontSize:'0.75rem'}} 
        onClick={()=>onOpen('bill',item)} 
        size="small" 
        variant="contained" 
        color="primary"
      >
        Kiszámlázva
      </Button> : 
      item.statuszId===Statuses.Kiszamlazva &&
      <Button 
        disabled={loading}
        style={{fontSize:'0.75rem'}} 
        onClick={()=>onOpen('recall',item)} 
        size="small" 
        variant="contained" 
        color="error"
      >
        Visszavonás
      </Button>}
    </div>
  )
}
/*=================================*/

/*==== Render others container ====*/
const Others = (props) =>{
  const { item, onOpen } = props;

  return (
    <div style={{width:'100%',display:'flex',alignItems:'center',gap:'0.5rem'}}>
      {item.megjegyzesekSzama>0 &&           
        <Chip
          size="small"
          onDelete={()=>onOpen("comments",item)}
          label="#Megjegyzések"
          color="info"
          deleteIcon={<MoreVertIcon fontSize='small' />}
          variant="contained"/>}
    </div>
  ) 
}
/*=================================*/



export default class TableColumns{

  constructor(props){
    this.props = props;
  }


  getColumns = () =>{
    const { onOpen, loading } = this.props;
    return [
      {
      key: 'column1',
      name: '#',
      fieldName: 'id',
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'number',
      onRender: (item) => (<Text>{item.id}</Text>)
    },             
    {
      key: 'column2',
      name: 'Munkalap',
      fieldName: 'megrendelesNev',
      minWidth: 120,
      maxWidth: 120,
      isRowHeader: true,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.megrendelesNev}</Text>)
    },
    {
      key: 'column3',
      name: 'Virtuális gyártás',
      fieldName: 'virtual',
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,     
      isPadded: true,
      data: 'string',
      onRender: (item) => (
        item.virtualisanGyarthato && 
        <Button 
          color={item.virtualisGyartasKesz ? 'error' : 'primary'}
          endIcon={item.virtualisGyartasKesz ? null : <PlaylistPlayIcon/>}
          onClick={item.virtualisGyartasKesz ? ()=>onOpen('cancelVirtual',item) : ()=>onOpen('virtual',item)} 
          style={{fontSize:'0.75rem'}}
          variant="contained" 
          size="small"
        >
         {item.virtualisGyartasKesz ? 'Visszavonás' : 'Gyártás'}
        </Button>
      )
    },
    {
      key: 'column4',
      name: 'Státusz',
      fieldName: 'status',
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,     
      isPadded: true,
      data: 'string',
      onRender: (item) => (<StatusChip item={item}/>)
    },
    {
      key: 'column5',
      name: 'Lejelentés',
      fieldName: 'actions',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      data: 'string',
      isPadded: true, 
      onRender: (item) => (<ActionContainer item={item} onOpen={onOpen} loading={loading}/>)
    },
    {
      key: 'column6',
      name: 'Kiegészítések',
      fieldName: 'notes',
      minWidth: 210,
      maxWidth: 210,
      isResizable: true,
      isPadded: true,
      data: 'string',
      onRender: (item) => (<Others item={item} onOpen={onOpen}/>)
    },
    {
      key: 'column7',
      name: 'Módosítva',
      fieldName: 'mappedModositva',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,     
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.mappedModositva}</Text>)
    },
    {
      key: 'column8',
      name: 'Létrehozva',
      fieldName: 'mappedLetrehozva',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.mappedLetrehozva}</Text>)
    }
    ]
  }
}