import React from 'react';
//Material UI
import {
    Fab,
    Zoom,
    useScrollTrigger
} from "@mui/material";
//Material UI Icons
import KeyboardArrowUpIcon from  '@mui/icons-material/KeyboardArrowUp';
//JQUERY
import $ from "jquery";


function ScrollTop(props) {
    const { children } = props;
    //const classes = style();
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger();

    const handleClick = (event) => {
      $(".scrollbar-container").animate({ scrollTop: 0 }, "slow");
    };

    return (
        <Zoom in={props.open}>
            <div onClick={handleClick} role="presentation" style={{position: 'fixed', bottom: '1rem', right:'1rem'}}>
                {children}
            </div>
        </Zoom>
    );
}

export default function ScrollToTop(props) {

    return (
        <ScrollTop {...props}>
            <Fab color="primary" size="small" aria-label="scroll back to top">
                <KeyboardArrowUpIcon />
            </Fab>
        </ScrollTop>
    );
}
