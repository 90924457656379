//React
import React from 'react';
//Fluent UI
import { Text } from "@fluentui/react";
//Material UI
import { Button,Chip } from "@mui/material";
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PageviewIcon from '@mui/icons-material/Pageview';
//Enums
import { Priority } from 'enums';


 
 /*====Render container with buttons====*/
 const ActionContainer = (props) =>{
  const {onSubDelete,onView,item,parentItem} = props;
  let element = <div style={{display:'flex',alignItems:'center',justifyContent:'center',gap:'0.5rem'}}>
    <Button style={{fontSize:'0.75rem'}} size='small' variant='contained' startIcon={<PageviewIcon />} color="primary" onClick={() => onView(item)}>
      Megtekintés
    </Button>
  </div>

  return element;
}
/*=====================================*/


export default class TableColumns{

  constructor(props){
    this.props = props;
  }


  getColumns = () =>{
    const {onView,onSubDelete,parentItem} = this.props;
    return [      
      {
        key: 'column1',
        name: 'Megnevezés',
        fieldName: 'name',
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
        onRender: (item) => (<Text>{item.name}</Text>)
      },
      {
        key: 'column3',
        name: 'Műveletek',
        fieldName: 'actions',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        onRender: (item) => (<ActionContainer parentItem={parentItem} onView={onView} onSubDelete={onSubDelete} item={item}/>)
      },
    ]
  }
}