//React
import React from 'react';
//Fluent UI
import { Text } from "@fluentui/react";
//Material UI
import {
  Button, 
  Chip,
  Checkbox, 
  IconButton,
  Tooltip
} from "@mui/material";
//Enums
import {Statuses} from 'enums';
import { Status } from 'components';
//Material UI Icons
import DoneIcon from '@mui/icons-material/Done';
import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import FactCheckIcon from '@mui/icons-material/FactCheck';

const statuses =[
  new Status(Statuses.Kesz,'warning','Kiszállítható','small',<InfoIcon/>),
  new Status(Statuses.Kiszallitva,'success','Kiszállítva','small',<LocalShippingIcon/>),
  new Status(Statuses.Kiszamlazva,'info','Kiszámlázva','small',<DoneIcon/>)
]

 
 /*==== Render status chip ====*/
  const StatusChip = (props) =>{
    const {item} = props;

    if(item.statuszId==Statuses.Kesz && !item.kiszallithato){
      return new Status(Statuses.VegellenorzesreVar,'secondary','Végellenőrzésre vár','small',<FactCheckIcon/>).getChip();
    }

    switch(item.statuszId){
      case Statuses.Kesz:
        return statuses.find(item=>item.id==Statuses.Kesz).getChip();
    case Statuses.Kiszallitva:
      return statuses.find(item=>item.id==Statuses.Kiszallitva).getChip();
    case Statuses.KiszamlazasVisszavonva:
      return statuses.find(item=>item.id==Statuses.Kiszallitva).getChip();
    case Statuses.Kiszamlazva:
      return statuses.find(item=>item.id==Statuses.Kiszamlazva).getChip();
    default:
      return '';
    }
  }
/*=====================================*/


/*==== Render action container ====*/
const ActionContainer = (props) =>{
  const { item, onOpen, loading } = props;
  return (
    <div style={{display:'flex',margin:'auto',alignContent:'center', gap:'0.5rem'}}>
      {item.statuszId===Statuses.Kesz ? 
      (!item.kiszallithato ? 
        <Tooltip title="Végellenőrzés nem történt meg, vagy nem kiszállítható státuszú">
          <span>
            <Button 
            disabled={true}
            onClick={()=>onOpen('send',item)} 
            size="small" 
            style={{fontSize:'0.75rem'}}
            variant="contained" 
            color="primary"
          >
            Kiküldve
          </Button>
          </span>
        </Tooltip>
        : 
        <Button 
        disabled={loading}
        onClick={()=>onOpen('send',item)} 
        size="small" 
        style={{fontSize:'0.75rem'}}
        variant="contained" 
        color="primary"
      >
        Kiküldve
      </Button> 
      )    
      : 
      item.statuszId!==Statuses.Kiszamlazva &&
      <Button 
        disabled={loading}
        onClick={()=>onOpen('recall',item)}
        size="small" 
        style={{fontSize:'0.75rem'}}
        variant="contained" 
        color="error"
      >
        Visszavonás
      </Button>}
      
    </div>
  )
}
/*=================================*/

/*==== Render others container ====*/
const Others = (props) =>{
  const { item, onOpen } = props;

  return (
    <div style={{width:'100%',display:'flex',alignItems:'center',gap:'0.5rem'}}>
      {item.megjegyzesekSzama>0 &&           
        <Chip
          size="small"
          onDelete={()=>onOpen("comments",item)}
          label="#Megjegyzések"
          color="info"
          deleteIcon={<MoreVertIcon fontSize='small' />}
          variant="contained"/>}
    </div>
  ) 
}
/*=================================*/



export default class TableColumns{

  constructor(props){
    this.props = props;
  }


  getColumns = () =>{
    const { onOpen, loading } = this.props;
    return [
      {
      key: 'column1',
      name: '#',
      fieldName: 'id',
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'number',
      onRender: (item) => (<Text>{item.id}</Text>)
    },             
    {
      key: 'column2',
      name: 'Munkalap',
      fieldName: 'megrendelesNev',
      minWidth: 120,
      maxWidth: 120,
      isRowHeader: true,
      isResizable: true,     
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.megrendelesNev}</Text>)
    },
    {
      key: 'column3',
      name: 'Státusz',
      fieldName: 'status',
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (<StatusChip item={item}/>)
    },
    {
      key: 'column4',
      name: 'Virtuális gyártás',
      fieldName: 'actions',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      data: 'string',
      isPadded: true, 
      onRender: (item) => (<Button 
        endIcon={item.virtualisanGyarthato ? <CheckOutlinedIcon/> : <DoNotDisturbAltOutlinedIcon/>}
        color={item.virtualisanGyarthato ? 'primary' : 'error'}
        disabled={loading}
        onClick={()=>onOpen('virtual',item)} 
        variant="contained" 
        style={{fontSize:'0.75rem'}}
        size="small" 
       >
         Virtuális gyártás
       </Button>)
    },  
    {
      key: 'column5',
      name: 'Műveletek',
      fieldName: 'actions',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      data: 'string',
      isPadded: true, 
      onRender: (item) => (<ActionContainer loading={loading} item={item} onOpen={onOpen}/>)
    },  
    {
      key: 'column6',
      name: 'Kiegészítések',
      fieldName: 'notes',
      minWidth: 210,
      maxWidth: 210,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (<Others item={item} onOpen={onOpen}/>)
    },
    {
      key: 'column7',
      name: 'Módosítva',
      fieldName: 'mappedModositva',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.mappedModositva}</Text>)
    },
    {
      key: 'column8',
      name: 'Létrehozva',
      fieldName: 'mappedLetrehozva',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.mappedLetrehozva}</Text>)
    }
    ]
  }
}