import React, { Component } from 'react';
//Material UI
import { 
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    IconButton,
    Button,
    ButtonGroup,
    Icon
} from '@mui/material';
//Material UI Icons
import CloseIcon from '@mui/icons-material/Close';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
//Components
import { DataTable } from 'components/Lists';
import { Upload, View } from './components';
import { apiRequest } from './apiRequest';
//Table columns
import TableColumns from './table_data';



class Attachments extends Component {

    state = {
        items: [],
        loading: true,
        request:false,
        selected:[],
        deleteAction:null,
        columns:[],
        addNew:null,
        dialogs:{
            open:false
        },
        dialogDatas:{
          open: null
        },
    }

    componentDidMount() {
        const {selected,request} = this.state;
        const {opened} = this.props;

        this.setColumns(new TableColumns({checkboxProps:{selected,deleteRequest:request,onSelectChange:this.handleSelectChange},onOpenDialog:this.handleOpenDialog}).getColumns());
        
        apiRequest.populateFiles(this,opened.id).then().catch().finally(()=>this.setLoading(false));
    }

    componentDidUpdate(prevProps,prevState){
        const {selected, request, items} = this.state;
    
        if(prevState.selected!=selected){
          this.setColumns(new TableColumns({checkboxProps:{selected,deleteRequest:request,onSelectChange:this.handleSelectChange},onOpenDialog:this.handleOpenDialog}).getColumns());
        }
    
        if(prevState.request!=request){
          this.setColumns(new TableColumns({checkboxProps:{selected,deleteRequest:request,onSelectChange:this.handleSelectChange},onOpenDialog:this.handleOpenDialog}).getColumns());
        }
    
        if(prevState.items!=items){
          if(items.length<1){
            this.setSelected([]);
          }
       
        }
    }

    
    /*==== Setter methods ====*/
        setRequest = (request) =>{
            this.setState({
                request
            });
        }

        setColumns = (columns) =>{
            this.setState({
                columns
            });
        }

        setItems = (items) =>{
            this.setState({
                items
            });
        }

        setSelected = (selected) =>{
            this.setState({
                selected
            });
        }

        setDeleteAction = (deleteAction) =>{ 
            this.setState({
                deleteAction
            })
        }

        setLoading = (loading) =>{
            this.setState({
                loading
            });
        }

        setAddNew = (addNew) =>{
            this.setState({
                addNew
            });
        }
    /*=========================*/

    /*==== Handle user interactions ====*/
      handleSelectChange = (checked,item) =>{
        var result = this.state.selected;
        if(checked){
              result.push(item.id);
        }else{
            var index = result.indexOf(item.id)
            if (index > -1) {
              result.splice(index, 1);
            }
          }
    
          this.setSelected(result);
      }

      handleCancelDelete=()=>{
          this.setDeleteAction(null);
      }

      handleOpenDialog = (props, row) => {
        this.setState(prevState=>({
          dialogs:{
                ...prevState.dialogs,
                [props]:true 
          },
          dialogDatas:{
              ...prevState.dialog,
              [props]:row 
          }
        }));
      }
        
      handleDialogClose = (props) => {
        this.setState(prevState=>({
          dialogs:{
              ...prevState.dialogs,
              [props]:false 
          },
          dialogDatas:{
            ...prevState.dialog,
            [props]:null 
          }
        }));      
      };
    /*===================================*/

    /*==== Handle events ====*/
        handleDelete = () =>{
            const {selected} = this.state;
            this.setRequest(true);
            
            //Call api
            apiRequest.deleteAction(this,selected).then(res=>{   
                this.setDeleteAction(null);    
                this.setSelected([]);                     
            }).catch(error=>{
            }).finally(()=>{
                this.setRequest(false);  
                this._refresh();    
            });
        }
    /*========================*/

   

   
    /*==== Helper methods ====*/
        _refresh=()=>{
            const {opened} = this.props;
        
            this.setLoading(true);
        
            apiRequest.populateFiles(this,opened.id).then().catch().finally(()=>this.setLoading(false));
        }
   /*=========================*/
  


    render() {
        const { onClose, opened } = this.props;
        const { loading, items, selected, columns, addNew, request, dialogDatas, dialogs } = this.state;

        return (
            <Dialog onClose={onClose} fullWidth maxWidth="xl" open={true}>
                <DialogTitle>                 
                    <Typography style={{color:'white',fontWeight:'600'}}>Mellékletek kezelése</Typography>
                    <IconButton disabled={request} edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>  
                </DialogTitle>
                <DialogContent dividers>
                    <div style={{ boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)' }}>
                        <div style={{ display:'flex',aligItems:'center',justifyContent:'space-between',padding:'1rem',backgroundColor:'white' }}>
                            <div>
                                <ButtonGroup>
                                <Button 
                                    onClick={this.setAddNew} 
                                    endIcon={<FileUploadIcon/>} 
                                    disabled={request}
                                    color="primary" 
                                    size="small" 
                                    variant="contained"
                                >
                                    Új feltöltés
                                </Button>
                                <Button 
                                    onClick={this.handleDelete} 
                                    endIcon={<DeleteIcon/>} 
                                    disabled={request || selected.length<1}
                                    color="error" 
                                    size="small" 
                                    variant="contained"
                                >
                                    Törlés
                                </Button>
                                </ButtonGroup>                      
                            </div>
                            <div>
                                <ButtonGroup size="small">      
                                <Button 
                                    onClick={this._refresh} 
                                    size="small" 
                                    disabled={request}
                                    variant="text"
                                >
                                    <Icon style={{display:'flex', alignItems:'center'}} fontSize='small' color={request ?  'secondary' : 'info'}>
                                        <RefreshOutlinedIcon fontSize='small' />
                                    </Icon>                               
                                </Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div> 
                    <DataTable
                        loading={request || loading} 
                        pagination={5} 
                        items={items}
                        setItems = {this.setItems}
                        length={items.length} 
                        selected={selected} 
                        columns={columns}
                    />
                    {addNew!==null && <Upload opened={opened.id} onRefresh={this._refresh} onClose={()=>this.setAddNew(null)}/>}
                    {dialogDatas.open != null && 
                        <View 
                            opened={dialogDatas.open} 
                            open={dialogs.open} 
                            handleClose={(event)=>this.handleDialogClose('open',event)} />
                    } 
                </DialogContent>
            </Dialog>)
    }

    
}

export default Attachments;
