import React from 'react';
import PropTypes from 'prop-types';
//Material UI
import {
    Typography,
    Link
} from '@mui/material'


const Footer = props => {
  const { className, ...rest } = props;

  const date = new Date();
  const year = date.getFullYear();

  return (
    <div
      {...rest}
      style={{padding:'2rem'}}
    >
      <Typography variant="body1">
        &copy;{' '}
        <Link
          component="a"
          href="https://abexkft.hu/"
          target="_blank"
        >
          Abex Kft
        </Link>
        . {year}
      </Typography>
      <Typography variant="caption">
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
