import React, { Component } from 'react';
import { withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
//Material UI
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Slide,
    Typography, 
    DialogTitle
} from '@mui/material';
//Services
import { authenticationService } from 'services/authentication';




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const LogoutDialog = props => {
    const { history, closeHandler, snackbar, open } = props;

   function logout() {
       authenticationService.logout(history, snackbar);
    }

    return (

        <Dialog TransitionComponent={Transition} onClose={closeHandler} aria-labelledby="logout-dialog-title" open={open}>
            <DialogTitle id="logout-dialog-title" onClose={closeHandler}>
                Biztosan kijelentkezel?
                </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    Kattints a  "Kijelentkezés" gombra ha végeztél az aktuális teendőiddel!
                    </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={closeHandler}>
                    Vissza
                </Button>
                <Button variant="contained"  color="primary" onClick={logout}>
                    Kijelentkezés
                </Button>
            </DialogActions>
        </Dialog>
    )
}

LogoutDialog.propTypes = {
    history: PropTypes.object
};

export default withRouter(LogoutDialog);

