import React, { Component }  from 'react';
//Material UI
import { 
    Typography,
    Paper,
    IconButton,
    Divider,
    LinearProgress,
    DialogTitle,
    DialogContent,
    Dialog,
    Button,
    DialogActions,
    DialogContentText
}from "@mui/material";
//Material UI Icons
import CloseIcon from '@mui/icons-material/Close';
//Components
import PerfectScrollbar from 'react-perfect-scrollbar'
import {apiRequest} from './apiRequest';

class Comments extends Component{

    state={
        notes:[],
        loading:true
    }

    componentDidMount(){
        const {data} = this.props;
        apiRequest.populateNotes(this,data.id);
    }

    render(){
        const { open, data, onClose } = this.props;
        const {  notes, loading } = this.state;
        return(                    
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitle>                 
                <Typography style={{color:'white',fontWeight:'600'}}>{data.megrendelesNev} - Megjegyzések</Typography>
                <IconButton size='small' edge="start" color="inherit" onClick={onClose}>
                    <CloseIcon fontSize='small' />
                </IconButton>  
            </DialogTitle>
            {loading===true &&  <LinearProgress color="primary" />}
            <PerfectScrollbar> 
                <DialogContent dividers style={{padding:'1rem'}}>  
                   {loading ? 
                    <DialogContentText style={{textAlign:'center'}}>
                        Betöltés...
                    </DialogContentText> :             
                    notes.map(item=>(
                        <React.Fragment key={item.id}>
                            <Typography style={{fontWeight:'bold'}}>
                                {item.author}
                            </Typography>
                            <Paper style={{padding:'0.5rem'}}>{item.message}</Paper>
                            <div style={{textAlign:'right'}}>
                                <Typography style={{fontWeight:'bold'}}>
                                    {item.createdTime}
                                </Typography>
                            </div>                    
                            <Divider style={{marginBottom:'1rem'}}/>  
                        </React.Fragment>
                    ))}              
                </DialogContent>
            </PerfectScrollbar>
            <DialogActions>
                <Button 
                    size="small" 
                    onClick={onClose} 
                    variant="contained" 
                    color="secondary"
                >
                    Vissza
                </Button>
            </DialogActions>                                                               
     </Dialog>)
    }
}
export default Comments