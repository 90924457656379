import React from 'react';
//Material UI
import { Popover } from '@mui/material';
//Components
import { DataTable } from 'components/Lists';
//Table columns
import TableColumns from './table_data';

function Workpages(props){
    const {files,workpages,onClose,onView,onSubDelete} = props;
    
    const [columns,setColumns] = React.useState(new TableColumns({parentItem:workpages.item,onSubDelete,onView}).getColumns());

        return(<Popover
            open={files.length>0}
            onClose={onClose}
            anchorEl={workpages.target}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
            }}
        >
             <DataTable
                items={files}
                length={files.length} 
                columns={columns}
            />
        </Popover>)
}

export default Workpages;