import React from 'react';
import PropTypes from 'prop-types';
//Material UI
import { 
  Chip, 
  Tooltip 
} from '@mui/material';
//Components
import {Constants} from 'stores';
import {signalR} from 'signalR';
//Icons
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const HelperPlan = props => {
  const { className, ...rest } = props;

  const [signalRState,setSignalRState] = React.useState([]);
  const [connectionError,setConnectionError] = React.useState(false);

  React.useEffect(() => {
    Constants.getSignalRState().subscribe(res =>
      setSignalRState(res)
    );
  },[]);

  React.useEffect(() => {
    if(signalRState.filter(item=>item.state==='NOT_CONNECTED').length>0){
      setConnectionError(true);
    }else{
      setConnectionError(false);
    }
  });

  const restartServices = () =>{
    const connections = Constants.getSignalRValue();
    signalR.restart(connections.office,'Office');
    signalR.restart(connections.factory,'Factory');
  }

  return (
    <div {...rest}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems:'center'}}>
      { connectionError &&  <Tooltip title="Nem érhető el minden szolgáltatlás, ezért lehetséges hogy az automatikus frissítés nem működik megfelelően">
      <Chip
          icon={<ErrorOutlineIcon />}
          label="Szolgáltatás probléma"
          color="warning"
          onClick={restartServices}
          
        />
      </Tooltip>}

      </div>
    </div>
  );
};

HelperPlan.propTypes = {
  className: PropTypes.string
};

export default HelperPlan;
