import React, { Component } from 'react';
//Material UI
import { Backdrop, CircularProgress } from "@mui/material";
//Components
import { SnackbarProvider } from 'notistack';
import Content from './Content';
import {UserStore,Constants} from 'stores';
import {Api,handleResponse,handleError} from 'api';
import { createBrowserHistory } from 'history';

//Styles
import { ThemeProvider } from  '@mui/material/styles'
import { loadTheme} from "@fluentui/react";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import overrides from './theme/overrides';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import './theme/overrides/fluent.css'

const browserHistory = createBrowserHistory();

//Actions
const Actions = {
  GetUser: 'user/logged_in'
}

initializeIcons();
initializeFileTypeIcons();

export default class App extends Component {

 

  constructor(props){
    super(props);

    Constants.setHistory(browserHistory);
    this.checkLoginStatus();
    this.state={
      request:UserStore.getRequestValue(),
      contentLoading:Constants.getcontentLoadingValue(),
      user:UserStore.getUserValue(),
    }
    
}

  componentDidMount(){

     //Subscribe to user changes
    UserStore.getUser().subscribe(res => this.setState({
            user: res,
    }));
    UserStore.getRequest().subscribe(res => this.setState({
      request: res,
    }));

    Constants.getcontentLoading().subscribe(res => this.setState({
      contentLoading: res,
    }));
    loadTheme(overrides.fluent.appTheme);  

  }

  async checkLoginStatus(){
    await new Api('withCookie').call.get(Actions.GetUser)
    .then(handleResponse)
    .then(res => {
        UserStore.setUser(res);
        UserStore.setLoggedInStatus("LOGGED_IN");
    },(error)=>{
        UserStore.setUser(null);
        UserStore.setLoggedInStatus("NOT_LOGGED_IN");       
        handleError(error);       
    }).finally(()=>{
      UserStore.setRequest(false);
    });
  }

  render() {
    const {request,contentLoading,user} = this.state;
    
    return (
        <ThemeProvider theme={overrides.material}>
            <SnackbarProvider maxSnack={3}>
            <Backdrop
                style={{ zIndex: 1200 + 1, backgroundColor: 'transparent '}}
                open={request===true || contentLoading===true}>
                <CircularProgress color="primary" />
            </Backdrop>
              {request===false &&
                <Content browserHistory={browserHistory} user={user}/>}
            </SnackbarProvider>
        </ThemeProvider>


    );
  }
}
