import {Api,handleResponse,handleError} from 'api';
//Action
const Actions = {
    Send: 'metal/uploadMM/upload-attachments',
}


export const apiRequest = {
    upload
};

async function upload(data) {
    await new Api().call.post(`${Actions.Send}`, data).then(handleResponse)
        .then(res => {           
        }).catch(handleError);
}

