import {Api,handleResponse,handleError} from 'api';
//Actions
const Actions = {
    OpenPdf: 'curtain-wall/warehouse/pdf/position', //+{mmId}
    OpenOnePdf: 'curtain-wall/warehouse/pdf', //+{mmId}
}


export const apiRequest = {
    populatePdf,
    populateOnePdf
};

  async function populatePdf(parent) {
        await new Api().call.get(`${Actions.OpenPdf}/${parent.props.typeId}/${parent.props.workpageId}`).then(handleResponse)
        .then((value) => {
            //Create arraybuffer
            if (value.file != "") {
                parent.initPdf(value)
            }
        }).catch(handleError);

    }

    async function populateOnePdf(parent) {
        await new Api().call.get(`${Actions.OpenOnePdf}/${parent.props.typeId}/${parent.props.workpageId}`).then(handleResponse)
        .then((value) => {
            //Create arraybuffer
            if (value.file != "") {
                parent.initPdf(value)
            }
        }).catch(handleError);

    }

