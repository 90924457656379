import React, { Component }  from 'react';
//Components
import {
  MissingsInOrder,
  RepairsInOrder,
  SelectedOrder
} from './components';
import { DataTable } from 'components/Lists';
import { FilterPanel } from 'components/Surfaces';
//Table columns
import TableColumns from './table_data';


class Store extends Component{

  state={
      dialog:{
        progress:null,
        repair:null,
        missing:null,
      },
      columns:[] 
  }

  componentDidMount() {
    this.setColumns(new TableColumns({onOpen:this.handleOpen}).getColumns());     
  }




  /*==== Setter methods ====*/
    setColumns = (columns) =>{
      this.setState({
        columns
      });
    }
  /*=========================*/



  /*==== Handle user interactions ====*/
    handleOpen = (name,value) =>{
      this.setState(prevState=>({
        dialog:{
          ...prevState.dialog,
          [name]:value
        }
      }));
    }

    handleClose = () =>{
      this.setState(prevState=>({
        dialog:{
          ...prevState.dialog,
          progress:null,
          repair:null,
          missing:null
        }
      }));
    }
  /*===================================*/
  
    render(){
      const { columns, dialog, anchorEl } = this.state;
      const { items, loading, filters, setFilters, onRefreshStore, setItems } = this.props;
      return (   
        <div>
          <FilterPanel 
            loading={loading}
            onSearch={onRefreshStore}
            onChange={setFilters} 
            filters={filters}/>  
          <DataTable
            loading={loading} 
            pagination={50} 
            items={items}
            setItems={setItems}
            length={items.length} 
            columns={columns}/>
          { dialog.progress!==null && 
          <SelectedOrder 
            selected={dialog.progress} 
            onClose={this.handleClose} 
            open={true}/>}
          { dialog.repair !==null && 
          <RepairsInOrder 
            selected={dialog.repair} 
            onClose={this.handleClose} 
            open={true}/>}
          { dialog.missing !==null && 
          <MissingsInOrder 
            selected={dialog.missing} 
            onClose={this.handleClose} 
            open={true}/>}
        </div>
      );
    }
    
};

export default Store;
