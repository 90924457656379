import {Api,handleResponse,handleError} from 'api';
import { NotificationBar } from 'components';
//Actions
const Actions = {
    GetFiles: 'metal/mMNumbers/attachments/by-group/store',
    DeleteMMNumber:'metal/mMNumbers/attachments/by-group/delete'
}


export const apiRequest = {
    populateFiles,
    deleteAction
};

async function populateFiles(parent,id) {
    await new Api().call.get(`${Actions.GetFiles}/${id}`).then(handleResponse)
        .then((value) => 
            parent.setItems(value)
        ).catch(handleError);

}


async function deleteAction(parent,model) {
    await new Api().call.post(Actions.DeleteMMNumber,model).then(handleResponse)
        .then((value) => {
            if(value!=null && value!==undefined){
                NotificationBar(value.message, 'top', 'right', 3000, 'success', null);   
            }
        }).catch(handleError);

}

