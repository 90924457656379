import React, { Component } from 'react';
//Router
import { Router } from 'react-router-dom';
import Routes from './Routes';


import { Chart } from 'react-chartjs-2';
import { chartjs } from './helpers';
import validate from 'validate.js';
import validators from './common/validators';

//Components
import { withSnackbar } from 'notistack';
import { authenticationService } from './services';
import {Constants} from 'stores';
import {signalR} from './signalR';
//Styles
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import "video-react/dist/video-react.css";


const apiRoute = Constants.getApiRoute();



Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
    draw: chartjs.draw
});

validate.validators = {
    ...validate.validators,
    ...validators
};
class Content extends Component {

    constructor(props){
        super(props);

        this. state = {
            connections:null,
            connectionsSetted:false,
            checkTokenInterval: null,    
        }
        
    }
   

    componentDidMount() {
        const { enqueueSnackbar } = this.props;
        Constants.setSnackbar(enqueueSnackbar);
        //Subscribe to connection changes
        Constants.getSignalR().subscribe(res=>this.setState({
            connections:res
        }));
        //Start singalR service and add to the store 
        signalR.start(Constants.getApiRoute()+'notifyhub','Office').then((connection)=>{
            var connections =  Constants.getSignalRValue();
            connections ={...connections,office:signalR.config(connection,'Office')};
            Constants.setSignalR(connections);
        });
        signalR.start(Constants.getFactoryApiRoute()+'notifyhub','Factory').then((connection)=>{
            var connections =  Constants.getSignalRValue();
            connections ={...connections,factory:signalR.config(connection,'Factory')};
            Constants.setSignalR(connections);
        });
    }

    componentDidUpdate(prevProps,prevState){
        const {connections} = this.state;
        if(connections!=prevState.connections){
            if(connections.factory!==undefined && connections.office!==undefined){
                this.setState({
                    connectionsSetted:true
                })
        }
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    tick = () => {
        const {browserHistory} = this.props;
        
        this.setState({
            checkTokenInterval: setInterval(authenticationService.checkTokenIsValid(browserHistory,this.props.enqueueSnackbar), 1000)
        });
    }

    render()
    {
        const {connectionsSetted } = this.state;
        const {user,browserHistory} = this.props;
        return (
            connectionsSetted==true &&
            <Router history={browserHistory}>
                <Routes loggedUser={user} />
            </Router>          
        );
    }
}
export default withSnackbar(Content);
