
import React from 'react';
//Components
import { DataTable } from 'components/Lists';
//Material UI
import { 
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    IconButton 
} from '@mui/material';
//Material UI Icons
import CloseIcon from '@mui/icons-material/Close';
//Table columns
import TableColumns from './table_data';

function ProblemBox(props){
    const {items, onClose} = props;

    const [columns,setColumns] = React.useState(new TableColumns({}).getColumns())


    return (  
        <Dialog onClose={onClose} fullWidth maxWidth="xl" open={true}>
        <DialogTitle>                 
            <Typography style={{color:'white',fontWeight:'600'}}>Problémák megtekintése</Typography>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                <CloseIcon />
            </IconButton>  
        </DialogTitle>
        <DialogContent dividers>
            <DataTable   
                items={items}
                length={items.length} 
                columns={columns}
            />
        </DialogContent>
    </Dialog>
           
    )
}

export default ProblemBox;
