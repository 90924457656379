import {Api,handleResponse,handleError} from 'api';
//Api calls
const Actions = {
    GetPositions: 'mMNumbers/positions',//+{mmId}
    GetMetalPositions: 'metal/mMNumbers/positions',//+{mmId}
    GetFFPositions: 'curtain-wall/mMNumbers/positions',//+{mmId}
}


export const apiRequest = {
    populatePositions,
    populateMetalPositions,
    populateFFPositions
};

async function populatePositions(parent) {
    const { opened } = parent.props;
    await new Api().call.get(`${Actions.GetPositions}/${opened.id}`).then(handleResponse)
        .then((value) => {
            if (value.length > 0) {
                parent.setState({
                    positions: value,
                    selected: value[0],
                    loading: false,
                });
            }

        }).catch((error) => {
            console.error(error);
        });

}

async function populateMetalPositions(parent) {
    const { opened } = parent.props;
    await new Api().call.get(`${Actions.GetMetalPositions}/${opened.id}`).then(handleResponse)
        .then((value) => {
            if (value.length > 0) {
                parent.setState({
                    positions: value,
                    selected: value[0],
                    loading: false,
                });
            }

        }).catch((error) => {
            console.error(error);
        });

}


async function populateFFPositions(parent) {
    const { opened } = parent.props;
    await new Api().call.get(`${Actions.GetFFPositions}/${opened.id}`).then(handleResponse)
        .then((value) => {
            if (value.length > 0) {
                parent.setState({
                    positions: value,
                    selected: value[0],
                    loading: false,
                });
            }

        }).catch((error) => {
            console.error(error);
        });

}

