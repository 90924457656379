//React

import React from 'react';
//Material UI

import {
    DialogContent,
    Slide,
    Typography,
    Dialog,
    IconButton,
    DialogTitle
} from '@mui/material';
//Components

import { Workpage } from './components';
//Icons

import CloseIcon from '@mui/icons-material/Close';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function View(props) {
    const { 
        open, 
        handleClose, 
        opened 
    } = props;


    return (
        <Dialog 
            open={open} 
            onClose={handleClose} 
            scroll="paper" 
            fullWidth={true}
            maxWidth="xl" 
            TransitionComponent={Transition}
        >
            <DialogTitle>
                <Typography style={{color:'white',fontWeight:'600'}}>
                    {opened?.name}
                </Typography>
                <IconButton 
                    edge="start" 
                    color="inherit" 
                    onClick={handleClose} 
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>                       
            </DialogTitle>
            <DialogContent style={{height:'100vh',paddingTop:'1rem'}}>
                <Workpage 
                    workpageId={opened?.id} 
                    workpageName={opened?.name} 
                />                   
            </DialogContent>
        </Dialog>
    );
}
