import {Api,handleResponse,handleError} from 'api';
//Actions
const Actions = {
    GetNotes:'finance/notes',
}

export const apiRequest = {
    populateNotes,
};

async function populateNotes(parent,orderId) {
    await new Api().call.get(`${Actions.GetNotes}/${orderId}`).then(handleResponse)
        .then((value) => {
            parent.setState({
                notes: value,
                loading: false,
            });
        }).catch(handleError);
}
