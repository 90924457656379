//Styles
import './style.css';
//Material UI
import { Typography} from '@mui/material';
import React from 'react';

export default function PageTitle(props){
    const {title,icon} = props;
    return(
        <div>
            <div className='page-title-container'>
                <div className='page-title-icon'>
                    {icon}  
                </div>
                <div className='page-title-text-container'>
                    <Typography className='page-title-text'>
                        {title}
                    </Typography>  
                </div>                                                     
            </div>
        </div>
    )
}