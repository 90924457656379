
import React, { Component } from 'react';
//Fluent UI
import { Label } from '@fluentui/react';
import { DataTable } from 'components/Lists';
//Table columns
import TableColumns from './table_data';
//Api
import { apiRequest } from './apiRequest';

function WorkstationBox(props){
    const {title, boxId, orderId, onProblem, request} = props;

    const [items,setItems] = React.useState([]);
    const [loading,setLoading] = React.useState(false);
    const [columns,setColumns] = React.useState(new TableColumns({onProblem,request}).getColumns())

    React.useEffect(()=>{
        if(orderId!==null && orderId!==undefined && orderId!==''){
            setLoading(true)
            apiRequest.getItems(boxId,orderId).then((res)=>{
                setItems(res)
            }).finally(()=>{
                setLoading(false)
            })
        }else{
            setItems([]);
        }
       
    },[orderId])


    React.useEffect(()=>{
        setColumns(new TableColumns({onProblem,request}).getColumns());
    },[items,request])

    return (  
        <div style={{marginTop:'2rem',marginBottom:'2rem'}}>
            <Label style={{fontSize:'1.0rem', fontWeight:'bold'}}>
                {title}
            </Label>
            <DataTable   
                loading={loading} 
                items={items}
                setItems={setItems}
                length={items.length} 
                columns={columns}/>
        </div>
    )
}

export default WorkstationBox;
