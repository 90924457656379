import * as React from 'react';
import { Component } from 'react';
//Fluent UI
import { 
    Label,
    SearchBox ,
    ComboBox 
} from '@fluentui/react';
//Material UI
import { 
    Grid, 
    Accordion, 
    AccordionSummary, 
    AccordionDetails,
    CircularProgress,
    Icon,
    Button,
    ButtonGroup,
    IconButton
} from "@mui/material";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
//Styles
import './style.css'

const LoadingLabel = (props) =>{
    const {label} = props;
    return <div style={{display:'flex', alignItems:'center',gap:'0.5rem'}}>{label}<CircularProgress size="1rem"/></div>
  }

class FilterPanel extends Component{

  state={
      hasFilter:false
  }

  setHasFilter = (hasFilter) =>{
      this.setState({
        hasFilter
      })
  }

  handleChange = (prop,value) =>{
    const { onChange,filters } = this.props;

    let mapped = (value===undefined || value ===null) ? '' : value;

    let result = filters.map(item=>{
        if(item.name===prop){
            item.value=mapped
        }  
        return item;
    })
    
    onChange(result);
  }

  handleSelectChange = (event, option, index, value,filterIndex) =>{
    const { onChange,filters } = this.props;

    let result = [...filters];
    let selectedKeys = result[filterIndex].value;
    let selected = option.selected;

    if(selected){
        selectedKeys.push(option.key);
    }else{
        selectedKeys = selectedKeys.filter(k => k !== option.key)
    }

    result[filterIndex].value = [...selectedKeys];
    onChange(result);
  }

  checkDefault = () =>{
    const {filters} = this.props;

    let result = false;
    filters.map(item=>{
        if(item.type=='text' && item.value!=='' && item.value!==undefined && item.value!==null){
            result = true;
        }
        if(item.type=='select' && item.value!==undefined && item.value!==null && item.value.length>0){
            result = true;
        }
    });
    return result;
  }

  handleDefault = () =>{
    const { onChange, filters, onSearch } = this.props;

    let result = filters.map(item=>{
        if(item.type==='text'){
            item.value='';
        }  
        if(item.type=='select'){
            item.value=[];
        }
        return item;
    })
    this.setHasFilter(false);
    onChange(result);
    onSearch();
  }

  handleFilter = () =>{
    const { onSearch } = this.props;

    this.setHasFilter(true);
    onSearch();
  }


  render(){
    const {loading, filters, onSearch} = this.props;
    const {hasFilter} = this.state;

    return (filters!==null && filters!==undefined && filters.length>0 ?
        <Accordion className="filter-container">
            <AccordionSummary
                expandIcon={<Icon color="secondary"><ExpandMoreOutlinedIcon/></Icon> }
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <div className='filter-title-container'>
                        {(this.checkDefault() || hasFilter) ? 
                        <IconButton onClick={this.handleDefault} size='small' color="primary">
                            <FilterAltOffIcon/>   
                        </IconButton> :
                        <IconButton size='small' color="secondary">
                            <FilterAltOutlinedIcon/>                      
                        </IconButton> 
                        }
                    <Label>Szűrők</Label>
                </div>      
            </AccordionSummary>
            <AccordionDetails>
                <Grid spacing={2} container className=''>
                    {filters.map((filter,filterIndex)=>
                        <Grid key={filter.name} item xs={12} sm={6} md={4} lg={3} xl={3}>
                            {filter.type==='text' && 
                            <React.Fragment>
                                <Label>{filter.label}</Label>
                                <SearchBox 
                                    placeholder="Keresés..." 
                                    name={filter.name} 
                                    value={filter.value} 
                                    onChange={(event,value)=>this.handleChange(filter.name,value)} />
                            </React.Fragment>}
                            {filter.type==='select' &&
                            <ComboBox
                                onChange={(event, option, index, value)=>this.handleSelectChange(event, option, index, value, filterIndex)}
                                selectedKey={filter.value}
                                label={filter.loading ? <LoadingLabel label={filter.label}/> : filter.label  }
                                disabled={filter.loading}
                                multiSelect
                                options={filter.options}/>}
                        </Grid>
                    )}    
                    <Grid style={{justifyContent:'flex-end',display:'flex'}} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ButtonGroup size="small">
                            <Button disabled={loading || !this.checkDefault()} onClick={this.handleDefault} size='small' variant="contained" color="secondary">
                                Alaphelyzet
                            </Button>
                            <Button disabled={loading} onClick={this.handleFilter} size='small' variant="contained" color="primary">
                                Szűrés
                            </Button>
                        </ButtonGroup>
                      
                    </Grid>           
                </Grid>
            </AccordionDetails>
        </Accordion>  : null
        
      );
  }
};

export default FilterPanel;