import {Api,handleResponse,handleError} from 'api';
//Actions
const Actions = {
    GetOrders:'tracking/store',
    GetStatuses:'tracking/statuses'
}


export const apiRequest = {
    populateOrders,    
    populateStatuses
};

async function populateOrders(parent,model) {
    await new Api().call.post(Actions.GetOrders,model).then(handleResponse)
        .then((value) => {
            parent.setState(prevState => ({
                store:value,
                loading:false,
            }));
        }).catch(handleError);

}

async function populateStatuses(parent) {
    await new Api().call.get(Actions.GetStatuses).then(handleResponse)
        .then((value) => {
            parent.setState(prevState => ({
                statuses:value
            }));
        }).catch(handleError);

}


