//React
import React from 'react';
//Fluent UI
import { 
  Text
} from "@fluentui/react";
//Material UI
import { 
    Checkbox,
    Button
} from "@mui/material";
import PreviewIcon from '@mui/icons-material/Preview';
import EditIcon from '@mui/icons-material/Edit';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import AddIcon from '@mui/icons-material/Add';


/*====Render Checkbox====*/
  const CheckboxContainer = (props) =>{
    const {item,onSelectChange,selected,deleteRequest} = props;

    const isChecked = (item) =>{
      return selected.includes(item.id);
    }

    return <Checkbox icon={<RadioButtonUncheckedIcon/>} checkedIcon={<RadioButtonCheckedIcon/>} color="primary" disabled={deleteRequest || !item.isDeletable} checked={isChecked(item)} onChange={(event,checked)=>onSelectChange(checked,item)}  />
  }
/*=======================*/
 
 /*====Render container with buttons====*/
  const InfosContainer = (props) =>{
    const {onOpenDialog,item} = props;

  return <div style={{display:'flex',alignItems:'center',gap:'0.5rem'}}>
  <Button onClick={()=>onOpenDialog("open",item)} style={{fontSize:'0.75rem'}} startIcon={<PreviewIcon fontSize='small'/>}  size='small' variant='contained' color="primary">
      Megtekintés
  </Button>
  <Button onClick={()=>onOpenDialog("edit",item)} style={{fontSize:'0.75rem'}} startIcon={<EditIcon fontSize='small'/>} size='small' variant='contained' color="warning">
      Kiegészítés
  </Button>
  <Button onClick={()=>onOpenDialog("attachments",item)} style={{fontSize:'0.75rem'}} startIcon={<AddIcon fontSize='small'/>} size='small' variant='contained' color="info">
      Mellékletek
  </Button>
  </div>
  }
/*=====================================*/

export default class TableColumns{

  constructor(props){
    this.props = props;
  }


  getColumns = () =>{
    const {checkboxProps,onOpenDialog} = this.props;
    return [
      {
        key: 'column_checkbox',
        name: 'Checkbox',
        fieldName: 'checkbox',
        minWidth: 30,
        maxWidth: 30,
        isRowHeader: true,
        isResizable: false,
        isIconOnly: true,
        onRender: (item) => (<CheckboxContainer onSelectChange={checkboxProps.onSelectChange} deleteRequest={checkboxProps.deleteRequest} selected={checkboxProps.selected} item={item}/>)
      },
      {
      key: 'column1',
      name: '#',
      fieldName: 'id',
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'number',
      onRender: (item) => (<Text>{item.id}</Text>)
    },             
    {
      key: 'column2',
      name: 'Megnevezés',
      fieldName: 'name',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.name}</Text>)
    },
    {
      key: 'column3',
      name: 'Feltöltve',
      fieldName: 'uploadedTime',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.uploadedTime}</Text>)
    },
    {
      key: 'column4',
      name: 'Műveletek',
      fieldName: 'actions',
      minWidth: 300,
      maxWidth: 300,
      isResizable: true,
      onRender: (item) => (<InfosContainer onOpenDialog={onOpenDialog} item={item}/>)
    },
    ]
  }
}