import React from 'react';
//Material UI
import { 
  Typography, 
  DialogTitle, 
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  IconButton 
} from "@mui/material";
//Material UI Icons
import CloseIcon from '@mui/icons-material/Close';
//Fluent UI
import { TextField } from "@fluentui/react";
//Components
import {DateSelect} from 'components/Inputs';
import { apiRequest } from './apiRequest';

export default function BillDialog(props) {
 const { onClose, open, data, afterFeedback } = props;

  const[note,setNote] = React.useState('');
  const [date, setDate] = React.useState({original:'',mapped:''});
  const [time, setTime] = React.useState('');
  const [request,setRequest] = React.useState(false);


  const handleDateChange = (name,newDate,newMappedDate)=>{
    setDate({original:newDate,mapped:newMappedDate});
  }

  const handleSave = () =>{
    setRequest(true)
    const model={
      id:data.id,
      date,
      time,
      note
    }
   apiRequest.billed(model).then(res=>{
    afterFeedback('bill')
   }).catch(error=>{
    setRequest(false);
   });
  }

  const validHHMMstring = (str) => /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(str);

  const validate = () =>{
    if(!validHHMMstring(time)){
      return false
    }
    if(date===null || date===undefined || date ===''){
      return false
    }
    return true;
  }

  return (
      <Dialog 
      fullWidth={true}
      open={open} 
      onClose={onClose} 
      >
        <DialogTitle>                 
          <Typography style={{color:'white',fontWeight:'600'}}>
            Kiszámlázás visszajelentése - {data.megrendelesNev}
          </Typography>
          <IconButton size='small' edge="start" color="inherit" onClick={onClose}>
            <CloseIcon fontSize='small' />
          </IconButton>  
        </DialogTitle>
        <DialogContent dividers style={{padding:'1rem'}}>
          <DateSelect disabled={request} required={true} errorMessage={(date===null || date===undefined || date ==='') ? 'Nem megengedett érték': null} value={date!==null ? date.original :''} onChange={handleDateChange} label="Kiszámlázás dátuma"/>
          <TextField
            onChange={(event)=>setTime(event.target.value)}
            value={time}
            required
            disabled={request}
            id="tf-time"
            label="Kiszámlázás pontos ideje"
            type="text"
            errorMessage={validHHMMstring(time) ? null : 'Nem megengedett érték'}
            fullWidth
          />
          <TextField
            onChange={(event)=>setNote(event.target.value)}
            value={note}
            multiline
            rows={4}
            disabled={request}
            id="tf-note"
            label="Megjegyzés"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button 
            size="small" 
            variant="contained" 
            disabled={request}
            onClick={onClose} 
            color="secondary"
          >
            Vissza
          </Button>
          <Button 
            size="small" 
            variant="contained" 
            disabled={!validate() || request} 
            onClick={handleSave} 
            color="primary"
          >
            Mentés
          </Button>
        </DialogActions>
      </Dialog>
  );
}