import {Api,handleResponse,handleError} from 'api';
//Actions
const Actions = {
    Billed: 'curtain-wall/finance/billed',
}

export const apiRequest = {
    billed
};

async function billed(model) {
    return await new Api().call.post(Actions.Billed,model).then(handleResponse)
        .then((value) => {
        }).catch(handleError);
}
