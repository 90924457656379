const Statuses = {
    OsszekeszitesreAtadva:1,
    GyartasbaElkuldve:2,
    Elinditva:3,
    Kesz:4,
    Uj:5,
    Javitando:6,
    Ujragyartando:7,
    TobbiElemreVar:8,
    Osszekeszitve:9,
    Kiszallitva:10,
    KiszallitvaVisszavonva:11,
    Kiszamlazva:12,
    KiszamlazasVisszavonva:13,
    VegellenorzesreVar:14
}

export default Statuses;