import React, { createRef } from 'react';
//Material UI
import { 
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    IconButton,
    Button, 
    DialogActions,
    ButtonGroup
} from '@mui/material';
//Components
import {
    ErrorDialog
} from './components';
import { DataTable } from 'components/Lists';
import { apiRequest } from './apiRequest';
//Fluent UI
import Dropzone from 'react-dropzone';
//Material UI Icons
import CloseIcon from '@mui/icons-material/Close';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import UploadIcon from '@mui/icons-material/Upload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//Table columns
import TableColumns from './table_data';



function Upload(props) {
    const { onClose, opened, onRefresh } = props;

    const [files, setFiles] = React.useState([]);
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [request,setRequest] = React.useState(false);
    const [columns, setColumns] = React.useState(new TableColumns({request}).getColumns());


    React.useEffect(()=>{
        setColumns(new TableColumns({request}).getColumns());
    },[request]);


   
    /*==== Handle user interactions ====*/
        const handleUpload = (event) => {
            //Set request 
            setRequest(true);

            //Create form data
            var data = new FormData();
            data.append('id',opened)
            files.map(file => {
                data.append('files[]', file.file);
            });
    
            //Send to server
            apiRequest.upload(data).then(()=>{
                onRefresh();
                onClose();
            }).catch().finally(()=>{
                //Set request 
                setRequest(false);
            });

        }

        const handleErrorClose = () => {
            setErrorOpen(false);
        }
    /*===================================*/



    /*==== Drop zone function ====*/
        const dropzoneRef = createRef();

        const onDropAccepted = (acceptedFiles) => {
            var temp = [...files];
            for (var i = 0; i < acceptedFiles.length; i++) {
                const file = acceptedFiles[i];
                const name = acceptedFiles[i].name.split('.').reverse()[1];

                const model = { name, file }
                temp.push(model);
            }
            setFiles(temp);
        };

        const onDropRejected = (rejectedFiles) => {
            if (rejectedFiles.length > 0) {
                setErrorOpen(true);
            } else {
                setErrorOpen(false);
            }
        }

        
        const openDialog = () => {
            // Note that the ref is set async,
            // so it might be null at some point 
            if (dropzoneRef.current) {
                dropzoneRef.current.open()
            }
        };
    /*=============================*/

        return (
            <Dialog onClose={onClose} fullWidth maxWidth="xl" open={true}>
                <DialogTitle>                 
                    <Typography style={{color:'white',fontWeight:'600'}}>Melléklet feltöltése</Typography>
                    <IconButton disabled={request} edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>  
                </DialogTitle>
                <DialogContent dividers>
                <Dropzone  
                    onDropRejected={onDropRejected} 
                    onDropAccepted={onDropAccepted} 
                    accept={'.pdf'} 
                    disabled={request}
                    ref={dropzoneRef} 
                    multiple 
                    noClick 
                    noKeyboard
                >
                    {({ getRootProps, fileRejections, isDragActive, getInputProps }) => {
                        return (<div>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        {
                                            isDragActive ?
                                            <div style={{ backgroundColor: 'white',boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',textAlign:'center' }}>
                                                <div style={{borderBottom:'1px solid #eeeeee',backgroundColor:'#fafafa',padding:'0.5rem'}}>
                                                    <Typography style={{ fontWeight:'600',fontSize:'1rem'}}>
                                                        Dobd a file-okat ide...
                                                    </Typography>
                                                    <Typography color="secondary" style={{ fontWeight:'400',fontSize:'0.8rem'}}>
                                                        Elfogadott formátumok: .pdf
                                                    </Typography>
                                                </div>
                                                <div style={{minHeight:'10rem',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#ecfacc'}}>
                                                    <FileCopyIcon />
                                                </div>
                                            </div> :
                                            <div style={{ backgroundColor: 'white',boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)', textAlign:'center' }}>
                                                <div style={{borderBottom:'1px solid #eeeeee',backgroundColor:'#fafafa',padding:'0.5rem'}}>
                                                    <Typography style={{ fontWeight:'600',fontSize:'1rem'}}>
                                                        Húzd ide a file-okat a feltöltéshez vagy kattints ide:
                                                    </Typography>                                   
                                                    <Button
                                                        style={{padding:'1rem 1rem'}} 
                                                        startIcon={<AddCircleOutlineIcon/>} 
                                                        color="primary" 
                                                        variant='contained' 
                                                        disabled={request}
                                                        onClick={openDialog} 
                                                        size="medium"
                                                    >
                                                        File(ok) kiválasztása
                                                    </Button>
                                                    <Typography color="secondary" style={{ fontWeight:'400',fontSize:'0.8rem'}}>
                                                        Elfogadott formátumok: .pdf
                                                    </Typography>
                                                </div>
                                                <div style={{minHeight:'10rem',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                    <FileCopyIcon />
                                                </div>
                                            </div>
                                        }
                                </div>
                                <ErrorDialog 
                                    handleClose={handleErrorClose} 
                                    fileRejections={fileRejections} 
                                    open={errorOpen} />
                            </div>
                        );
                    }}
                </Dropzone>
                <DataTable
                    loading={request} 
                    pagination={5} 
                    items={files}
                    length={files.length} 
                    columns={columns}
                />
                </DialogContent>
                <DialogActions>
                <div style={{ display:'flex',aligItems:'center',justifyContent:'space-between',padding:'1rem',backgroundColor:'white' }}>
                    <div>
                    </div>
                    <div>
                      <ButtonGroup size="small">
                          <Button
                            onClick={onClose} 
                            style={{fontSize:'0.75rem'}}                         
                            startIcon={<ArrowBackIcon fontSize='small'/>}
                            size="small" 
                            disabled={request}
                            color="secondary"
                            variant="contained"
                          >
                            Vissza
                          </Button>
                          <Button 
                            disabled={request || files.length<1}
                            onClick={handleUpload}
                            style={{fontSize:'0.75rem'}}
                            endIcon={ <UploadIcon fontSize='small'/>}
                            size="small" 
                            color="primary"
                            variant="contained"
                          >
                           Feltöltés
                          </Button>
                        </ButtonGroup>
                    </div>
                </div>
                </DialogActions>
            </Dialog>)  
}

export default Upload;
