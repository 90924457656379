import React, { Component } from 'react';
//Material UI
import { 
    Button,
    Dialog,
    IconButton,
    Typography,
    DialogTitle,
    DialogContent,
    DialogActions
 } from "@mui/material";
//Material UI Icons
import CloseIcon from '@mui/icons-material/Close';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
//Components
import { DataTable } from 'components/Lists';
import { ViewWorkPage,EditWorkPage } from 'components';
//Table columns
import TableColumns from './table_data';
//Api
import { apiRequest } from './apiRequest';

class Sended extends Component {

    state = {
        columns:[],    
        dialogs:{edit:null,view:null}
    }

    componentDidMount() {
        this.setColumns(new TableColumns({onView:this.handleDialog}).getColumns());
    }

    componentDidUpdate(prevProps,prevState){
        const {dialogs} = this.state;
        const {parentRefresh,items} = this.props;
    
        if(prevState.dialogs.edit!=dialogs.edit){
            if(dialogs.edit==null){
                parentRefresh();
           }       
        }

        if(prevProps.items != items){
            this.setColumns(new TableColumns({onView:this.handleDialog}).getColumns());
        }
    
    }


    /*==== Setter methods ====*/
        setColumns = (columns) =>{
            this.setState({
                columns
            });
        }
    /*=========================*/

    /*==== Handle user interactions ====*/
        handleDialog = (props, value) => {
            this.setState(prevState=>({
                dialogs:{
                    ...prevState.dialogs,
                    [props]:value 
                }
            }));
        }
    /*===================================*/

  
    /*==== Handle events ====*/
        handleSend = () =>{
            const { onClose,parentRefresh } = this.props;
            apiRequest.sendGroup(this).then(res=>{
                parentRefresh();
                onClose();
            });
        }
    /*========================*/

    render(){
        const { dialogs, columns } = this.state;
        const { snackbar, onClose, items, sended } = this.props;

        return(  <Dialog open={true} maxWidth="xl" fullWidth>
        <DialogTitle>                 
            <Typography style={{color:'white',fontWeight:'600'}}>{sended.name} - gyártás indítása</Typography>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                <CloseIcon />
            </IconButton>  
        </DialogTitle>
        <DialogContent style={{padding:'1rem'}}>
            <DataTable
                pagination={50} 
                items={items}
                length={items.length} 
                columns={columns}/>
            {dialogs.open != null && 
                <ViewWorkPage 
                    opened={dialogs.open} 
                    open={true} 
                    handleClose={(event)=>this.handleDialog('open',null)} />} 
            {dialogs.edit != null && 
                <EditWorkPage 
                    group={sended} 
                    addsOn={{workpage:true,position:true}} 
                    snackbar={snackbar} 
                    opened={dialogs.edit} 
                    open={true} 
                    handleClose={(event) => this.handleDialog('edit', null)} />}                                                        
        </DialogContent>
        <DialogActions>
            <Button 
                variant='contained'
                onClick={onClose} 
                color='error' 
                size='small'
            >
                Vissza
            </Button>
            <Button 
                variant='contained' 
                color='primary' 
                size='small'
                onClick={this.handleSend}
                endIcon={<ArrowCircleRightIcon/>}
            >
                Indítás
            </Button>
        </DialogActions>
        </Dialog>)
    }
}

export default Sended;