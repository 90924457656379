import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {UserStore,Constants} from 'stores';

//Material UI
import { useTheme } from  '@mui/material';
import { useMediaQuery } from  '@mui/material';

const RouteWithLayout = props => {
    const { layout: Layout, authorize, path, handleContentLoading, roles, title, tabs,  component: Component, ...rest } = props;
    const { enqueueSnackbar } = useSnackbar();
    const currentUser = UserStore.getUserValue();
    const loggedInStatus = UserStore.getLoggedInStatusValue();
    React.useEffect(()=>{
        Constants.setcontentLoading(false);
    },[])
    const pathName = props.location.pathname;
    const returnUrl = (props.location.state !==undefined && props.location.state !==null)  ? props.location.state.from.pathname : null;

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    return (authorize ?
        <Route
            {...rest}
            render={matchProps => {
                if (loggedInStatus === 'NOT_LOGGED_IN') {
                    // not logged in so redirect to login page with the return url
                    return <Redirect to={{ pathname: '/sign-in'+pathName, state: { from: props.location } }} />
                } else {
                    if (path === "/sign-in") {
                        return <Redirect to={{ pathname: returnUrl!==null ? returnUrl : '/', state: { from: props.location } }} />
                    }
                }
                //check if route is restricted by role
                if (roles) {
                    if(currentUser===null){
                        return <Redirect to={{ pathname: '/sign-in'+pathName, state: { from: props.location } }} />
                    }
                    const permission = roles.some(r => currentUser.roles.indexOf(r) >= 0);
                    if (permission === false) {
                        // role not authorised so redirect to home page
                        return <Redirect to={{ pathname: '/unauthorized' }} />
                    }

                }
                return <Layout title={title} tabs={tabs} snackbar={enqueueSnackbar}>
                    <Component isDesktop={isDesktop}  {...matchProps} snackbar={enqueueSnackbar} />
                </Layout>
            }

            } /> :
        <Route
            {...rest}
            render={matchProps => (
                <Layout handleContentLoading={handleContentLoading} tabs={tabs} snackbar={enqueueSnackbar}>
                    <Component handleContentLoading={handleContentLoading}  {...matchProps} snackbar={enqueueSnackbar} />
                </Layout>
            )}
        />
    );
};

RouteWithLayout.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string,
};

export default RouteWithLayout;













/*import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';


const RouteWithLayout = props => {
    const { layout: Layout, roles, tabs, component: Component, ...rest } = props;
    const { enqueueSnackbar } = useSnackbar();
    return (
        <Route
            {...rest}
            render={matchProps => (
                <Layout tabs={tabs}>
                    <Component  {...matchProps} snackbar={enqueueSnackbar} />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default RouteWithLayout;
*/
