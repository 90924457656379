import React from 'react';
//Material UI
import { 
    Dialog,
    Button, 
    IconButton, 
    Typography, 
    Table, 
    TableHead, 
    TableRow, 
    TableCell, 
    TableBody,
    DialogTitle,
    DialogActions,
    DialogContent 
} from '@mui/material';
//Icons
import CloseIcon from '@mui/icons-material/Close';

export default function ErrorDialog(props) {

    const { open, fileRejections, handleClose } = props;

    return (
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>                 
                    <Typography style={{color:'white',fontWeight:'600'}}>Hiba üzenet</Typography>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>  
                </DialogTitle>
                <DialogContent dividers style={{ textAlign: 'center',padding:'1rem' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">
                                    Megnevezés
                                </TableCell>
                                <TableCell align="center">
                                    Méret
                                </TableCell>
                                <TableCell align="center">
                                    Hibaüzenet
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fileRejections.map(({ file, errors }) => (
                                <TableRow key={file.path}>
                                    <TableCell>
                                        {file.path}
                                    </TableCell>
                                    <TableCell>
                                        {file.size} bytes
                                    </TableCell>
                                    <TableCell>
                                        {errors.map((e, index) => (
                                            <p style={{color:'red'}} key={index}>{e.message}</p>
                                        ))}
                                    </TableCell>
                                </TableRow>
                            ))}
                           
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button
                        color='primary' 
                        size="small" 
                        variant="contained" 
                        onClick={handleClose} 
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
    );
}
