//React
import React from 'react';
//Fluent UI
import { Text } from "@fluentui/react";
//Material UI
import { Button,IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import PageviewIcon from '@mui/icons-material/Pageview';
import { Icon } from '@fluentui/react';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons'


 
 /*====Render container with buttons====*/
 const ActionContainer = (props) =>{
  const {onSubDelete,onView,item,parentItem} = props;
  let element = <div style={{display:'flex',alignItems:'center',justifyContent:'center',gap:'0.5rem'}}>{splitFileName(item.name)[0] === 'pdf' &&
    <Button style={{fontSize:'0.75rem'}} size='small' variant='contained' startIcon={<PageviewIcon />} color="primary" onClick={() => onView(item)}>
      Megtekintés
    </Button>}
    <Button style={{fontSize:'0.75rem'}} size='small' variant='contained' startIcon={<DeleteIcon />} color="error" onClick={() => onSubDelete(parentItem, item)}>
      Törlés
    </Button>
  </div>

  return element;
}
/*=====================================*/


const splitFileName = (fileName) => {
  return fileName.split('.').reverse();
}

export default class TableColumns{

  constructor(props){
    this.props = props;
  }


  getColumns = () =>{
    const {onView,onSubDelete,parentItem} = this.props;
    return [      
      {
        key: 'column0',
        name: 'Icon',
        fieldName: 'icon',
        minWidth: 50,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
        isIconOnly: true,
        onRender: (item) => (<Icon {...getFileTypeIconProps({ extension: splitFileName(item.name)[0], size: 32, imageFileType: 'png' }) } />)
      },
      {
        key: 'column1',
        name: 'Megnevezés',
        fieldName: 'name',
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
        onRender: (item) => (<Text>{item.name}</Text>)
      },
      {
        key: 'column2',
        name: 'Kiterjesztés',
        fieldName: 'extension',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        data: 'string',
        isPadded: true,
        onRender: (item) => (<Text>.{splitFileName(item.name)[0]}</Text>)
      },
      {
        key: 'column3',
        name: 'Utolsó módosítás',
        fieldName: 'lastModifiedDate',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        onRender: (item) => (<Text>{item.lastModifiedDate.toLocaleDateString()}</Text>)
      },
      {
        key: 'column4',
        name: 'Műveletek',
        fieldName: 'actions',
        minWidth: 300,
        maxWidth: 300,
        isResizable: true,
        onRender: (item) => (<ActionContainer parentItem={parentItem} onView={onView} onSubDelete={onSubDelete} item={item}/>)
      },
    ]
  }
}