//React
import React from 'react';
//Fluent UI
import { Text  } from "@fluentui/react";


export default class TableColumns{

  constructor(props){
    this.props = props;
  }

  getColumns = () =>{
    return [
      {
      key: 'column1',
      name: '#',
      fieldName: 'id',
      minWidth: 20,
      maxWidth: 20,
      isRowHeader: true,
      isResizable: true,
      data: 'number',
      isPadded: true,
      onRender: (item) => (<Text>{item.id}</Text>)
    },             
    {
      key: 'column2',
      name: 'Pozíció',
      fieldName: 'position',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (<Text>{item.position}</Text>)
    },
    {
      key: 'column3',
      name: 'Elem',
      fieldName: 'item',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (<Text>{item.item}</Text>)
    },
    {
      key: 'column4',
      name: 'Darabszám',
      fieldName: 'quantity',
      minWidth: 60,
      maxWidth: 60,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (<Text>{item.quantity}</Text>)
    },
    {
      key: 'column5',
      name: 'Munkaállomás',
      fieldName: 'workstation',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (<Text>{item.workstation}</Text>)
    },
    {
      key: 'column6',
      name: 'Dolgozó',
      fieldName: 'worker',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (<Text>{item.worker}</Text>)
    },
    {
      key: 'column7',
      name: 'Lejelentve',
      fieldName: 'createdTime',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (<Text>{item.createdTime}</Text>)
    }
    ]
  }
}