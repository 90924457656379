import React, { Component } from 'react';
//Material UI
import { 
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Typography
 } from "@mui/material";
//Material UI Icons
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import CloseIcon from '@mui/icons-material/Close';
//Components
import { CreateDialog } from './components';
import { DataTable } from 'components/Lists';
import { FilterPanel } from 'components/Surfaces';
import { ViewWorkPage,EditWorkPage } from 'components';
//Models
import {FilterModel} from 'components/Models';
//Table columns
import TableColumns from './table_data';
//Api
import { apiRequest } from './apiRequest';


class NewGroup extends Component {


    state = {
        priorities: [],
        items: [],
        selected: [],
        filters:[new FilterModel('id','Azonosító','','text'),
        new FilterModel('name','Megnevezés','','text')],
        loading: {
            priorities: true,
            items: true
        },
        columns:[],    
        dialogs:{edit:null,view:null},
        open:false
    }

    componentDidMount() {
        const { selected } = this.state;
        this.setColumns(new TableColumns({checkboxProps:{selected,onSelectChange:this.handleSelectChange},onOpenDialog:this.handleDialog}).getColumns());

        apiRequest.populateFiles(this,{}).then(res=>{
            this.setLoading('items',false);
        });
        apiRequest.populatePriorities(this).then(res=>{
            this.setLoading('priorities',false);
        });
    }

    componentDidUpdate(prevProps,prevState){
        const {filters, selected} = this.state;
    
        if(prevState.selected!=selected){
          this.setColumns(new TableColumns({checkboxProps:{selected,onSelectChange:this.handleSelectChange},onOpenDialog:this.handleDialog}).getColumns());
        }       
    }

   
    /*==== Setter methods ====*/    
        setColumns = (columns) =>{
            this.setState({
            columns
            });
        } 

        setItems = (items) =>{
            this.setState({
            items
            });
        }

        setSelected = (selected) =>{
            this.setState({
            selected
            });
        }

        setLoading = (prop,value) =>{
            this.setState((prevState)=>({
                loading:{
                    ...prevState.loading,
                    [prop]:value
                }
            }));
        }

        setFilters = (filters) =>{
            this.setState({
            filters
            });
        }

        setOpen = (open) =>{
            this.setState({
                open
            });
        }
    /*=========================*/

 
    /*==== Handle user interactions ====*/
        handleSelectChange = (checked,item) =>{
            var result = this.state.selected;
            if(checked){
                result.push(item);
            }else{
                const temp = result.map(item=>{return item.id});
                var index = temp.indexOf(item.id)
                if (index > -1) {
                result.splice(index, 1);
                }
            }
        
            this.setSelected(result);
        }

        handleAddElement = () =>{
            this.setOpen(true);
        }

        handleDialog = (props, value) => {
            this.setState(prevState=>({
                dialogs:{
                    ...prevState.dialogs,
                    [props]:value 
                }
            }));
        }

        handleStatementChange = (item,checked) =>{
            const {selected} = this.state;

            var _items = [...selected];
            var index = _items.indexOf(item);
            _items[index] = { ...item };
            _items[index].needStatement = checked;

            this.setSelected(_items);
        }

        handlePriorityChange = (event,option,item) =>{
            const {selected} = this.state;

            var _items = [...selected];
            var index = _items.indexOf(item);
            _items[index] = { ...item };
            _items[index].priorityId = option.key;

            this.setSelected(_items);
        }
    /*===================================*/

    /*==== Helper methods ====*/
        _refresh=()=>{
            const {filters} = this.state;
        
            //Set filter model
            let model;
            filters.map(item=>{ model = {...model,[item.name]:item.value}});
        
            this.setLoading('priorities',true);
            this.setLoading('items',true);
        
            apiRequest.populatePriorities(this).then(res=>
                this.setLoading('priorities',false)
            );

            apiRequest.populateFiles(this,model).then(res =>       
              this.setLoading('items',false)
            );
          }

        addButtonIsDisabled = () =>{
            const { selected } = this.state;

            if(selected.length<1){
                return true;
            }
        }

        initAfterSave = () =>{
            const { parentRefresh } = this.props;
            this.setSelected([]);
            this._refresh();
            parentRefresh();
        }
    /*=========================*/

    render() {
        const { selected, items, priorities, loading,columns,filters,dialogs, open } = this.state;
        const { snackbar, onClose } = this.props;
        return (
              <Dialog onClose={onClose} fullWidth maxWidth="xl" open={true}>
                <DialogTitle>                 
                    <Typography style={{color:'white',fontWeight:'600'}}>Új gyártás</Typography>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>  
                </DialogTitle>
                <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>       
                                <FilterPanel 
                                    loading={loading.items}
                                    onSearch={this._refresh}
                                    onChange={this.setFilters} 
                                    filters={filters}/>

                                <div style={{ boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)' }}>
                                    <div style={{ display:'flex',aligItems:'center',justifyContent:'space-between',padding:'1rem',backgroundColor:'white' }}>
                                    <div>
                                        <Button 
                                            disabled={this.addButtonIsDisabled()}
                                            onClick={this.handleAddElement} 
                                            endIcon={<AddCircleRoundedIcon/>} 
                                            color='primary' 
                                            size="small" 
                                            variant="contained"
                                        >
                                            Gyártás létrehozása
                                        </Button>
                                    </div>
                                    <div>
                                        <Button 
                                            onClick={this._refresh} 
                                            size="small" 
                                            variant="text"
                                        >
                                            <RefreshOutlinedIcon color='info'  fontSize='small'/>
                                        </Button>
                                    </div>
                                    </div>
                                </div>
                                <DataTable
                                    loading={loading.items} 
                                    pagination={50} 
                                    items={items}
                                    setItems = {this.setItems}
                                    length={items.length} 
                                    selected={selected} 
                                    columns={columns}
                                />                                
                    </Grid>
                </Grid>                                                             
            </DialogContent>
            {open && 
            <CreateDialog 
                onClose={()=>this.setOpen(false)} 
                selected={selected} 
                initAfterSave={this.initAfterSave}
                open={true} 
                priorities={priorities}
                onPriorityChange={this.handlePriorityChange}
                onStatementChange={this.handleStatementChange}/>}
            {dialogs.open != null && <ViewWorkPage opened={dialogs.open} open={true} handleClose={(event)=>this.handleDialog('open',null)} isMetal={true} />} 
            {dialogs.edit != null && <EditWorkPage addsOn={{workpage:false,position:true}} snackbar={snackbar} opened={dialogs.edit} open={true} handleClose={(event) => this.handleDialog('edit', null)} isMetal={true} />}
            </Dialog>)
    }
}
export default NewGroup;
