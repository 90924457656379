import React,{Component} from 'react';
//Material UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, 
  IconButton,
  Typography} from "@mui/material";
import {apiRequest} from './apiRequest';
//Material UI Icons
import CloseIcon from '@material-ui/icons/Close';
//Components
import {DataTable} from 'components/Lists';
//Table columns
import TableColumns from './table_data';

class SelectedOrder extends Component{

    state={
        items:[],
        loading:true,
        columns:[]
    }
    componentDidMount(){
        const {selected} = this.props;
        this.setColumns(new TableColumns({onOpen:this.handleOpen}).getColumns());  

        apiRequest.populateResult(this,selected.id);
       
    }

  /*==== Setter methods ====*/
    setColumns = (columns) =>{
      this.setState({
        columns
      });
    }
  /*=========================*/
  
    render(){
        const { loading, columns, items } = this.state;
        const { open, onClose, selected } = this.props;
        return(
          <Dialog
            fullWidth={true}
            maxWidth={'xl'}
            open={open}
            onClose={onClose}
          >
            <DialogTitle>
              <Typography style={{color:'white',fontWeight:'600'}}>
                Gyártási állapot - {selected.name}
              </Typography>
              <IconButton 
                edge="start" 
                color="inherit" 
                onClick={onClose} 
                aria-label="close"
              >
                  <CloseIcon />
              </IconButton>            
            </DialogTitle>
            <DialogContent style={{padding:'1rem'}}>
            <DataTable
              loading={loading} 
              items={items}
              length={items.length} 
              columns={columns}
            />
            </DialogContent>
            <DialogActions>
              <Button 
                size="small" 
                variant="contained" 
                onClick={onClose} 
                color="secondary"
              >
                Vissza
              </Button>
            </DialogActions>
        </Dialog>)
    }
}
export default SelectedOrder;