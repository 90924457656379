import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
//Material UI
import {
    List,
    ListItem,
    Button,
    Menu,
    MenuItem,
    colors
} from  '@mui/material';
  //Material UI Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
    const { pages, pathName, className, ...rest } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [anchorIndex, setAnchorIndex] = React.useState(null)

    const handleClick = (event,index) => {
        setAnchorEl(event.currentTarget);
        setAnchorIndex(index);
    };

    const handleClose = () => {
        setAnchorIndex(null);
        setAnchorEl(null);
    };
    return (
      <List {...rest}>
        {pages.map((page, index) => (
        <ListItem
          style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}
          disableGutters
          key={page.title}
        >
          {page.submenus != null ?
          <Button
            color={pathName.includes(page.href) ? "primary" : "menuButton"}
            style={{ 
                  padding: '10px 8px',
                  justifyContent: 'flex-start',
                  textTransform: 'none',
                  letterSpacing: 0,
                  width: '100%',
                  fontFamily: 'Nunito',
                  fontWeight: 'bold'}}
            onClick={event => handleClick(event, index)}
          >
            <div style={{width: 24, height: 24, display: 'flex', alignItems: 'center', marginRight:'1rem'}}>
              {page.icon}
            </div>
            {page.title}
            <div style={{width: 24, height: 24, display: 'flex', alignItems: 'center', marginLeft:'1rem'}}>
              {anchorIndex == index ? <NavigateNextIcon /> : <ExpandMoreIcon />}
            </div>
          </Button>:
          <Button
            color={pathName.includes(page.href) ? "primary" : "menuButton"}
            style={{ 
                  padding: '10px 8px',
                  justifyContent: 'flex-start',
                  textTransform: 'none',
                  letterSpacing: 0,
                  width: '100%',
                  fontFamily: 'Nunito',
                  fontWeight: 'bold'}}
                  component={CustomRouterLink}
                  to={page.href}
            >
              <div style={{ width: 24, height: 24, display: 'flex', alignItems: 'center'}}>
                {page.icon}
              </div>
              {page.title}
            </Button>}
          </ListItem>
          ))}
          {anchorIndex!=null &&
              <Menu
              style={{marginLeft: '1.2rem', fontWeight: 'bold'}}
              id='submenu'
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {pages[anchorIndex].submenus.map(submenu => (
                  <MenuItem                 
                    color='primary'
                    style={{fontWeight: 'bold',fontSize:'0.85rem',color:pathName.includes(submenu.href) ? '#84BD00' : colors.blueGrey[800]}}
                    key={submenu.title} 
                    component={CustomRouterLink}
                    to={submenu.href} 
                    onClick={handleClose}
                  >
                    {submenu.title}
                  </MenuItem>
              ))}
          </Menu>
          }
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
