import React,{Component} from 'react';
//Material UI
import { 
  Typography,
  Grid, 
  Menu,
  MenuItem
} from  "@mui/material";
//Material UI Icons
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
//Components
import {Constants} from 'stores';
import {Comments,BillDialog,RecallDialog, VirtualDialog, CancelVirtualDialog} from './components';
import { DataTable } from 'components/Lists';
import { FilterPanel,PageTitle } from 'components/Surfaces';
//Api
import {apiRequest} from './apiRequest';
//Table columns
import TableColumns from './table_data';
//Models
import {FilterModel} from 'components/Models';

class PlasticFeedback extends Component {

  state={
    anchor:{
      info:null,
      transactions:null,
    },
    items:[],
    loading:true,
    dialog:{
      bill:null,
      recall:null,
      comments:null,
      virtual:null,
      cancelVirtual:null
    },
    filters:[new FilterModel('id','Azonosító','','text'),
    new FilterModel('workpage','Munkalap','','text'),
    new FilterModel('statuses','Státusz',[],'select',[],true)],
    statuses:[],
    selectedRow:null,
    columns:[]
  }

  componentDidMount(){
    const {loading} = this.state;
    this.setColumns(new TableColumns({onOpen:this.handleDialogOpen,loading}).getColumns());

    apiRequest.populateStore(this,{});
    apiRequest.populateStatuses(this);
    this.setUpSingalR();
  }

  componentDidUpdate(prevProps,prevState){
    const { filters, statuses, loading } = this.state;
    
    if(prevState.loading!=loading){
      this.setColumns(new TableColumns({onOpen:this.handleDialogOpen,loading}).getColumns());
    }

    if(prevState.statuses!=statuses){
        this.setFilters([new FilterModel('id','Azonosító','','text'),
        new FilterModel('workpage','Munkalap','','text'),
        new FilterModel('statuses','Státusz',[],'select',statuses,false)])
    }
  }

  componentWillUnmount(){
    const connections = Constants.getSignalRValue();
    
    connections.office.off('ActionAtWarehouse');
  }


  setUpSingalR = () =>{
    const connections = Constants.getSignalRValue();
      connections.office.on('ActionAtWarehouse', (users) => {
      apiRequest.populateStore(this,{});
    });
  
  }
 
 /*==== Setter methods ====*/

    setItems = (items) =>{
      this.setState({
        items
      })
    }


    setFilters = (filters) =>{
      this.setState({
        filters
      });
    }

    
    setColumns = (columns) =>{
      this.setState({
        columns
      });
    }

    setLoading = (loading) =>{
      this.setState({
        loading
      });
    }
  /*========================*/

  /*==== Helper methods ====*/
    _refresh=()=>{
      const {filters} = this.state;
    
      //Set filter model
      let model;
      filters.map(item=>{ model = {...model,[item.name]:item.value}});
    
      this.setLoading(true);
    
      apiRequest.populateStore(this,model).then(res =>       
          this.setLoading(false)
      )
    }

    afterFeedback = (props) =>{
      this.handleDialogClose(props);
      this._refresh()
    }
  /*=========================*/


  /*==== Handle user interactions ====*/
    handleAnchorClick = (event) =>{
      const name = event.currentTarget.name;
      const currentTarget = event.currentTarget;
      this.setState(prevState=>({
        anchor:{
          ...prevState.anchor,
          [name]:currentTarget
        }
      }));
    }

    handleAnchorClose = () => {
      this.setState(prevState=>({
        anchor:{
          ...prevState.anchor,
          info:null,
          transactions:null
        }
      }));
    };

    handleDialogOpen = (props,value) =>{
      this.setState(prevState=>({
            dialog:{
              ...prevState.dialog,
              [props]:value
            }
      }));
    }


    handleDialogClose = (props) =>{
      this.setState(prevState=>({
            dialog:{
              ...prevState.dialog,
              [props]:null
            }
      }));
    }
  /*===================================*/


  render()
  {
    const {dialog, selectedRow, items, loading, columns, anchor, drawer, filters } = this.state;
    return(      
      <div>
        <div style={{padding:'2rem'}}>
                  <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>            
                        <FilterPanel 
                          loading={loading}
                          onSearch={this._refresh}
                          onChange={this.setFilters} 
                          filters={filters}/>  
                        <DataTable
                          loading={loading} 
                          pagination={50} 
                          items={items}
                          setItems = {this.setItems}
                          length={items.length} 
                          columns={columns}/>   
                    <Menu
                      anchorEl={anchor.transactions}
                      keepMounted
                      open={Boolean(anchor.transactions)}
                      onClose={this.handleAnchorClose}
                      >
                      {selectedRow!=null && selectedRow.previousStatuses.map((status,index)=>(
                        <MenuItem key={status.id}>
                          <Typography>{status.label}:</Typography >
                          <Typography>{status.createdTime}</Typography>
                        </MenuItem>)
                      )}
                    </Menu>                   
                    </Grid>
                    <Grid
                        item
                        xl={1}
                        lg={false}
                        md={false}
                        sm={false}
                        xs={false}
                    ></Grid>
                </Grid>
              </div>
                {dialog.bill!=null && 
                <BillDialog
                  afterFeedback={this.afterFeedback}
                  refresh ={this.refreshGrid} 
                  data={dialog.bill}  
                  open={true} 
                  onClose={()=>this.handleDialogClose('bill')}/>}
                {dialog.recall!=null &&
                <RecallDialog 
                  data={dialog.recall}  
                  afterFeedback ={this.afterFeedback} 
                  open={true} 
                  onClose={()=>this.handleDialogClose('recall')}/>}
                {dialog.virtual!=null &&
                <VirtualDialog 
                  data={dialog.virtual}  
                  afterFeedback ={this.afterFeedback} 
                  open={true} 
                  onClose={()=>this.handleDialogClose('virtual')}/>}
                {dialog.cancelVirtual!=null &&
                <CancelVirtualDialog 
                  data={dialog.cancelVirtual}  
                  afterFeedback ={this.afterFeedback} 
                  open={true} 
                  onClose={()=>this.handleDialogClose('cancelVirtual')}/>}
                {dialog.comments!=null && 
                <Comments 
                  data={dialog.comments} 
                  open={true} 
                  onClose={()=>this.handleDialogClose('comments')} />}
        </div>
    )
  }
}

export default PlasticFeedback;
