import React from 'react';
import PropTypes from 'prop-types';
//Components
import { Topbar } from './components';
import PerfectScrollbar from 'react-perfect-scrollbar'

const Minimal = props => {
  const { children } = props;

  return (
    <div style={{  paddingTop: 64, height: '100%'}}>
       <Topbar />
          <PerfectScrollbar>
              <main style={{ height: '100%'}}>{children}</main>
          </PerfectScrollbar>
        
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Minimal;
