import axios from "axios";
import {UserStore} from 'stores';
import Cookies from 'js-cookie';

export class Api{
  call;
    constructor(props){
      if(UserStore.getUserValue()!==null && UserStore.getUserValue()!==undefined){
        this.call = axios.create({
          baseURL:`${process.env.REACT_APP_API_URL}/`,
          headers:{
           'Authorization': `Bearer ${props==='withCookie' ?  Cookies.get('token') : UserStore.getUserValue().token}`
          }
        });
      }else{
        if(props==='withCookie'){
          this.call = axios.create({
            baseURL:`${process.env.REACT_APP_API_URL}/`,
            headers:{
              'Authorization': `Bearer ${Cookies.get('token')}`
             }
          });
        }else{
          this.call = axios.create({
            baseURL:`${process.env.REACT_APP_API_URL}/`
          });
        }

      } 
  }
}