import {Api,handleResponse,handleError} from 'api';
const Actions = {
    OpenPdf:'metal/mMNumbers/attachments/by-group'
}

export const apiRequest = {
    populatePdf
};

async function populatePdf(parent) {
    const { pdfId } = parent.props;
    await new Api().call.get(`${Actions.OpenPdf}/${pdfId}`).then(handleResponse)
        .then((value) => {
            //Create arraybuffer
            if (value.file != "") {
                 parent.initPdf(value)
             }
    }).catch(handleError);
}
