//React
import React from 'react';
//Fluent UI
import { Text } from "@fluentui/react";
//Material UI
import { Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
 
 /*====Render container with buttons====*/
  const InfosContainer = (props) =>{
    const {onOpenDialog,item, request} = props;

  return <div style={{display:'flex',alignItems:'center',gap:'0.5rem'}}>
  <Button disabled={request} onClick={()=>onOpenDialog("open",item)} style={{fontSize:'0.75rem'}} startIcon={<DeleteIcon fontSize='small'/>}  size='small' variant='contained' color="error">
      Törlés
  </Button>
  </div>
  }
/*=====================================*/

export default class TableColumns{

  constructor(props){
    this.props = props;
  }


  getColumns = () =>{
    const {request} = this.props;
    return [
      {
      key: 'column1',
      name: '#',
      fieldName: 'id',
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'number',
      onRender: (item,index) => (<Text>{index+1}</Text>)
    },             
    {
      key: 'column2',
      name: 'Megnevezés',
      fieldName: 'name',
      minWidth: 250,
      maxWidth: 250,
      isRowHeader: true,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.name}</Text>)
    },
    {
      key: 'column3',
      name: 'Műveletek',
      fieldName: 'actions',
      minWidth: 300,
      maxWidth: 300,
      isResizable: true,
      onRender: (item) => (<InfosContainer item={item} request={request}/>)
    },
    ]
  }
}