import { BehaviorSubject } from 'rxjs';
import Cookies from 'js-cookie';
//Components
import { NotificationBar } from 'components';
import {Api,handleResponse,handleError} from 'api';
import {Constants,UserStore} from 'stores';

//Actions
const Actions = {
    GetUser: 'user/user',
    SignIn: 'user/authenticate'
}


const remainingTimeSubject = new BehaviorSubject(null);
var time = null;
var kicked = false;
export const authenticationService = {
    login,
    logout,
    logoutForced,
    remainingTimeSubject: remainingTimeSubject,
    remainingTime: remainingTimeSubject.asObservable(),
    get remainingTimeValue() { return remainingTimeSubject.value }
};

async function login(id, password) {
    kicked = false;
    const history = Constants.getHistory()
    const model = { felhasznalonev:id, jelszo: password };
    await new Api().call.post(Actions.SignIn, model,{withCredentials: true}).then(value => {
        time = new Date(value.data.refreshTokenExpires);
        UserStore.setUser(value.data);
        UserStore.setRequest(false);
        UserStore.setLoggedInStatus('LOGGED_IN');
        if(history.location.state!==undefined && history.location.state.from!==undefined){
            history.push(history.location.state.from.pathname);
        }else{
            history.push(`/`);
        }
        NotificationBar('Sikeres bejelentkezés', 'top', 'right', 2000, 'success', null);
    },(error)=>{
        handleError(error); 
        throw "error";
    });
}

async function logout() {
    const history = Constants.getHistory()
    Cookies.remove('token');
    UserStore.setUser(null);
    UserStore.setRequest(false);
    if(UserStore.getLoggedInStatusValue()==='LOGGED_IN'){
        NotificationBar('Sikeres kijelentkezés', 'top', 'right', 2000, 'success', null);
    }
    UserStore.setLoggedInStatus('NOT_LOGGED_IN');
    history.push('/sign-in');

}

async function logoutForced() {
    if(kicked==false){
    const history = Constants.getHistory()
    Cookies.remove('token');
    UserStore.setUser(null);
    UserStore.setRequest(false);
    UserStore.setLoggedInStatus('NOT_LOGGED_IN');
    history.push('/sign-in');
    NotificationBar('Lejárt a munkamenet', 'top', 'right', 2000, 'warning', null);
    kicked = true;
    }
}