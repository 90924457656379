import {Api,handleResponse,handleError} from 'api';
//Actions
const Actions = {
    GetStore: 'curtain-wall/warehouse/ready-store',
    GetStatuses:'curtain-wall/warehouse/feedback/statuses',
    Shipped: 'curtain-wall/warehouse/shipped'
}

export const apiRequest = {
    populateStore,
    shipped,
    populateStatuses
};

async function populateStore(parent,model) {
    await new Api().call.post(Actions.GetStore,model).then(handleResponse)
        .then((value) => {
            parent.setState({
                items: value,
                loading: false,
            });
        }).catch(handleError);
}

async function shipped(parent,model) {
    parent.initStates();
    await new Api().call.post(Actions.Shipped,model).then(handleResponse)
        .then((value) => {
            populateStore(parent);
        }).catch(handleError);
}

async function populateStatuses(parent) {
    await new Api().call.get(Actions.GetStatuses).then(handleResponse)
        .then((value) => {
            parent.setState(prevState => ({
                statuses:value
            }));
        }).catch(handleError);

}

