import React, { Component } from 'react';
//Material UI
import { 
    Typography,
    Menu,
    MenuItem
  } from '@mui/material';
//Components
import { Workpages } from './components';
import { DataTable } from 'components/Lists';
import { FilterPanel } from 'components/Surfaces';
import { Statuses } from 'enumsMetal';
//Api
import { apiRequest } from './apiRequest';
//Table columns
import TableColumns from './table_data';

  
class Store extends Component{

    state={
        anchorEl:null,
        infoAnchorEl:null,
        selectedRow:null,
        densityAnchorEl:null,
        columns:[],
        workpages:null        
      }

      componentDidMount(){
        const { loading } = this.props;
        this.setColumns(new TableColumns({onWorkpages:this.setWorkpages,onOpen:this.handleDialogOpen,onAction:this.handleActionClick,loading}).getColumns());
      }

      componentDidUpdate(prevProps,prevState){
        const { loading } = this.props;
        if(prevProps.loading!=loading){
          this.setColumns(new TableColumns({onWorkpages:this.setWorkpages,onOpen:this.handleDialogOpen,onAction:this.handleActionClick,loading}).getColumns());
        }
      }
    
  /*==== Setter methods ====*/
    setColumns = (columns) =>{
      this.setState({
        columns
      });
    }

    setWorkpages = (workpages) =>{
      this.setState({
        workpages
      });
    }
  /*=========================*/

  /*==== Handle user interactions ====*/ 
    handleClick = (event) => {
      this.setState({
        anchorEl:event.currentTarget
      });
    };
    
    handleInfoClick = (event) => {
      this.setState({
        infoAnchorEl:event.currentTarget
      });
    };
    
    handleClose = () => {
      this.setState({
        anchorEl:null,
        infoAnchorEl:null,
      });
    };

    handleActionClick=(id,statusId)=>{
      const { onRefreshStore,setRequest} = this.props;
      setRequest(true);

      if(statusId==Statuses.OsszekeszitesreAtadva){
        apiRequest.collectGroup(id,onRefreshStore,setRequest).finally(()=>{
          setRequest(false);
        });
      }else if(statusId==Statuses.Osszekeszitve){
        apiRequest.sendGroup(id,onRefreshStore,setRequest).finally(()=>{
          setRequest(false);
        });
      }
          
    }
  /*===================================*/

    render() {
        const { items, onView, loading, filters, setFilters, onRefreshStore, setItems } = this.props;
        const { selectedRow, columns, workpages } = this.state;
        return (
          <div>
            <FilterPanel 
              loading={loading}
              onSearch={onRefreshStore}
              onChange={setFilters} 
              filters={filters}/>  
            <DataTable
              loading={loading} 
              pagination={50} 
              items={items}
              setItems={setItems}
              length={items.length} 
              columns={columns}/>   
            {workpages!==null && 
            <Workpages 
              onView={onView} 
              files={workpages.item.pages} 
              workpages={workpages} 
              onClose={()=>this.setWorkpages(null)}/>}
            <Menu
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleClose}>
            {selectedRow!=null && selectedRow.previousStatuses.map((status,index)=>(
              <MenuItem key={status.id}>
                <Typography>{status.label}:</Typography >
                <Typography>{status.createdTime}</Typography>
              </MenuItem>))}
            </Menu>
        </div>
    );
    }


    
};

export default Store;
