import React from 'react';
//Material UI
import { 
  Typography, 
  DialogTitle, 
  DialogContent,
  DialogActions,
  DialogContentText,
  Dialog,
  Tooltip,
  Button,
  IconButton 
} from "@mui/material";
//Material UI Icons
import CloseIcon from '@mui/icons-material/Close';
//Fluent UI
import { TextField, Checkbox } from "@fluentui/react";
//Components
import {apiRequest} from './apiRequest';


export default function VirtualDialog(props) {
 const {onClose,open,data,afterFeedback} = props;
 
  const[note,setNote] = React.useState(data.virtualisGyartasMegjegyzes);
  const[confirm,setConfirm] = React.useState(data.virtualisanGyarthato);
  const [request, setRequest] = React.useState(false);

  const handleNoteChange = (event) =>{
    setNote(event.target.value);
  }

  const handleCheckChange = (event,checked) =>{
    setConfirm(checked)
  }


  const handleSave = () =>{
    setRequest(true);
      const model={
        orderId:data.id,
        note,
        allowVirtualProduction:confirm
      };
      apiRequest.virtual(model).then(res=>{
        afterFeedback('virtual');
      }).catch(error=>{
        setRequest(false)
      });
  }


  return (
      <Dialog 
      fullWidth={true}
      open={open} 
      onClose={onClose} 
      >
        <DialogTitle>                 
          <Typography style={{color:'white',fontWeight:'600'}}>
            Virtuális gyártás - {data.megrendelesNev}
          </Typography>
          <IconButton size='small' edge="start" color="inherit" onClick={onClose}>
            <CloseIcon fontSize='small' />
          </IconButton>  
        </DialogTitle>
        <DialogContent>
        <DialogContentText color="text" style={{padding:'1rem'}}>
          Kérlek írd be a virtuális gyártáshoz szükséges információkat.
        </DialogContentText>
          <TextField
            disabled={request}
            onChange={handleNoteChange}
            autoFocus
            value={note}
            multiline
            rows={4}
            id="tf-note"
            name="note"
            label="Megjegyzés"
            type="text"
            fullWidth
          />
          <div style={{marginTop:'0.5rem'}}>
            <Checkbox checked={confirm} onChange={handleCheckChange}  label="Virtuális gyártás elindítható" />
          </div>
        
        </DialogContent>
        <DialogActions>
          <Button 
            disabled={request}
            variant="contained" 
            size="small" 
            onClick={onClose} 
            color="secondary"
          >
            Vissza
          </Button>
          <Tooltip title={'Mentés'}>
            <span>
              <Button
                variant="contained" 
                size="small" 
                disabled={request} 
                onClick={handleSave} 
                color="primary"
              >
                Mentés
              </Button>
            </span>
          </Tooltip>
         
        </DialogActions>
      </Dialog>
  );
}