//React

import React, { Component } from 'react';
//Fluent UI

import {
  Pivot,
  PivotItem,
  Label
} from '@fluentui/react';
//Material UI 

import { Icon } from '@mui/material';
//Material UI Icons

import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
//Components

import { AllWorkstations } from './components';


export default class Review extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedKey: "1"
    }
  }

  handleChange = (item, event) => {
    this.setState({
      selectedKey: item.props.itemKey
    })
  }


  render() {
    const { isDesktop } = this.props;
    const { selectedKey } = this.state;

    return (
      <Pivot onLinkClick={this.handleChange} selectedKey={selectedKey}>
        {isDesktop &&
          <PivotItem
            itemKey="0"
            headerText="Munkaállomás kiegészítések"
            onRenderItemLink={_customRenderer}
            headerButtonProps={{ 'disabled': true }}
          />
        }
        <PivotItem itemKey="1" headerText="Összes gyártás" >
          <AllWorkstations />
        </PivotItem>
      </Pivot>
    )
  }
}

function _customRenderer(link, defaultRenderer) {
  if (!link || !defaultRenderer) {
    return null;
  }

  return (
    <span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
      <Icon style={{ height: '100%' }} color="primary">
        <TextSnippetOutlinedIcon />
      </Icon>
      <Label style={{ fontWeight: 'bold', fontSize: '1rem' }}>
        {link.headerText}
      </Label>
    </span>
  );
}