//React
import React from 'react';
//Fluent UI
import { 
  Text,
  Dropdown
} from "@fluentui/react";
//Material UI
import { 
    Checkbox,
    Button,
} from "@mui/material";
import PreviewIcon from '@mui/icons-material/Preview';
import EditIcon from '@mui/icons-material/Edit';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { PdfTypes } from 'enumsFF';
 
 /*====Render container with buttons====*/
  const ActionContainer = (props) =>{
    const {onOpenDialog,item} = props;

  return <div style={{display:'flex',alignItems:'center',gap:'0.5rem'}}>
  <Button onClick={()=>onOpenDialog("open",{...item,typeId:PdfTypes.DARABJEGYZEK})} style={{fontSize:'0.75rem'}} startIcon={<PreviewIcon fontSize='small'/>}  size='small' variant='contained' color="primary">
    Darabjegyzék
  </Button>
  <Button onClick={()=>onOpenDialog("open",{...item,typeId:PdfTypes.SZABASTERV})} style={{fontSize:'0.75rem'}} startIcon={<PreviewIcon fontSize='small'/>}  size='small' variant='contained' color="primary">
    Szabásterv
  </Button>
  <Button onClick={()=>onOpenDialog("edit",{...item,typeId:PdfTypes.DARABJEGYZEK})} style={{fontSize:'0.75rem'}} startIcon={<EditIcon fontSize='small'/>} size='small' variant='contained' color="warning">
      Kiegészítés
  </Button>
  </div>
  }
/*=====================================*/

export default class TableColumns{

  constructor(props){
    this.props = props;
  }


  getColumns = () =>{
    const {onStatementChange,priorities,onPriorityChange,request} = this.props;
    return [     
      {
      key: 'column1',
      name: '#',
      fieldName: 'id',
      minWidth: 20,
      maxWidth: 20,
      isRowHeader: true,
      isResizable: true,
      data: 'number',
      isPadded: true,
      onRender: (item) => (<Text>{item.id}</Text>)
    },             
    {
      key: 'column2',
      name: 'Megnevezés',
      fieldName: 'name',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (<Text>{item.name}</Text>)
    },
    {
      key: 'column3',
      name: 'Feltöltve',
      fieldName: 'uploadedTime',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (<Text>{item.uploadedTime}</Text>)
    },
    {
      key: 'column4',
      name: 'Prioritás',
      fieldName: 'priority',
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => ( <Dropdown
        disabled={request}
        selectedKey={item.priorityId}
        onChange={(event,option)=>onPriorityChange(event,option,item)}
        options={priorities}
      />)
    },
    {
      key: 'column5',
      name: 'Nyilatkozat',
      fieldName: 'needStatement',
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (<Checkbox disabled={request} icon={<RadioButtonUncheckedIcon/>} checkedIcon={<RadioButtonCheckedIcon/>} color="primary" checked={item.needStatement} onChange={(event,checked)=>onStatementChange(item,checked)}/>)
    },
    ]
  }
}