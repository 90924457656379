import React from 'react';
//Material UI
import { 
  Typography, 
  DialogTitle, 
  DialogContent,
  DialogActions,
  DialogContentText,
  Dialog,
  Tooltip,
  Button,
  IconButton 
} from "@mui/material";
//Material UI Icons
import CloseIcon from '@mui/icons-material/Close';
//Fluent UI
import { TextField } from "@fluentui/react";
//Components
import {apiRequest} from './apiRequest';


export default function RecallDialog(props) {
 const {onClose,open,data,afterFeedback} = props;

  const[note,setNote] = React.useState('');
  const[confirm,setConfirm] = React.useState('');
  const [request, setRequest] = React.useState(false);

  const handleChange = (event) =>{
    setConfirm(event.target.value);
  }

  const handleNoteChange = (event) =>{
    setNote(event.target.value);
  }


  const handleSave = () =>{
    setRequest(true);

      const model={
        id:data.id,
        note
      };
      apiRequest.cancel(model).then(res=>{
        afterFeedback('recall');
      }).catch(error=>{
        setRequest(false)
      });
  }

  const valid = ((confirm=='' || confirm ==undefined || confirm==null) || (note=='' || note ==undefined || note==null) || confirm!=data.megrendelesNev) ? false : true;

  return (
      <Dialog 
      fullWidth={true}
      open={open} 
      onClose={onClose} 
      >
        <DialogTitle>                 
          <Typography style={{color:'white',fontWeight:'600'}}>
            Számlázás visszavonása - {data.megrendelesNev}
          </Typography>
          <IconButton size='small' edge="start" color="inherit" onClick={onClose}>
            <CloseIcon fontSize='small' />
          </IconButton>  
        </DialogTitle>
        <DialogContent>
        <DialogContentText color="text" style={{padding:'1rem'}}>
          A visszavonáshoz írd be a munkalap nevét. Majd indokold meg miért szeretnéd visszavonni a lejelentést.
        </DialogContentText>
          <TextField
            disabled={request}
            onChange={handleChange}
            value={confirm}
            errorMessage={(confirm=='' || confirm ==undefined || confirm==null) ? 'Kötelező megadni' : null}
            autoFocus
            id="tf-confirm"
            name="confirm"
            required
            label="Munkalap"
            type="text"
            fullWidth
          />
          <TextField
            disabled={request}
            onChange={handleNoteChange}
            errorMessage={(note=='' || note ==undefined || note==null) ? 'Kötelező megadni' : ''}
            autoFocus
            required
            value={note}
            multiline
            rows={4}
            id="tf-note"
            name="note"
            label="Megjegyzés"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button 
            disabled={request}
            variant="contained" 
            size="small" 
            onClick={onClose} 
            color="secondary"
          >
            Vissza
          </Button>
          <Tooltip title={valid!=true ? 'Nem megfelelő adatok' : 'Mentés'}>
            <span>
              <Button
                variant="contained" 
                size="small" 
                disabled={!valid || request} 
                onClick={handleSave} 
                color="primary"
              >
                Mentés
              </Button>
            </span>
          </Tooltip>
         
        </DialogActions>
      </Dialog>
  );
}