import React from 'react';
//Material UI
import { CircularProgress, Popover, Typography } from '@mui/material';
//Components
import { DataTable } from 'components/Lists';
//Table columns
import TableColumns from './table_data';
//Api
import {apiRequest} from './apiRequest';

function Workpages(props){
    const {workpages,onClose,onView,onSubDelete} = props;

    const [columns,setColumns] = React.useState(new TableColumns({parentItem:workpages.item,onSubDelete,onView}).getColumns());
    const [files,setFiles] = React.useState([]);
    const [loading,setLoading] = React.useState(true);

    React.useEffect(()=>{
        apiRequest.getWorkpages(workpages.item.id).then(res=>{
            setFiles(res);
        }).finally(()=>{
            setLoading(false);
        })
    },[])
    
    

        return(<Popover
            open={true}
            onClose={onClose}
            anchorEl={workpages.target}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
            }}
        >
            {loading ? 
            <div style={{padding:'1rem',textAlign:'center'}}>
                <CircularProgress size="1.5rem"/>
                <Typography>Betöltés...</Typography>
            </div> :
             <DataTable
                items={files}
                length={files.length} 
                columns={columns}
            />
            }
        </Popover>)
}

export default Workpages;