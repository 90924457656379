//React
import React from 'react';
//Fluent UI
import { Text } from "@fluentui/react";
//Material UI
import { Button } from "@mui/material";


export default class TableColumns{

  constructor(props){
    this.props = props;
  }


  getColumns = () =>{
    const {onOpen} = this.props;
    return [
      {
      key: 'column1',
      name: '#',
      fieldName: 'id',
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'number',
      onRender: (item) => (<Text>{item.id}</Text>)
    },             
    {
      key: 'column2',
      name: 'Pozíció',
      fieldName: 'position',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.position}</Text>)
    },
    {
      key: 'column3',
      name: 'Elem',
      fieldName: 'item',
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.item}</Text>)
    },
    {
      key: 'column4',
      name: 'Darabszám',
      fieldName: 'quantity',
      minWidth: 60,
      maxWidth: 60,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.quantity}</Text>)
    },
    {
      key: 'column5',
      name: 'Munkaállomás',
      fieldName: 'workstation',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.workstation}</Text>)
    },
    {
      key: 'column6',
      name: 'Dolgozó',
      fieldName: 'worker',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.worker}</Text>)
    },
    {
      key: 'column7',
      name: 'Problémák',
      fieldName: 'problems',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isPadded: true,
      data: 'string',
      onRender: (item) => ( 
        <Button 
          size="small" 
          color="info" 
          variant="contained"
          onClick={(event)=>onOpen(event,item)}
        >
          Megtekintés
        </Button>      
      )
    },
    {
      key: 'column8',
      name: 'Jóváhagyta',
      fieldName: 'reviewer',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,      
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.reviewer}</Text>)
    },
    {
      key: 'column9',
      name: 'Lejelentve',
      fieldName: 'createdTime',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.createdTime}</Text>)
    }
    ]
  }
}