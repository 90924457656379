import React,{Component} from 'react';
//Material UI
import { Grid } from "@mui/material";
//Components
import { Comments, SendDialog, RecallDialog, VirtualDialog } from './components';
import { FilterPanel } from 'components/Surfaces';
import { DataTable } from 'components/Lists';
import { Constants } from 'stores';
//Models
import {FilterModel} from 'components/Models';
//Api
import { apiRequest } from './apiRequest';
//Table columns
import TableColumns from './table_data';


class MetalFeedback extends Component {

  state={
    items:[],
    filters:[new FilterModel('id','Azonosító','','text'),
    new FilterModel('workpage','Munkalap','','text'),
    new FilterModel('statuses','Státusz',[],'select',[],true)],
    loading:true,
    statuses:[],
    dialog:{
      send:null,
      recall:null,
      comments:null,
      virtual:null
    },
    columns:[] 
  }

  componentDidMount(){
    const { loading } = this.state;
    this.setColumns(new TableColumns({onOpen:this.handleDialogOpen,loading}).getColumns());

    apiRequest.populateStore(this,{});
    apiRequest.populateStatuses(this);
    this.setUpSingalR();
  }

  componentWillUnmount(){
    const connections = Constants.getSignalRValue();
    
    connections.office.off('ActionAtMetalFinance');
  }

  
  componentDidUpdate(prevProps,prevState){
    const { filters, statuses, loading } = this.state;

    if(prevState.loading!=loading){
      this.setColumns(new TableColumns({onOpen:this.handleDialogOpen,loading}).getColumns());
    }

    if(prevState.statuses!=statuses){
        this.setFilters([new FilterModel('id','Azonosító','','text'),
        new FilterModel('workpage','Munkalap','','text'),
        new FilterModel('statuses','Státusz',[],'select',statuses,false)])
    }
}

  setUpSingalR = () =>{
    const { filters, statuses } = this.state;
    const connections = Constants.getSignalRValue();
    connections.office.on('ActionAtMetalFinance', (users) => {
      apiRequest.populateStore(this,filters);
    });
  }

  /*==== Setter methods ====*/

    setItems = (items) =>{
      this.setState({
        items
      });
    }

    setColumns = (columns) =>{
      this.setState({
        columns
      });
    }

    setFilters = (filters) =>{
      this.setState({
      filters
      });
    }


/*=========================*/


  handleClick = (event) => {
    this.setState({
      anchorEl:event.currentTarget
    });
  };

  handleInfoClick = (event) => {
    this.setState({
      infoAnchorEl:event.currentTarget
    });
  };

  handleClose = () => {
    this.setState({
        anchorEl:null,
        infoAnchorEl:null,
    });
  };

  handleDialogClose = (props) =>{
    this.setState(prevState=>({
          dialog:{
            ...prevState.dialog,
            [props]:null
          }
    }));
  }

  handleDialogOpen = (props,value) =>{
    this.setState(prevState=>({
          dialog:{
            ...prevState.dialog,
            [props]:value
          }
    }));
  }


   /*==== Helper methods ====*/
    afterFeedback = (props) =>{
      this.handleDialogClose(props);
      this.refreshStore()
    }

    initStates = () =>{
      this.setState({
          loading: true,
      });    
    }

    refreshStore = () => {
        const {filters} = this.state;

        //Set filter model
        let model;
        filters.map(item=>{ model = {...model,[item.name]:item.value}});

        this.initStates();
        apiRequest.populateStore(this,model);
    }
  /*=========================*/




  render()
  {
    const { dialog, items, loading, filters, columns} = this.state;
    return(
        <div style={{padding:'2rem'}}>
          <Grid container spacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <FilterPanel 
                loading={loading}
                onSearch={this.refreshStore}
                onChange={this.setFilters} 
                filters={filters}/> 
              <DataTable
                loading={loading} 
                pagination={50} 
                items={items}
                setItems={this.setItems}
                length={items.length} 
                columns={columns}/>                    
            </Grid>
          </Grid>
          {dialog.send!=null && 
            <SendDialog 
              afterFeedback ={this.afterFeedback} 
              refresh ={this.refreshStore} 
              data={dialog.send}  
              open={true} 
              onClose={()=>this.handleDialogClose('send')}/>}
           {dialog.recall!=null && 
            <RecallDialog 
              afterFeedback ={this.afterFeedback} 
              data={dialog.recall}  
              refresh ={this.refreshStore} 
              open={true} 
              onClose={()=>this.handleDialogClose('recall')}/>}
           {dialog.virtual!=null && 
            <VirtualDialog 
              afterFeedback ={this.afterFeedback} 
              data={dialog.virtual}  
              refresh ={this.refreshStore} 
              open={true} 
              onClose={()=>this.handleDialogClose('virtual')}/>}
            {dialog.comments!=null && 
            <Comments 
              data={dialog.comments} 
              open={true} 
              onClose={()=>this.handleDialogClose('comments')} />}
        </div>
    )
  }
}

export default MetalFeedback;
