
//SignalR
import {
    HubConnectionBuilder,
    HubConnectionState,
    HubConnection,
  } from '@microsoft/signalr';
  //Components
  import { NotificationBar } from 'components';
  import {Constants} from 'stores';


class SignalR{
connection = null;

    constructor(route){
    this.connection =  new HubConnectionBuilder()
        .withUrl(route)
        .withAutomaticReconnect()
        .build();
    }
}


export const signalR = {
    start,
    config,
    restart
}

  async function start (route,name){
    const signalR = new SignalR(route);

    try {
        await signalR.connection.start();
        Constants.setSignalRState({id:name,state:'CONNECTED'});
    } catch (err) {
        Constants.setSignalRState({id:name,state:'NOT_CONNECTED'});
        console.error(`Can't connect to the ${name} signal-r service`,err)
        //NotificationBar(`Nem sikerült kapcsolódni a(z) '${name}' szogáltatáshoz`, 'top', 'right', 4000, 'warning', null);
    }
    return signalR.connection;
};

  async function restart(connection,name){
    try {
      await connection.stop().then(async ()=>{
        await connection.start().then(()=>{
          Constants.setSignalRState({id:name,state:'CONNECTED'});
          console.log(`Connection success with ${name} signal-r service`)
        //NotificationBar(`Helyre állt  a kapcsolat a(z) '${name}' szolgáltatásal`, 'top', 'right', 3000, 'success', null);
        });
      });  
    } catch (err) {
      Constants.setSignalRState({id:name,state:'NOT_CONNECTED'});
      console.error(`Can't connect to the ${name} signal-r service`,err)
      //NotificationBar(`Nem sikerült kapcsolódni a(z) '${name}' szogáltatáshoz`, 'top', 'right', 4000, 'warning', null);
    }
    return connection;
  }

function config(con,name){
    con.onreconnecting(function(err) {
        Constants.setSignalRState({id:name,state:'NOT_CONNECTED'});
        console.error(`Connection lost with ${name} signal-r service`,err)
        //NotificationBar(`Megszakadt a kapcsolat a(z) '${name}' szolgáltatásal`, 'top', 'right', 4000, 'warning', null);
      });
      con.onreconnected(function(err) {
        Constants.setSignalRState({id:name,state:'CONNECTED'});
        console.log(`Connection success with ${name} signal-r service`,err)
        //NotificationBar(`Helyre állt  a kapcsolat a(z) '${name}' szolgáltatásal`, 'top', 'right', 3000, 'success', null);
      });
      con.onclose(function(err){
        Constants.setSignalRState({id:name,state:'NOT_CONNECTED'});
        console.error(`Connection closed with ${name} signal-r service`,err)
      });
    return con;
}