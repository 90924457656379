import React,{Component} from 'react';
//Material UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton
} from "@mui/material";
//Material UI Icons
import CloseIcon from '@mui/icons-material/Close';
import {apiRequest} from './apiRequest';
import {DataTable} from 'components/Lists';
//Table columns
import TableColumns from './table_data';

class MissingsInOrder extends Component{

    state={
        items:[],
        loading:true,
        columns:[]
    }
    componentDidMount(){
        const {selected} = this.props;
        this.setColumns(new TableColumns({}).getColumns());   

        apiRequest.populateResult(this,selected.id)
    }

  /*==== Setter methods ====*/
    setColumns = (columns) =>{
      this.setState({
        columns
      });
    }
  /*=========================*/

    render(){
        const { items, loading, columns } = this.state;
        const { open, onClose, selected } = this.props;
        return(
          <Dialog
            fullWidth={true}
            maxWidth={'xl'}
            open={open}
            onClose={onClose}
          >
             <DialogTitle>
              <Typography style={{color:'white',fontWeight:'600'}}>
                Jelentett hiányok - {selected.name}
              </Typography>
              <IconButton 
                edge="start" 
                color="inherit" 
                onClick={onClose} 
                aria-label="close"
              >
                  <CloseIcon />
              </IconButton>            
            </DialogTitle>
            <DialogContent style={{padding:'1rem'}}>
            <DataTable
                loading={loading} 
                pagination={10} 
                items={items}
                length={items.length} 
                columns={columns}/>
            </DialogContent>
            <DialogActions>
              <Button 
                size="small" 
                variant="contained" 
                onClick={onClose} 
                color="secondary"
              >
                Vissza
              </Button>
            </DialogActions>
          </Dialog>
        )
    }
}
export default MissingsInOrder;