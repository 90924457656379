import React, { Component } from 'react';
//Material UI
import {
    DialogContent,
    DialogTitle,
    Grid,
    Slide,
    Typography,
    Dialog,
    IconButton
} from "@mui/material";
//Icons
import CloseIcon from '@mui/icons-material/Close';
//Components
import { Workpage, EditAddsOn } from './components';
import $ from "jquery";
import { apiRequest } from './apiRequest';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const _OpenDialog = (props) => {
    const { open, handleClose, opened, loading, positions, selected, handleSelect } = props;

    const handleChange = (event,item) => {
        handleSelect(item.key);
        $(".scrollbar-container").animate({ scrollTop: 0 }, "slow");
    }
    
    return (
        <Dialog fullScreen scroll='paper' open={open} onClose={handleClose} TransitionComponent={Transition}>
            <DialogTitle>
                    <Typography style={{color:'white',fontWeight:'600'}}>{opened.name}</Typography>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>                       
            </DialogTitle>
            <DialogContent style={{height:'100vh'}}>
                <div style={{padding: '2rem',height:'100%'}}>
                        <Grid style={{height:'100%'}} container spacing={4}>
                            <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                                {!loading && <Workpage workpageId={parseInt(selected)} workpageName={opened.name} />}
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                {!loading && <EditAddsOn handleChange={handleChange} positions={positions} selected={parseInt(selected)} />}
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
        </Dialog>
    );
}
export default class OpenDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            positions: [],
            selected: null,
            loading: true
        }
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(value) {
        this.setState({
            selected: value
        });
    }

    componentDidMount() {
        apiRequest.populatePositions(this);
    }
    render() {
        const { loading, positions, selected } = this.state;
        const { open, handleClose, opened } = this.props;
        return (<_OpenDialog
            open={open}
            handleClose={handleClose}
            opened={opened}
            loading={loading}
            positions={positions}
            selected={selected}
            handleSelect={this.handleSelect}
        />)
    }

   
}
