//React
import React from 'react';
//Fluent UI
import { Text } from "@fluentui/react";
//Material UI
import { 
    Box,
    Typography,
    LinearProgress,
    IconButton,
    Chip
} from "@mui/material";
//Enums
import {Statuses} from 'enumsFF';
import { Status } from 'components';
//Material UI Icons
import DoneIcon from '@mui/icons-material/Done';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BuildIcon from '@mui/icons-material/Build';
import InboxIcon from '@mui/icons-material/Inbox';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const statuses =[
  new Status(Statuses.OsszekeszitesreAtadva,'secondary','Összekészítésre átadva','small',<PresentToAllIcon/>),
  new Status(Statuses.Osszekeszitve,'secondary','Összekészítve','small',<InboxIcon/>),
  new Status(Statuses.GyartasbaElkuldve,'warning','Gyártásban','small',<BuildIcon/>),
  new Status(Statuses.Kesz,'success','Legyártva','small',<DoneIcon/>),
  new Status(Statuses.Kiszallitva,'info','Kiszállítva','small',<LocalShippingIcon/>),
  new Status(Statuses.Kiszamlazva,'info','Kiszámlázva','small',<AttachMoneyIcon/>),
]

  /*==== Render state progress ====*/
    const LinearProgressWithLabel = (props) => {
      const { item, onOpen } = props;
      return (
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" value={item.progress}/>
          </Box>
          <Box style={{display:'flex', alignItems:'center'}} minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(
              item.progress,
            )}%`}</Typography>
            <IconButton onClick={()=>onOpen("progress",item)} size="small">
              <MoreVertIcon color="info"/>
            </IconButton>
          </Box>
        </Box>
      );
    }
  /*==============================*/
 
 /*==== Render status chip ====*/
  const StatusChip = (props) =>{
    const {item} = props;
    switch(item.status.id){
      case Statuses.OsszekeszitesreAtadva:
        return statuses.find(item=>item.id==Statuses.OsszekeszitesreAtadva).getChip();
      case Statuses.Osszekeszitve:
        return statuses.find(item=>item.id==Statuses.Osszekeszitve).getChip();
      case Statuses.GyartasbaElkuldve:
        return statuses.find(item=>item.id==Statuses.GyartasbaElkuldve).getChip();
      case Statuses.Kesz:
        return statuses.find(item=>item.id==Statuses.Kesz).getChip();
      case Statuses.Kiszallitva:
        return statuses.find(item=>item.id==Statuses.Kiszallitva).getChip();
      case Statuses.Kiszamlazva:
        return statuses.find(item=>item.id==Statuses.Kiszamlazva).getChip();
      case Statuses.KiszamlazasVisszavonva:
        return statuses.find(item=>item.id==Statuses.Kiszallitva).getChip();
      default:
        return null;
    }
  }
/*=====================================*/


/*==== Render others container ====*/
const Others = (props) =>{
  const { item, onOpen } = props;

  return (
    <div style={{width:'100%',display:'flex',alignItems:'center',gap:'0.5rem'}}>
      {item.isReportRepair === true &&           
        <Chip
          size="small"
          onDelete={()=>onOpen("repair",item)}
          label="#Javítandó"
          color="warning"
          deleteIcon={<MoreVertIcon fontSize='small' />}
          variant="contained"/>}
      {item.isReportDeficit === true &&   
      <Chip
        size="small"
        label="#Hiány jelentve"
        color="info"
        onDelete={()=>onOpen("missing",item)}
        deleteIcon={<MoreVertIcon fontSize='small' />}
        variant="contained"/>}
    </div>
  ) 
}
/*=================================*/



export default class TableColumns{

  constructor(props){
    this.props = props;
  }


  getColumns = () =>{
    const { onOpen } = this.props;
    return [
      {
      key: 'column1',
      name: '#',
      fieldName: 'id',
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'number',
      onRender: (item) => (<Text>{item.id}</Text>)
    },             
    {
      key: 'column2',
      name: 'Megnevezés',
      fieldName: 'name',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.name}</Text>)
    },
    {
      key: 'column3',
      name: 'Csoport',
      fieldName: 'groupName',
      minWidth: 300,
      maxWidth: 300,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.groupName}</Text>)
    },
    {
      key: 'column4',
      name: 'Státusz',
      fieldName: 'status',
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      isPadded: true,
      data: 'string',
      onRender: (item) => (<StatusChip item={item}/>)
    },
    {
      key: 'column5',
      name: 'Állapot',
      fieldName: 'state',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isPadded: true,
      data: 'string',
      onRender: (item) => (Statuses.GyartasbaElkuldve === item.status.id && <LinearProgressWithLabel onOpen={onOpen} item={item}/>)
    },
    {
      key: 'column6',
      name: 'Egyéb',
      fieldName: 'others',
      minWidth: 210,
      maxWidth: 210,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (<Others item={item} onOpen={onOpen}/>)
    },
    {
      key: 'column7',
      name: 'Utolsó interakció',
      fieldName: 'mappedUpdatedTime',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.mappedUpdatedTime}</Text>)
    }
    ]
  }
}