//React
import React from 'react';
//Fluent UI
import { Text } from "@fluentui/react";
//Material UI
import { Button,Chip } from "@mui/material";
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PageviewIcon from '@mui/icons-material/Pageview';
import EditIcon from '@mui/icons-material/Edit';
//Enums
import { Priority,PdfTypes } from 'enumsFF';


 
 /*====Render container with buttons====*/
 const ActionContainer = (props) =>{
  const { onView, item } = props;
  let element = <div style={{display:'flex',alignItems:'center',justifyContent:'flex-start',gap:'0.5rem'}}>
    <Button style={{fontSize:'0.75rem'}} size='small' variant='contained' startIcon={<PageviewIcon />} color="primary" onClick={() => onView('open',{...item,typeId:PdfTypes.DARABJEGYZEK})}>
      Darabjegyzék
    </Button>
    <Button style={{fontSize:'0.75rem'}} size='small' variant='contained' startIcon={<PageviewIcon />} color="primary" onClick={() => onView('open',{...item,typeId:PdfTypes.SZABASTERV})}>
      Szabásterv
    </Button>
    <Button style={{fontSize:'0.75rem'}} size='small' variant='contained' startIcon={<EditIcon />} color="warning" onClick={() => onView('edit',{...item,typeId:PdfTypes.DARABJEGYZEK})}>
      Kiegészítés
    </Button>
  </div>

  return element;
}
/*=====================================*/

const InfosContainer = (props) =>{
  const { item } = props;
  return <div style={{display:'flex',width:'100%', alignItems:'center', gap:'0.5rem'}}>
  {item.priorityId === Priority.ELSOBBSEGI 
  && <Chip
         size="small"
         color="warning"
         icon={<NotificationImportantIcon />}
         label="#Elsőbbségi"
     />}
  {item.needStatement 
  && <Chip
         size="small"
         color="info"
         icon={<AssignmentTurnedInIcon />}
         label="#Telj.nyilatkozat"
     />}  
 </div>    
}

export default class TableColumns{

  constructor(props){
    this.props = props;
  }


  getColumns = () =>{
    const { onView } = this.props;
    return [      
      {
        key: 'column1',
        name: 'Megnevezés',
        fieldName: 'name',
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
        onRender: (item) => (<Text>{item.name}</Text>)
      },
      {
        key: 'column2',
        name: 'Kiegészítések',
        fieldName: 'others',
        minWidth: 250,
        maxWidth: 250,
        isResizable: true,
        data: 'string',
        isPadded: true,
        onRender: (item) => (<InfosContainer item={item}/>)
      },
      {
        key: 'column3',
        name: 'Műveletek',
        fieldName: 'actions',
        minWidth: 300,
        maxWidth: 300,
        isResizable: true,
        onRender: (item) => (<ActionContainer onView={onView} item={item}/>)
      },
    ]
  }
}