import React, { Component } from 'react';
//Material UI
import { 
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    IconButton 
} from '@mui/material';
//Material UI Icons
import CloseIcon from '@mui/icons-material/Close';
//Components
import { Store } from './components';



class Upload extends Component {

    render() {
        const { onClose, onRefresh} = this.props;
        return (
            <Dialog onClose={onClose} fullWidth maxWidth="xl" open={true}>
                <DialogTitle>                 
                    <Typography style={{color:'white',fontWeight:'600'}}>Munkalap feltöltése</Typography>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>  
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Store onRefresh={onRefresh}  />
                        </Grid>  
                    </Grid>
                </DialogContent>
            </Dialog>)
    }

    
}

export default Upload;
