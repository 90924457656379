import React, { Component } from 'react';
//Material UI
import {
    DialogContent,
    Grid,
    Slide,
    Dialog,
    DialogTitle,
    Typography,
    IconButton
} from '@mui/material';
//Icons
import CloseIcon from '@mui/icons-material/Close';
//Components
import { Workpage, EditAddsOn, EditAddsOnMetal, EditAddsOnFF } from './components';
import $ from "jquery";
//Api
import { apiRequest } from './apiRequest';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


class Edit extends Component {

        state = {
            positions: [],
            selected: null,
            loading: true
        }
 

    handleChange = (event,item) => {
        this.handleSelect(item);
        $(".scrollbar-container").animate({ scrollTop: 0 }, "slow");
    }

    handleSelect = (value) => {
        this.setState({
            selected: value
        });
    }

    componentDidMount() {
        if(this.props.isMetal){
            apiRequest.populateMetalPositions(this);
        }else if(this.props.isFF){
            apiRequest.populateFFPositions(this);
        }else{
            apiRequest.populatePositions(this);
        }
    
    }

    render() {
        const { loading, positions, selected } = this.state;
        const { open, handleClose, snackbar,editable, opened, group,addsOn, isMetal, isFF } = this.props;
        return ( 
            <Dialog fullScreen scroll='paper' open={open} onClose={handleClose} TransitionComponent={Transition}>
                <DialogTitle>
                        <Typography style={{color:'white',fontWeight:'600'}}>{opened.name}</Typography>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>                       
                </DialogTitle>
                <DialogContent style={{height:'100vh'}}>
                    <div style={{padding: '2rem',height:'100%'}}>
                        <Grid style={{height:'100%'}} container spacing={4}>
                            <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                                {!loading && <Workpage workpageId={selected.key} workpageName={opened.name} typeId={opened.typeId} isMetal={isMetal} isFF={isFF}  />}
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                {!loading && (isMetal===true ?  
                                <EditAddsOnMetal editable={editable} addsOn={addsOn} snackbar={snackbar} opened={opened} handleChange={this.handleChange} positions={positions} group={group} selected={selected.key} /> :
                                isFF===true ? <EditAddsOnFF editable={editable} addsOn={addsOn} snackbar={snackbar} opened={opened} handleChange={this.handleChange} positions={positions} group={group} selected={selected.key} /> : 
                                <EditAddsOn editable={editable} addsOn={addsOn} snackbar={snackbar} opened={opened} handleChange={this.handleChange} positions={positions} group={group} selected={selected.key} />)}
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
        </Dialog>
)
    }

   
}
export default Edit; 
