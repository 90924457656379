import {Api,handleResponse,handleError} from 'api';
//Actions
const Actions = {
    Shipped: 'curtain-wall/warehouse/shipped'
}

export const apiRequest = {
    shipped
};

async function shipped(model) {
    await new Api().call.post(Actions.Shipped,model).then(handleResponse)
        .then((value) => {
        }).catch(handleError);
}
