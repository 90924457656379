
import React, { Component } from 'react';
//Fluent UI
import { Label, ProgressIndicator, Dropdown } from '@fluentui/react';
//Material UI
import { Button, FormControl, MenuItem, Select, InputLabel} from '@mui/material';
//Materail UI Icons
import AssignmentIcon from '@mui/icons-material/Assignment';
//Components
import { Picker } from 'components/Inputs';
import {DateSelect} from 'components/Inputs';
//Api
import { apiRequest } from './apiRequest';


class PlasticDocuments extends Component {

    state={
        selected:[],
        orders:[],
        types:[],
        documents:[],
        selectedType:null,
        selectedDocument:null,
        date:null,
        request:false
    }

    componentDidMount(){
        apiRequest.getTypes().then(res=>{
            this.setState({
                types:res
            })
        })
    }

    componentDidUpdate(prevProps,prevState){
        const {selectedType, selected} = this.state;
        if(prevState.selectedType!==selectedType){
            this.setState({
                selectedDocument:null,
            })
            if(selectedType!==null){
                apiRequest.getDocuments(selectedType.key).then(res=>{
                    this.setState({
                        documents:res
                    })
                })
            }
        }
        if(prevState.selected!==selected){
            if(selected.length>0){
                apiRequest.getTime(selected[0].key).then(res=>{
                    this.setState({
                        date:res
                    })
                })
            }else{
                this.setState({
                    selectedType:null,
                    selectedDocument:null,
                    date:null
                })
            }
        }
    }


    //==== Fill pickers with data ====//
        handleOrderTags = (filterText) =>{
            const model = {filter:filterText};
            return apiRequest.getOrders(model).then(res => {return res});
        }
    //==============================//


    //==== Handle user events ====//
        handleOrderChange = (order) =>{
            //Set state
            this.setState({
                selected:order
            });
        }

        
        handleTypeChange = (event,option) =>{

            this.setState({
                selectedType:option
            })
        }

        handleDocChange = (event,option) =>{

            this.setState({
                selectedDocument:option
            })
        }

        handleDateChange = (name,newDate,newMappedDate)=>{
            this.setState({
                date:{original:newDate,mapped:newMappedDate}
            })
        }

        //TODO
        handleCreateDocument = () =>{
            const {selected, date, selectedDocument} = this.state;
            this.setState({
                request:true
            });
            const model = {orderId:selected[0].key,date,documentId:selectedDocument.key};
            apiRequest.createDocument(model).finally(()=>{
                this.setState({
                    request:false
                });
            })
        }

    
    //=============================//

    validate = () =>{
        const {selected, selectedDocument, selectedType, date} = this.state;
        if(selected===null || selected === undefined || selected.length<1) return false;
        if(selectedDocument===null || selectedDocument===undefined || selectedDocument==='') return false;
        if(selectedType===null || selectedType===undefined || selectedType==='') return false;
        if(date===null || date===undefined || date==='') return false;

        return true;
    }

    render() {
        const {selected, request, types, documents, selectedType, selectedDocument, date} = this.state;
  
        return (  
            <div style={{padding:'2rem'}}>
                <Label style={{fontWeight:'bold', fontSize:'1rem'}}>
                    Munkaszám kiválasztása:
                 </Label>
                <div style={{padding:'0.5rem', backgroundColor:'white', boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)', margin:'0rem 0rem 2rem 0rem'}}>
                    <Picker selected={selected} 
                        disabled={request}
                        itemLimit={1} 
                        apiCall={this.handleOrderTags}
                        onChange={this.handleOrderChange} 
                        headerText="Munkaszám"/>
                </div>           
                
                <Label style={{fontWeight:'bold', fontSize:'1rem'}}>
                        Dokumentum típus kiválasztása:
                </Label>
                <div 
                    style={{padding:'0.5rem', 
                        backgroundColor:'white', 
                        boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                        margin:'0rem 0rem 2rem 0rem'
                    }}
                >
                    <Dropdown
                        style={{maxWidth:'16rem'}}
                        disabled={selected.length<1}
                        selectedKey={selectedType===null ? null : selectedType.key}
                        onChange={this.handleTypeChange}
                        options={types}
                    />
                    <Label style={{fontWeight:'bold', fontSize:'0.9rem'}}>
                        Dokumentum:
                    </Label>
                    <Dropdown
                        disabled={selectedType===null}
                        style={{maxWidth:'16rem'}}
                        onChange={this.handleDocChange}
                        selectedKey={selectedDocument===null ? null : selectedDocument.key}
                        options={documents}
                    />
                
                </div>
                <Label style={{fontWeight:'bold', fontSize:'1rem'}}>
                        Dátum:
                </Label>
                <div 
                    style={{padding:'0.5rem', 
                        backgroundColor:'white', 
                        boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                        margin:'0rem 0rem 2rem 0rem'
                    }}
                >
                    <DateSelect style={{maxWidth:'16rem'}} disabled={request || selected.length<1} errorMessage={(date===null || date===undefined || date ==='') ? 'Nem megengedett érték': null} value={(date!==null && date!==undefined && date!=='') ? new Date(date.original) :''} onChange={this.handleDateChange}/>
                </div>
                {request && <ProgressIndicator/>}
                <div 
                    style={{padding:'0.5rem', 
                        backgroundColor:'white', 
                        display:'flex', 
                        alignItems:'center',
                        justifyContent:request ? 'space-between' : 'flex-end', 
                        boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                        margin:'0rem 0rem 2rem 0rem'
                    }}
                >   
                 {request && <Label>Betöltés...</Label>}
                <Button 
                    onClick={this.handleCreateDocument}
                    variant='contained' 
                    size='small'
                    disabled={request || !this.validate()}
                    endIcon={<AssignmentIcon/>}
                >
                    Dokumentum létrehozása
                </Button>
                </div>
            </div>
        )
    }
}

export default PlasticDocuments;