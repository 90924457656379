import {Api,handleResponse,handleError} from 'api';
//Api calls
const Actions = {
    GetPositions:'curtain-wall/warehouse/positions',//+{mmId}
}

export const apiRequest = {
    populatePositions
};

async function populatePositions(parent) {
    const { opened } = parent.props;
    await new Api().call.get(`${Actions.GetPositions}/${opened.id}`).then(handleResponse)
        .then((value) => {
            if (value.length > 0) {
                parent.setState({
                    positions: value,
                    selected: value[0].key,
                    loading: false,
                });
            }

        }).catch(handleError);

}
