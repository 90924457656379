//React
import React, { Component } from 'react';
//Fluent UI
import { 
    Sticky,
    DetailsRow
} from '@fluentui/react';
import { ProgressIndicator } from 'office-ui-fabric-react';
import {
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
} from 'office-ui-fabric-react/lib/DetailsList';
//Components
import { Pagination } from './components';
//Styles
import './style.css';
import { 
  Typography,
  Divider 
} from "@mui/material";


class DataTable extends Component {

  constructor(props){
    super(props)

    this.state={
       //act page
       page:1,
       //all page
       allPage:1,
       //first page number
       first:0,
       //last page number
      last:this.props.items.length > this.props.pagination ? this.props.pagination : this.props.items.length
    }
  }

  componentDidMount(){
    const {items,pagination} = this.props; 
    //Calculate all page count
    var allPage = Math.ceil(items.length / pagination);
    this.setState({
        allPage
    });
  }
   

    componentDidUpdate(prevProps,prevState){
      const {items,pagination,onSelection} = this.props;
      const {first,page} = this.state;

      if(prevState.first!==first && onSelection!==null && onSelection!==undefined){

        //Set selected rows with active class
        var elements =  document.getElementsByClassName("ms-Checkbox is-checked");
        if(elements.length>0){
          for(let i=0;i<elements.length;i++){
            let rowElement = findAncestor(elements[i],"ms-DetailsRow");
            rowElement.classList.add("selected");
          }
        }
      }

       //Calculate fist and last values
      if(prevState.page!==page){
        if(page===1){
            var _first = page;
            if(items.length<1){
              _first = 0;
            }
            var last = page*pagination;
            if(last>items.length){
                last = items.length;
            }
        }else{
            var _first = ((page-1)*pagination)+1;
            var last = page*pagination;
            if(last>items.length){
                last = items.length;
            }
        }
        //Set states
        this.setState({
            first:_first,
            last
        });
        //Set parent states
        this.setState({
            first:_first-1,
            last
        });
      }

      if(prevProps.items!==items){
        //Calculate all page count
        var allPage = Math.ceil(items.length / pagination);

        var _first = 1;
        if(items.length<1){
          _first = 0;
        }
        var last = page*pagination;
        if(last>items.length){
            last = items.length;
        }

        //Set parent states
        this.setState({
            allPage,
            page:1,
            first:_first,
            last
         });

        //Set parent states
        this.setState({
            first:_first>0 ? _first-1 : 0,
            last
        });
      }
    }

    handleNext = () =>{
      this.setState({
          page:this.state.page+1
      });
    }

    handlePrevious = ()=>{
        this.setState({
            page:this.state.page-1
        });
      }

    handleLast = () =>{
        this.setState({
            page:this.state.allPage
        });
      }

    handleFirst = () =>{
        this.setState({
            page:1
        });
    }

    
  

      render(){
        const {first,last,page} = this.state;      
        const {items,pagination,loading,onInvoke,columns,popperMenu} = this.props;
        //Append with sort option
        let newColumns;
        newColumns = columns.map(col=>{
          return {...col,onColumnClick:this._onColumnClick};
        })

        return (
          <div style={{boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'}}>
              {pagination!==null && pagination!==undefined && <Pagination step={pagination} loading={loading} parent={this} length={items.length} page={page} first={first+1} last={last}/>}
              {loading && <ProgressIndicator styles={{itemProgress:{padding:'0px'}}}/>}
              <Divider/>
              <div className='table-wrapper'>              
                <DetailsList
                    items={(pagination!==null && pagination!==undefined) ? items.slice(first,last) : items}
                    columns={newColumns}
                    setKey="set"
                    onItemInvoked={onInvoke}
                    selectionMode={SelectionMode.none}
                    layoutMode={DetailsListLayoutMode.justified}
                    onRenderDetailsHeader={
                      (detailsHeaderProps, defaultRender) => (
                        <Sticky>
                          {defaultRender(detailsHeaderProps)}
                        </Sticky>
                      ) }
                    isHeaderVisible={true}
                    onRenderRow={this.onRenderColumnListRow}
                  /> 
                  {(loading===true && items.length<1) && 
                    <div className='empty-row'>
                    <Typography>Betöltés...</Typography>
                    </div>
                  }
                  {(loading===false && items.length<1) && 
                    <div className='empty-row'>
                    <Typography>Nincs megjeleníthető sor...</Typography>
                    </div>
                  }
                  {popperMenu}  
              </div>     
              {pagination!==null && pagination!==undefined && <Pagination step={pagination} loading={loading} parent={this} length={items.length} page={page} first={first+1} last={last}/>}
                           
         </div>
        )
      }

      _onColumnClick = (ev, column) => {
        const {items,setItems,columns} = this.props;
        const newColumns= columns.slice();
        const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        //If col sortable
        if(currColumn.isSortable){
          newColumns.forEach((newCol) => {
            if (newCol === currColumn) {
              currColumn.isSortedDescending = !currColumn.isSortedDescending;
              currColumn.isSorted = true;
              this.setState({
                announcedMessage: `${currColumn.name} is sorted ${
                  currColumn.isSortedDescending ? 'descending' : 'ascending'
                }`,
              });
            } else {
              newCol.isSorted = false;
              newCol.isSortedDescending = true;
            }
          });
          const newItems = _copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
          this.setState({
            columns: newColumns
          });
  
          setItems(newItems);
        }

      };

   

      onRenderColumnListRow = (props) => {
        const {first,last} = this.state;
        const {onSelection,selected} = this.props;

        if(onSelection!==null && onSelection!==undefined){

          var index = selected.map(item=>item.key).indexOf(props.item.key);
          if (index > -1) {
            if(props){
              return <DetailsRow className='selected' onClick={(event)=>onSelection(event,props.item)} {...props} />;
            }
          }
          else{
            if(props){
              return <DetailsRow  onClick={(event)=>onSelection(event,props.item)} {...props} />;
            }
          }
        }else{
          return <DetailsRow  {...props} />;
        }
        return null;
      };  
}



function _copyAndSort(items, columnKey, isSortedDescending) {
  const key = columnKey;
  return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}


function findAncestor (el, cls) {
  while ((el = el.parentElement) && !el.classList.contains(cls));
  return el;
}

export default DataTable