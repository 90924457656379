import {Api,handleResponse,handleError} from 'api';
//Actions
const Actions = {
    GetResult:'tracking/extended',
}


export const apiRequest = {
    populateResult,
    
};

async function populateResult(parent,orderId) {
    await new Api().call.get(`${Actions.GetResult}/${orderId}`).then(handleResponse)
        .then((value) => {
            parent.setState(prevState => ({
                items:value,
                loading:false,
            }));
        }).catch(handleError);

}

