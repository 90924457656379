import {Api,handleResponse,handleError} from 'api';
//Actions
const Actions = {
    GetType: 'curtain-wall/warehouse/type',//+{mmId}
    GetComments: 'curtain-wall/warehouse/addsOn/get',//+{mmId}
    SaveComment: 'curtain-wall/warehouse/addsOn/add',
    EditComment:'curtain-wall/warehouse/addsOn/edit',
}


export const apiRequest = {
    populateComments,
    populateType,
    saveComments,
    editComments
};

async function populateComments(parent) {

    const { selected } = parent.props;
    await new Api().call.get(`${Actions.GetComments}/${selected}`).then(handleResponse)
        .then((value) => {
            parent.setState(prevState => ({
                comments: value,
                loading: {
                    ...prevState.loading,
                    comment: false,
                }
            }));
        }).catch(handleError);

}

async function editComments(parent,model,newArray) {
    await new Api().call.post(Actions.EditComment, model).then(handleResponse)
        .then((value) => {
            parent.setState({
                comments: newArray
            });
        }).catch(handleError);
}

async function populateType(parent) {
    parent.setState(prevState => ({
        types: [],
        loading: {
            ...prevState.loading,
            type: true,
        },
        wingQuantity: 0,
        caseQuantity: 0,
        selectedType: -1,
    }));
    const { selected } = parent.props;
    await new Api().call.get(`${Actions.GetType}/${selected}`).then(handleResponse)
        .then((value) => {
            parent.setState(prevState => ({
                types: value.type,
                selectedType: value.selectedType,
                wingQuantity: value.wingQuantity,
                caseQuantity: value.caseQuantity,
                loading: {
                    ...prevState.loading,
                    type: false,
                }
            }));
        }).catch(handleError);

}

async function saveComments(parent) {
    const { selected } = parent.props;
    const { comment } = parent.state;
    const model = { author: 'dfdfdf', content: comment, orderId: selected };
    await new Api().call.post(Actions.SaveComment, model).then(handleResponse)
        .then((value) => {
            parent.setState({
                comment: ''
            });
        }).catch(handleError);
}
