
import React, { Component } from 'react';
//Fluent UI
import { Label, ProgressIndicator } from '@fluentui/react';
//Material UI
import { Divider, Button, FormGroup, FormControlLabel, Checkbox, TextField } from '@mui/material';
//Materail UI Icons
import AssignmentIcon from '@mui/icons-material/Assignment';
//Components
import { Picker } from 'components/Inputs';
import {WorkstationBox, ProblemBox} from './components';
//Api
import { apiRequest } from './apiRequest';

class PlasticReview extends Component {

    state={
        selected:[],
        orders:[],
        problems:[],
        request:false,
        endCheck:{isOk:true,problemDescription:'',reviewer:'',time:'',isDone:false},
    }

    componentDidUpdate(prevProps,prevState){
        const {selected} = this.state;
        
        if(prevState.selected!==selected){
            if(selected.length>0){
                apiRequest.getEndCheck(selected[0].key).then(res=>{
                    this.setState({
                        endCheck:res
                    })
                })
            }else{
                this.setState({
                    endCheck:{isOk:true,problemDescription:'',reviewer:'',time:'',isDone:false},
                })
            }
            
        }
    }

    //==== Fill pickers with data ====//
        handleOrderTags = (filterText) =>{
            const model = {filter:filterText};
            return apiRequest.getOrders(model).then(res => {return res});
        }
    //==============================//


    //==== Handle user events ====//
        handleOrderChange = (order) =>{
            //Set state
            this.setState({
                selected:order
            });
        }

        handleProblems = (value) =>{
            this.setState({
                problems:value
            });
        }

        //TODO
        handleCreateDocument = () =>{
            const {selected} = this.state;
            this.setState({
                request:true
            });
            const model = {key:selected[0].key};
            apiRequest.createDocument(model).finally(()=>{
                this.setState({
                    request:false
                });
            })
        }

        handleMakeEndCheck = () =>{
            const {endCheck, selected} = this.state;

            this.setState({
                request:true
            })

            const model ={isOk:endCheck.isOk,problemDescription:endCheck.problemDescription,orderId:selected[0].key};

            apiRequest.makeEndCheck(model).then(res=>{
                apiRequest.getEndCheck(selected[0].key).then(res2=>{
                    this.setState({
                        endCheck:res2
                    })
                })
            }).finally(()=>{
                this.setState({
                    request:false
                })
            })
        }

        handleTextChange = (event) =>{
            const name = event.target.name;
            const value = event.target.value;

            this.setState(prevState=>({
                endCheck:{
                    ...prevState.endCheck,
                    [name]:value
                }
            }))
        }

        handleCheckbox = (event) =>{
            const name = event.target.name;
            const value = event.target.checked;

            let result;

           if(name==="IsOk"){
               if(value===false){
                result = false;
               }else{
                result = true;
               }            
           }else if(name==="IsNotOk"){
            if(value===false){
                result = true;
               }else{
                result = false;
               }        
           }

           this.setState(prevState=>({
                endCheck:{
                    ...prevState.endCheck,
                    isOk:result
                }
            }))

        }
    //=============================//

    render() {
        const {selected, problems, request, endCheck, declaration} = this.state;
  
        return (  
            <div style={{padding:'2rem'}}>
                <Label style={{fontWeight:'bold', fontSize:'1rem'}}>
                    Munkaszám kiválasztása:
                 </Label>
                <div style={{padding:'0.5rem', backgroundColor:'white', boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'}}>
                    <Picker selected={selected} 
                        disabled={request}
                        itemLimit={1} 
                        apiCall={this.handleOrderTags}
                        onChange={this.handleOrderChange} 
                        headerText="Munkaszám"/>
                    <Label style={{fontWeight:'bold'}}>
                        munkaszámú műanyag nyílászárók a lentebb felsorolt munkafázisok során az alább meghatározott ellenőrzési szempontok alapján kerültek megvizsgálásra és ellenőrzésre, melynek eredményei:								
                    </Label>
                </div>           
                <WorkstationBox 
                    onProblem={this.handleProblems} 
                    title="Szabászat" 
                    boxId={1} 
                    request={request}
                    orderId={selected.length>0 ? selected[0].key : null}/>
                <Divider/>
                <WorkstationBox 
                    onProblem={this.handleProblems} 
                    title="Vasvágás" 
                    boxId={2} 
                    request={request}
                    orderId={selected.length>0 ? selected[0].key : null}/>
                <Divider/>
                <WorkstationBox 
                    onProblem={this.handleProblems} 
                    title="Összeállító" 
                    boxId={3} 
                    request={request}
                    orderId={selected.length>0 ? selected[0].key : null}/>
                <Divider/>
                <WorkstationBox 
                    onProblem={this.handleProblems} 
                    title="Hegesztő / Maró" 
                    boxId={4} 
                    request={request}
                    orderId={selected.length>0 ? selected[0].key : null}/>
                <Divider/>
                <WorkstationBox 
                    onProblem={this.handleProblems} 
                    title="Vasaló" 
                    boxId={5} 
                    request={request}
                    orderId={selected.length>0 ? selected[0].key : null}/>
                <Divider/>
                <WorkstationBox 
                    onProblem={this.handleProblems} 
                    title="Üvegező" 
                    boxId={6} 
                    request={request}
                    orderId={selected.length>0 ? selected[0].key : null}/>
                <Divider/>
                {problems.length>0 && 
                <ProblemBox 
                    items={problems} 
                    onClose={()=>this.handleProblems([])}/>}
                <Label style={{fontWeight:'bold', fontSize:'1rem'}}>
                    VÉGELLENŐRZÉS:
                </Label>
                {request && <ProgressIndicator/>}
                <div   
                    style={{padding:'0.5rem', 
                        backgroundColor:'white', 
                        padding:'1rem',
                        textAlign:'left',
                        gap:'0.5rem',
                        margin:'0rem 0rem 2rem 0rem',
                        boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
                    }}>
                    <Label style={{fontWeight:'bold'}}>
                        {(selected.length>0) ? `A(Z) ${selected[0].name} munkaszámú műanyag nyílászárók a végellenőrzés alapján:` : 'A(Z) - munkaszámú műanyag nyílászárók a végellenőrzés alapján:'}							
                    </Label>
                    {endCheck.isDone && <Label style={{fontWeight:'bold'}}>
                        {`Az ellenörzést ${endCheck.reviewer} elvégezte a következő időpontban: ${endCheck.time}`} 						
                    </Label>}
                    <div style={{marginTop:'0.75rem'}}>
                        <FormControlLabel style={{marginLeft:'0px'}} disabled={(selected.length<1) || request} control={<Checkbox checked={endCheck.isOk} onChange={!endCheck.isDone ? this.handleCheckbox : null} name="IsOk"/>} label="Kiszállítható" />
                        <FormControlLabel disabled={(selected.length<1) || request} control={<Checkbox checked={!endCheck.isOk} onChange={!endCheck.isDone ? this.handleCheckbox : null} name="IsNotOk"/>} label="Nem szállítható"/>
                    </div>                
                    <TextField
                        multiline
                        inputProps={{readOnly:endCheck.isDone}}
                        value={endCheck.problemDescription}
                        onChange={this.handleTextChange}
                        name="problemDescription"
                        rows={3}
                        style={{marginTop:'0.75rem'}}
                        disabled={(selected.length<1) || request}
                        label="Megjegyzés"
                        fullWidth
                    />
                    <Button
                        onClick={this.handleMakeEndCheck}
                        style={{marginTop:'0.75rem'}}
                        variant='contained' 
                        size='small'
                        disabled={selected.length<1 || request || endCheck.isDone}
                    >
                        Lejelentés
                    </Button>
                </div>
                {request && <ProgressIndicator/>}
                <div 
                    style={{padding:'0.5rem', 
                        backgroundColor:'white', 
                        display:'flex', 
                        alignItems:'center',
                        justifyContent:request ? 'space-between' : 'flex-end', 
                        boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
                    }}
                >
                   {request && <Label>Betöltés...</Label>}
                   <Button 
                    onClick={this.handleCreateDocument}
                    variant='contained' 
                    size='small'
                    disabled={selected.length<1 || request || !endCheck.isDone}
                    endIcon={<AssignmentIcon/>}>
                       Dokumentum létrehozása
                   </Button>
                </div>
            </div>
        )
    }
}

export default PlasticReview;