import React, { Component } from 'react';
//Icons
import HomeIcon from '@material-ui/icons/Home';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import TableChartIcon from '@material-ui/icons/TableChart';
import GetAppIcon from '@material-ui/icons/GetApp';
import AssignmentIcon from '@material-ui/icons/Assignment';
import StoreIcon from '@material-ui/icons/Store';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ForwardIcon from '@material-ui/icons/Forward';
import FactCheckRoundedIcon from '@mui/icons-material/FactCheckRounded';
import InventoryIcon from '@mui/icons-material/Inventory';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
//Components
import {UserStore} from 'stores';
import { Roles } from 'enums';

const officePages = [
    {
        title: 'Kezdőlap',
        href: '/office/home',
        icon: <HomeIcon />
    },
    {
        title: 'Munkaállomás kieg.',
        href: '/workstations/notes',
        icon: <TextSnippetOutlinedIcon />
    },
    {
        title: 'Munkalapok',
        href: '/work-pages/set-up',
        icon: <InsertDriveFileIcon />
    },
    {
        title: 'Gyártások',
        href: '/production/set-up',
        icon: <AssignmentIcon />
    },
    {
        title: 'Gyártáskövetés',
        href: '/tracking',
        icon: <ForwardIcon />
    },
    {
        title: 'Gyártás ellenőrzés',
        href: '/review',
        icon: <FactCheckRoundedIcon />
    },
    {
        title: 'Teljesítménynyilatkozatok',
        href: '/documents',
        icon: <InventoryIcon />
    },
    {
        title: 'Visszajelentések',
        href: '/finance/feedback',
        icon: <AssignmentTurnedInIcon />,
    },
];

const warehousePages = [
    {
    title: 'Összekészítés',
    href: '/warehouse/setup/from-group',
    icon: <StoreIcon />
    },
    {
    title: 'Visszajelentés',
    href: '/warehouse/feedback',
    icon: <AssignmentTurnedInIcon />
    },
    {
        title: 'Gyártáskövetés',
        href: '/warehouse/tracking',
        icon: <ForwardIcon />
    },    
]

const adminPages = [
    {
        title: 'Kezdőlap',
        href: '/office/home',
        icon: <HomeIcon />
    },
    {
        title: 'Állomány törzs',
        href: '/products',
        icon: <PeopleAltIcon />
    },
    {
        title: 'Törzsadatok',
        href: '/products',
        icon: <TableChartIcon />
    },
    {
        title: 'Bevitt adatok módosítása',
        href: '/products',
        icon: <AssignmentIcon />
    },
    {
        title: 'Értékelőlapok',
        href: '/products',
        icon: <MenuBookIcon />
    },
    {
        title: 'Adatok exportálása',
        href: '/products',
        icon: <GetAppIcon />
    },
]


const pages = officePages;


export default class Data extends Component {

    constructor(props) {
        super(props);
        this.state = {
          currentUser:UserStore.getUserValue()
        }
    }
    componentDidMount() {
        const { currentUser } = this.state;
        if (currentUser.roles.includes(Roles.IRODA)) {
            this.props.handleSetPages(officePages);
        } else if (currentUser.roles.includes(Roles.RAKTAR)) {
            this.props.handleSetPages(warehousePages);
        }
    }

    render() {
        return ('');
    }
}
