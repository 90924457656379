import React,{Component} from 'react';
//Material UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, 
  Divider,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
  IconButton,
  ListItemIcon,
  Icon
} from "@mui/material";
import {apiRequest} from './apiRequest';
import {DataTable} from 'components/Lists';
//Material UI Icons
import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/Circle';
//Table columns
import TableColumns from './table_data';

class RepairsInOrder extends Component{

    state={
        items:[],
        columns:[],
        anchorEl:{target:null,item:null},
        loading:true,
        selectedRow:null,
    }
    componentDidMount(){
        const {selected} = this.props;
        this.setColumns(new TableColumns({onOpen:this.handleOpen}).getColumns());   

        apiRequest.populateResult(this,selected.id)
    }

  /*==== Setter methods ====*/
    setItems = (items) =>{
      this.setState({
        items
      });
    }

    setColumns = (columns) =>{
      this.setState({
        columns
      });
    }
  /*=========================*/

 
  /*==== Handle user interactions ====*/
    handleClose = () =>{
      this.setState({
        anchorEl:{target:null,item:null},
      });
    }

    handleOpen = (event,item) =>{
      this.setState({
        anchorEl:{target:event.currentTarget,item}
      });
    }
  /*===================================*/

    render(){
        const { items, loading, columns, anchorEl } = this.state;
        const { open, onClose, selected } = this.props;
        return(<Dialog
            fullWidth={true}
            maxWidth={'xl'}
            open={open}
            onClose={onClose}
            aria-labelledby="selected-dialog-title"
          >
             <DialogTitle>
              <Typography style={{color:'white',fontWeight:'600'}}>
                Javítandó elemek - {selected.name}
              </Typography>
              <IconButton 
                edge="start" 
                color="inherit" 
                onClick={onClose} 
                aria-label="close"
              >
                  <CloseIcon />
              </IconButton>            
            </DialogTitle>
            <DialogContent style={{padding:'1rem'}}>
              <DataTable
                loading={loading} 
                pagination={10} 
                items={items}
                setItems={this.setItems}
                length={items.length} 
                columns={columns}/>
              {anchorEl.item!=null &&
               <Popover
               id="repair-options-menu"
               anchorEl={anchorEl.target}
               keepMounted
               anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
                }}
                transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
                }}
               open={Boolean(anchorEl)}
               onClose={this.handleClose}
             >
                <List disablePadding={true}>
                  <ListSubheader style={{lineHeight:'15px',color:'white',padding:'0.5rem',backgroundColor:'#84bd00'}}>Problémák</ListSubheader>
                  <Divider/>
                  {anchorEl.item.problems.length >0 && anchorEl.item.problems.map((problem,index)=>(
                  <ListItem key={problem}>
                     <div style={{display:'flex',alignItems:'center',gap:'0.5rem'}}>
                        <Icon style={{display:'flex',alignItems:'center'}} color='secondary'>
                          <CircleIcon  style={{fontSize:'1rem'}}  />
                        </Icon>   
                        <Typography>
                        {problem}
                        </Typography>
                     </div>   
                  </ListItem>
                  ))}
                  {anchorEl.item.problems.length===0 &&  
                  <ListItem>
                    <ListItemText primary="Nem lett probléma kiválasztva" />     
                  </ListItem>
                  } 
                </List>       
              </Popover>}
            </DialogContent>
            <DialogActions>
              <Button 
                size="small" 
                variant="contained" 
                color="secondary"
                onClick={onClose} 
              >
                Vissza
              </Button>
            </DialogActions>
          </Dialog>)
    }
}
export default RepairsInOrder;