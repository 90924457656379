import { BehaviorSubject } from 'rxjs';

class User {
    user;
    request;
    loggedInStatus;

    constructor(){
      this.user=new BehaviorSubject(null);
      this.request = new BehaviorSubject(true);
      this.loggedInStatus = new BehaviorSubject('NOT_LOGGED_IN');
    }

    setUser(user){
      this.user.next(user);
    }
    getUser(){
      return this.user.asObservable();
    }

    getUserValue(){
      return this.user.value;
    }

    setRequest(request){
      this.request.next(request);
    }

    getRequest(){
      return this.request.asObservable();
    }

    getRequestValue(){
      return this.request.value;
    }

    setLoggedInStatus(loggedInStatus){
      this.loggedInStatus.next(loggedInStatus);
    }

    getLoggedInStatus(){
      return this.loggedInStatus.asObservable();
    }

    getLoggedInStatusValue(){
      return this.loggedInStatus.value;
    }

  }
export const UserStore = new User();