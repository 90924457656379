import {Api,handleResponse,handleError} from 'api';
//Actions
const Actions = {
    GetOrders:'review/orders',
    GetEndCheck:'review/endCheck',
    MakeEndCheck:'review/make-endCheck',
    CreateDocument:'review/create-document'
}


export const apiRequest = {
    getOrders,  
    getEndCheck,
    makeEndCheck,
    createDocument  
};

async function getOrders(model) {
    return await new Api().call.post(Actions.GetOrders,model).then(handleResponse)
        .then((res) => {
            return res
        }).catch(handleError);

}

async function getEndCheck(id) {
    return await new Api().call.get(`${Actions.GetEndCheck}/${id}`).then(handleResponse)
        .then((res) => {
            return res
        }).catch(handleError);

}

async function makeEndCheck(model) {
    return await new Api().call.post(Actions.MakeEndCheck,model).then(handleResponse)
        .then((res) => {
            return res
        }).catch(handleError);

}

async function createDocument(model) {
    return await new Api().call.post(Actions.CreateDocument,model).then(handleResponse)
        .then((res) => {
            var converted = base64ToArrayBuffer(res.data.file);
            var blob = new Blob([converted], {type: "application/octet-stream"});

            //Create the download link
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = res.data.name+res.data.extension;
            link.click();
            return res
        }).catch(handleError);

}

function base64ToArrayBuffer(base64){
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
}


