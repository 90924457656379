import React, { Component } from 'react';
//Material UI
import { 
    Button, 
    ButtonGroup, 
    Divider,
    Typography,
    Grid,
    CircularProgress,
    Checkbox } from '@mui/material';
//Fluent UI
import { TextField } from '@fluentui/react/lib/TextField';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { Label } from '@fluentui/react';
//Components
import { Types } from 'enums';
//Api
import { apiRequest } from './apiRequest';



class EditAddsOnFF extends Component {


    state = {
        loading: {
            type: true,
            comment: true,
            other:true
        },
        comment: '',
        comments: [],
        types: [],
        other:null,
        wingQuantity: 0,
        caseQuantity: 0,
        selectedType: -1,
        modQuantities: false,
        modOther:false,
        deadline:'',
        scheduledDeadline:''


    }


    componentDidMount() {
        const { addsOn } = this.props;
        apiRequest.populateComments(this);
        apiRequest.populateType(this);
        if(addsOn.workpage)
        {
            apiRequest.populateOther(this);
        }

    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.selected !== prevProps.selected) {
            apiRequest.populateComments(this);
            apiRequest.populateType(this);
        }
        if (this.state.comment != prevState.comment && (this.state.comment === "" || this.state.comment === null)) {
            apiRequest.populateComments(this);
        }

    }

    commentHandleChange = (event) => {
        const value = event.target.value;
        this.setState({
            comment: value
        });
    }

    saveCommentClickHandler = () => {
        apiRequest.saveComments(this);
    }

    saveQuantitieshandler = () => {
        apiRequest.saveQuantities(this);
    }

    changeHandler = (event, props) => {
        const value = event.target.value;
        this.setState({
            [props]: value
        });
    }

    modOtherClickHandler = (value) => {
        this.setState({
            modOther: value
        });
        if (!value) {
        }
    }

    modClickHandler = (value) => {
        this.setState({
            modQuantities: value
        });
        if (!value) {
            apiRequest.populateType(this);
        }
    }

    typeChangeHandler = (event,item) => {
        this.setState({
            selectedType: item
        });
    }

    prioChangeHandler = (event,item) => {
        this.setState(prevState => ({         
            other: {
                ...prevState.other,
                selectedPrioritas: item
            }
        }));
    }

    statementHandleChange = (event) => {
        const value = event.target.checked;
        this.setState(prevState => ({
            other: {
                ...prevState.other,
                needStatement: value
            }
        }));
    }

    saveOtherHandler = () => {
        apiRequest.saveOther(this);
    }

    handleCommentChange = (index,value) =>{
        const {comments} = this.state;
        const temp = comments;
        temp[index].content = value;
        this.setState({
            comments:temp
        });
    }

    handleCancelModify = (index) =>{
        const {comments} = this.state;
        const temp = comments;
        temp[index].content = temp[index].originalContent;
        this.setState({
            comments:temp
        });
    }

    handleSaveModifiedComment = (index) =>{
        const {comments} = this.state;
        const temp = comments;
        temp[index].originalContent = temp[index].content;
        const model={id: temp[index].id,content:temp[index].content}

        apiRequest.editComments(this,model,temp);
    }

    

    render() {
        const { comment, comments, types, selectedType, wingQuantity, modOther, caseQuantity, modQuantities, loading, other,scheduledDeadline,deadline } = this.state;
        const { positions, handleChange, selected,addsOn,editable } = this.props;
        return (<React.Fragment>{addsOn.workpage && 
                <div style={{boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',padding:'0.5rem',marginBottom:'1rem'}}>
                    <div style={{display:'flex',alignItems:'center',gap:'0.5rem'}}>
                        <Typography style={{ fontFamily: 'Nunito', fontSize: '1.0rem',fontWeight: 'bold'}}>
                            Munkalaphoz tartozó kiegészítések
                        </Typography>
                        {loading.other && <CircularProgress size="1rem" />}
                    </div>
                    <Divider  />
                    {!loading.other && <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div style={{display:'flex',alignItems:'center',gap:'0.5rem'}}>
                            <Label>Prioritás</Label> 
                        </div>
                        <Dropdown
                            options={other ? other.priosForList : []}
                            disabled={!modOther} 
                            selectedKey={other ? other.selectedPrioritas.key : undefined}
                            onChange={this.prioChangeHandler}
                        />
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div style={{display:'flex',alignItems:'center',gap:'0.5rem'}}>
                            <Label>Nyilatkozat:</Label>                      
                            <Checkbox
                                color='primary' 
                                disabled={!modOther} 
                                checked={other ? other.needStatement : false} 
                                onChange={(event)=>this.statementHandleChange(event)} 
                            />
                        </div>

                    </Grid>
                </Grid>}
                {!loading.other && 
                <div style={{ display:'flex',alignItems:'center',justifyContent:'flex-end',padding:'0.5rem 1rem'}}>
                    {modOther ? 
                    <ButtonGroup size="small">
                        <Button 
                            color='error' 
                            variant='contained' 
                            size="small" 
                            onClick={() => this.modOtherClickHandler(false)}
                        >
                            Mégsem
                        </Button>
                        <Button 
                            color='primary' 
                            variant='contained' 
                            onClick={this.saveOtherHandler}
                        >
                            Mentés
                        </Button>
                    </ButtonGroup> :
                        <Button 
                            size="small" 
                            variant='contained' 
                            color='warning' 
                            onClick={() => this.modOtherClickHandler(true)} 
                        >
                            Módosítás
                        </Button>}
                </div>}
        </div>}
           {addsOn.position && 
           <div style={{boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',padding:'0.5rem'}}>
               <div style={{display:'flex',flexDirection:'column',gap:'1rem'}}>
                   <div>
                        <Typography style={{ fontFamily: 'Nunito', fontSize: '1.0rem',fontWeight: 'bold'}}>
                            Pozíció
                        </Typography>
                        <Divider  />
                        <Dropdown 
                            style={{marginTop:'0.5rem'}}                  
                            options={positions}
                            disabled={modQuantities}
                            selectedKey={selected}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <div style={{display:'flex',alignItems:'center',gap:'0.5rem'}}>
                            <Label>Pozíció típusa  </Label> 
                            {loading.type && <CircularProgress size="1rem" />}
                        </div>
                        <Dropdown
                            options={types}
                            disabled={!modQuantities}
                            selectedKey={selectedType ? selectedType.key : undefined}
                            onChange={this.typeChangeHandler}
                        />
                    </div>                 
                </div>
                    {selectedType.key != Types.SOROLO && 
                    <Grid style={{marginTop: '1rem'}} container spacing={2}>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <TextField 
                                type="number" 
                                onChange={(event) => this.changeHandler(event, 'caseQuantity')} 
                                errorMessage={(!loading.type && caseQuantity <= 0) && "Nem megadható érték"} 
                                suffix={loading.type ? <CircularProgress size="1rem" /> : 'db'} 
                                disabled={!modQuantities} 
                                value={caseQuantity} 
                                label="Tok" />
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <TextField 
                                type="number" 
                                onChange={(event) => this.changeHandler(event, 'wingQuantity')} 
                                errorMessage={(!loading.type && wingQuantity <= 0) && "Nem megadható érték"} 
                                suffix={loading.type ? <CircularProgress size="1rem" /> : 'db'} 
                                disabled={!modQuantities} 
                                value={wingQuantity} 
                                label="Szárny" />
                        </Grid>                      
                    </Grid>}
                    {editable &&
                    <div style={{ paddingBottom: '1.0rem', paddingTop:'1.0rem',justifyContent:'flex-end',display:'flex',alignItems:'center' }}>
                        {modQuantities ?
                        <ButtonGroup>
                            <Button 
                                size="small" 
                                color="error" 
                                variant="contained" 
                                onClick={() => this.modClickHandler(false)}
                            >
                            Mégsem
                            </Button>
                            <Button 
                                size="small" 
                                color="primary" 
                                variant="contained" 
                                onClick={this.saveQuantitieshandler}
                            >
                                Mentés
                            </Button>
                        </ButtonGroup> : 
                        <Button 
                            size="small" 
                            color="warning" 
                            variant="contained" 
                            onClick={() => this.modClickHandler(true)}
                        >
                            Módosítás
                        </Button>}
                    </div>}
                    {comments.length > 0 && 
                    <div>
                        <Typography style={{ fontFamily: 'Nunito', fontSize: '1.0rem',fontWeight: 'bold',marginTop:'1rem'}}>
                            Kiegészítések
                        </Typography>
                        <Divider />
                    </div>}
                    {comments.map((comment, index) => 
                    <div key={comment.id} style={{paddingBottom:'1.0rem'}}>
                        <TextField                     
                            id="outlined-multiline-static"
                            label={comment.author+' - '+comment.createdTime}
                            name="comment"
                            multiline
                            InputProps={{
                                readOnly: !comment.ownComment,
                            }}
                            onChange={(event)=>this.handleCommentChange(index,event.target.value)}
                            value={comment.content}
                            rows={3}
                            variant="outlined"
                        />
                    {comment.originalContent!==comment.content &&   
                    <div style={{display:'flex', justifyContent:'flex-end',margin:'0.5rem'}}>
                        <Button 
                            color="error" 
                            variant="contained" 
                            size="small" 
                            onClick={()=>this.handleCancelModify(index)}
                        >
                            Mégsem
                        </Button>
                        <Button 
                            color="primary" 
                            variant="contained" 
                            size="small" 
                            onClick={()=>this.handleSaveModifiedComment(index)}
                        >
                            Mentés
                        </Button>
                    </div>}
                   
                </div>
                )}
                <Typography style={{ fontFamily: 'Nunito', fontSize: '1.0rem',fontWeight: 'bold',marginTop:'1rem'}}>
                    Új hozzáadása
                </Typography>
                <Divider />
                <TextField
                    id="tf-comment"
                    label="Megjegyzés"
                    name="comment"
                    multiline
                    value={comment}
                    rows={5}
                    variant="outlined"
                    onChange={(event) => this.changeHandler(event, 'comment')}
                />
                <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',padding:'0.5rem'}}>
                    <Button 
                        color="primary" 
                        variant="contained" 
                        size="small" 
                        onClick={this.saveCommentClickHandler}
                    >
                        Rögzítés
                    </Button>
                </div>
               
            </div>}</React.Fragment>)
    }

   

}
export default EditAddsOnFF;


