import {Api,handleResponse,handleError} from 'api';
//Actions
const Actions = {
    GetStore: 'metal/warehouse/store',
    GetStatuses:'metal/warehouse/statuses'
}

export const apiRequest = {
    populateStore,
    populateStatuses
};

async function populateStore(parent,model) {
    
    await new Api().call.post(Actions.GetStore,model).then(handleResponse)
        .then((value) => {
            parent.setState({
                store: value,
                loading: false,
            });
        }).catch(handleError);
}

async function populateStatuses(parent) {
    await new Api().call.get(Actions.GetStatuses).then(handleResponse)
        .then((value) => {
            parent.setState(prevState => ({
                statuses:value
            }));
        }).catch(handleError);

}
