//React
import React from 'react';
//Fluent UI
import { 
  Text
} from "@fluentui/react";
//Material UI
import { 
    Button,
    IconButton
} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
 
 /*====Render container with buttons====*/
  const InfosContainer = (props) =>{
    const {onSend,item} = props;

  return <div style={{display:'flex',alignItems:'center',gap:'0.5rem'}}>
    <Button onClick={()=>onSend(item)} style={{fontSize:'0.75rem'}} endIcon={<ArrowCircleRightIcon fontSize='small'/>}  size='small' variant='contained' color="primary">
        Indítás
    </Button>
  </div>
  }
/*=====================================*/

export default class TableColumns{

  constructor(props){
    this.props = props;
  }


  getColumns = () =>{
    const {onSend,onWorkpages} = this.props;
    return [
      {
      key: 'column1',
      name: '#',
      fieldName: 'id',
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'number',
      onRender: (item) => (<Text>{item.id}</Text>)
    },             
    {
      key: 'column2',
      name: 'Megnevezés',
      fieldName: 'name',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.name}</Text>)
    },
    {
      key: 'column3',
      name: 'Munkalapok',
      fieldName: 'pages',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (<div style={{display:'flex', alignItems:'center',gap:'0.5rem'}}>
        <Text>{item.numberOfWorkpages} db</Text>
        <IconButton color='info' onClick={(event)=>onWorkpages({item,target:event.currentTarget})}>
          <MoreVertIcon />
        </IconButton>
        </div>)
    },
    {
      key: 'column4',
      name: 'Létrehozva',
      fieldName: 'createdTime',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.createdTime}</Text>)
    },
    {
      key: 'column5',
      name: 'Műveletek',
      fieldName: 'actions',
      minWidth: 300,
      maxWidth: 300,
      isResizable: true,
      onRender: (item) => (<InfosContainer onSend={onSend} item={item}/>)
    },
    ]
  }
}