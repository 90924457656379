
import { BehaviorSubject } from 'rxjs';

export class ConstantsInitializer {
    snackbar;
    history;
    pdf_client = process.env.REACT_APP_PDF_CLIENT;
    apiRoute = `${process.env.REACT_APP_API_URL}/`;
    factoryApiRoute=`${process.env.REACT_APP_FACTORY_API_URL}/`;
    signalR;
    signalRState;
    contentLoading;
  
    constructor () {
      this.signalR = new BehaviorSubject(null);
      this.signalRState=new BehaviorSubject([]);
      this.contentLoading = new BehaviorSubject(true);
    }

    setSnackbar(snackbar){
        this.snackbar = snackbar;
    }

    getSnackbar(){
      return this.snackbar;
    }

    setHistory(history){
      this.history = history;
    }

    getHistory(){
    return this.history;
    }

    getPdfClient(){
    return this.pdf_client;
    }

    getApiRoute(){
      return this.apiRoute;
    }
    getFactoryApiRoute(){
      return this.factoryApiRoute;
    }

    setSignalRState(signalRState){
      var temp = this.signalRState.value.filter(item=>item.id!=signalRState.id);
      temp.push(signalRState);
      this.signalRState.next(temp);
    }
    getSignalRState(){
      return this.signalRState.asObservable();
    }
    getSignalRStateValue(){
      return this.signalRState.value;
    }
    getSignalR(){
      return this.signalR;
    }
    getSignalRValue(){
      return this.signalR.value;
    }
    setSignalR(signalR){
      this.signalR.next(signalR);
    }

    getcontentLoading(){
      return this.contentLoading;
    }
    getcontentLoadingValue(){
      return this.contentLoading.value;
    }
    setcontentLoading(contentLoading){
      this.contentLoading.next(contentLoading);
    }
}

export const Constants = new ConstantsInitializer();