//React
import * as React from 'react';
//Fluent UI
import { 
    TagPicker,
    ValidationState 
} from '@fluentui/react/lib/Pickers';

const inputProps = {
    placeholder: 'Kereséshez kezdj el gépelni...'
};





function Picker(props){
  const {itemList,selected,viewTextChange,onTextChange,disabled,itemLimit,onChange,apiCall,headerText} = props;

  const picker = React.useRef(null);

  const pickerSuggestionsProps = {
    suggestionsHeaderText: 'Keresési találatok',
    mostRecentlyUsedHeaderText: headerText,
    noResultsFoundText: 'Nem található',
    loadingText: 'Betöltés',
    showRemoveButtons: false
};

  const onFilterChanged = (
    filterText
  ) => {
    if (filterText) {
        return apiCall(filterText)
    } else {
      return [];
    }
  };

  const filterItemsByText = (filterText) => {
    return itemList.filter(item => doesTextStartWith(item.text, filterText));
  };

  function removeDuplicates(items, possibleDupes) {
    return items.filter(item => !listContainsItem(item, possibleDupes));
  }

  function doesTextStartWith(text, filterText) {
    return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
  }

  function listContainsItem(item, items) {
    if (!items || !items.length || items.length === 0) {
      return false;
    }
    return items.filter(_item => _item.text === item.text).length > 0;
  }

  const returnItems= () => {
    if(itemList!==null && itemList!==undefined){
      return itemList.filter(item=>!selected.includes(item.key));
    }
 
  };

  function validateInput(input) {
    if (input.indexOf('@') !== -1) {
      return ValidationState.valid;
    } else if (input.length > 1) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  }

    function onInputChange(input) {
        if(viewTextChange){
            onTextChange(input);
        }
        return input;
    }

  return (
      <TagPicker
        removeButtonAriaLabel="Remove"
        selectionAriaLabel="Selected colors"
        onResolveSuggestions={onFilterChanged}
        onEmptyInputFocus={returnItems}
        className={'ms-PeoplePicker'}
        onValidateInput={validateInput}
        onInputChange={onInputChange}
        key={'normal'}
        selectedItems={selected}
        pickerSuggestionsProps={pickerSuggestionsProps}
        componentRef={picker}
        itemLimit={itemLimit}
        onChange={(item)=>onChange(item)}
        disabled={disabled}
        inputProps={{
          ...inputProps,
          id: 'item-picker',
        }}
      />
  );
};

export default Picker;