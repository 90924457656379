import React, { Component } from "react";
//Fluent UI
import { Label, Pivot, PivotItem } from '@fluentui/react';
//Material UI
import { Grid } from "@mui/material";
//Components
import { PageTitle } from 'components/Surfaces';
import { SetUpGroup,ScheduleProduction } from './components';
//Material UI Icons
import AssignmentIcon from '@mui/icons-material/Assignment';

class FFGroups extends Component{


    
    render(){
        return(
            <div style={{padding:'2rem'}}>
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Pivot className="pivot-top-selected">
                            <PivotItem headerText="Gyártások kezelése">
                                <SetUpGroup/>
                            </PivotItem>
                            <PivotItem headerText="Gyártások ütemezése">
                                <ScheduleProduction/>
                            </PivotItem>
                        </Pivot>
                    </Grid>
                </Grid>
            </div>)
    }
}

export default FFGroups;