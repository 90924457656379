import {Api,handleResponse,handleError} from 'api';
//Actions
const Actions = {
    GetGroups: 'schedule/groups'
}


export const apiRequest = {
    populateGroups,
};

async function populateGroups(parent,model) {
    await new Api().call.post(Actions.GetGroups,model).then(handleResponse)
        .then((value) => {
            parent.setState({
                items: value
            });
            if(parent.state.sendable!==null){
                let element = value.find(item=>item.id==parent.state.sendable.id);
                parent.setSendable(element);
            }
        }).catch(handleError);
}


