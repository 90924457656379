//React
import React from 'react';
//Fluent UI
import { 
  Text
} from "@fluentui/react";
//Material UI
import { 
    Checkbox,
    Button,
    IconButton,
    Tooltip,
    Chip
} from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

/*====Render Checkbox====*/
  const CheckboxContainer = (props) =>{
    const {item,onSelectChange,selected} = props;

    const isChecked = (item) =>{
      return selected.includes(item.name);
    }

    return  <Checkbox icon={<RadioButtonUncheckedIcon/>} checkedIcon={<RadioButtonCheckedIcon/>} color="primary" checked={isChecked(item)} onChange={(event,checked)=>onSelectChange(checked,item)}  />
  }
/*=======================*/

const IsValidContainer = (props) =>{
  const {item,onShowMore,showMore} = props;

  let element = null;

  if(item.isValid){
    element =  <Chip
    size='small'
    color="primary"
    label='OK'
    onClick={(event)=>onShowMore(showMore? null : {item,target:event.currentTarget})}
    icon={<CheckCircleIcon />}
  />
  }else{
    element = <Chip
      size='small'
      color="warning"
      label={item.missing === 'darabjegyzek' ? "Hiányzik a darabjegyzek.pdf file" : item.missing === 'szabasterv' ? "Hiányzik a szabasterv.pdf file" : item.missing === 'xlsx' ? "Hiányzik a .xlsx file" : 'Hiányzó file'}
      onClick={(event)=>onShowMore(showMore? null : {item,target:event.currentTarget})}
      icon={<WarningIcon />}
    />
  }

    return element;
}

const ShowMoreContainer = (props) =>{
  const {item,showMore,onShowMore} = props;

  return <IconButton id={"bt_"+item.name+"_showMore"} onClick={(event)=>onShowMore(showMore? null : {item,target:event.currentTarget})} size="small" color="primary">
        {(showMore!==null && showMore.item==item) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
      </IconButton>
}
 
 /*====Render container with buttons====*/
  const ActionContainer = (props) =>{
    const {onDelete,item} = props;
    let element = <Button style={{fontSize:'0.75rem'}} size='small' variant='contained' startIcon={<DeleteIcon/>} color="error" onClick={() => onDelete(item)}>
                    Törlés
                  </Button>

    return element;
  }
/*=====================================*/

export default class TableColumns{

  constructor(props){
    this.props = props;
  }


  getColumns = () =>{
    const {checkboxProps,showMore,onShowMore,onDelete} = this.props;
    return [
      {
        key: 'column_checkbox',
        name: 'Checkbox',
        fieldName: 'checkbox',
        minWidth: 30,
        maxWidth: 30,
        isRowHeader: true,
        isResizable: false,
        isIconOnly: true,
        onRender: (item) => (<CheckboxContainer onSelectChange={checkboxProps.onSelectChange} selected={checkboxProps.selected} item={item}/>)
      },    
      {
        key: 'column0',
        name: 'ShowMore',
        fieldName: 'showMore',
        minWidth: 30,
        maxWidth: 30,
        isRowHeader: true,
        isResizable: false,
        isIconOnly: true,
        onRender: (item) => (<ShowMoreContainer showMore={showMore} onShowMore={onShowMore}  item={item}/>)
      },          
      {
        key: 'column1',
        name: 'Megnevezés',
        fieldName: 'name',
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
        onRender: (item) => (<Text>{item.name}</Text>)
      },
      {
        key: 'column2',
        name: 'Gyártási szám',
        fieldName: 'mmNumber',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        data: 'string',
        isPadded: true,
        onRender: (item) => (<Text>{item.name.replace('_', '/')}</Text>)
      },
      {
        key: 'column3',
        name: 'Szükséges file-ok',
        fieldName: 'isValid',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        onRender: (item) => (<IsValidContainer showMore={showMore} onShowMore={onShowMore} item={item}/>)
      },
      {
        key: 'column4',
        name: 'Műveletek',
        fieldName: 'actions',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        onRender: (item) => (<ActionContainer onDelete={onDelete} item={item}/>)
      },
    ]
  }
}