import React, { Component } from 'react';
//Material UI
import {
    Grid,
    Button,
    ButtonGroup
} from "@mui/material";
//Material UI Icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
//Components
import {
    Sended,
    Workpages
} from './components';
import { DataTable } from 'components/Lists';
import { FilterPanel } from 'components/Surfaces';
import { ViewWorkPage } from 'components';

//Models
import {FilterModel} from 'components/Models';
//Table columns
import TableColumns from './table_data';
//Api
import { apiRequest } from './apiRequest';
  

class ScheduleProduction extends Component {

    state = {
        items:[],
        filters:[new FilterModel('id','Azonosító','','text'),
        new FilterModel('name','Megnevezés','','text')],
        loading: true,
        columns:[],    
        workpages:null,
        dialogs:{edit:null,view:null},
        sendable:null,
    }


    componentDidMount() {
        this.setColumns(new TableColumns({onWorkpages:this.setWorkpages,onSend:this.setSendable}).getColumns());

        apiRequest.populateGroups(this,{}).then(res=>{
            this.setLoading(false)
        });
    }

    
    /*==== Setter methods ====*/
        setColumns = (columns) =>{
            this.setState({
            columns
            });
        }

        setItems = (items) =>{
            this.setState({
            items
            });
        }

        setLoading = (loading) =>{
            this.setState({
                loading
            });
        }

        setFilters = (filters) =>{
            this.setState({
                filters
            });
        }

        setWorkpages = (workpages) =>{
            this.setState({
                workpages
            });
        }

        setSendable = (sendable) =>{
            this.setState({
                sendable
            });
        }
    /*=========================*/

    /*==== Helper methods ====*/
        _refresh=()=>{
            const {filters,sendable, items} = this.state;
        
            //Set filter model
            let model;
            filters.map(item=>{ model = {...model,[item.name]:item.value}});
        
            this.setLoading(true);
        
            apiRequest.populateGroups(this,model).then(res => {
                this.setLoading(false);
            })
        }
    /*=========================*/

    /*==== Handle user interactions ====*/
        handleDialog = (props, value) => {
            this.setState(prevState=>({
                dialogs:{
                    ...prevState.dialogs,
                    [props]:value 
                }
            }));
        }
    /*===================================*/


    render() {
        const { loading, columns, items, filters, workpages, dialogs, sendable } = this.state;
        const { snackbar } = this.props;
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <FilterPanel 
                            loading={loading}
                            onSearch={this._refresh}
                            onChange={this.setFilters} 
                            filters={filters}/>  
                        <div style={{ boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)' }}>
                            <div style={{ display:'flex',aligItems:'center',justifyContent:'space-between',padding:'1rem',backgroundColor:'white' }}>
                                <div>
                                </div>
                                <div>
                                    <ButtonGroup size="small">         
                                        <Button 
                                            onClick={this.openInfoCallout} 
                                            size="small" 
                                            variant="text"
                                        >
                                            <InfoOutlinedIcon color='info' fontSize='small'/>
                                        </Button>
                                        <Button 
                                            onClick={this._refresh} 
                                            size="small" 
                                            variant="text"
                                        >
                                            <RefreshOutlinedIcon color='info'  fontSize='small'/>
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                        </div> 
                        <DataTable
                            loading={loading} 
                            pagination={50} 
                            items={items}
                            length={items.length} 
                            columns={columns}
                            setItems={this.setItems}
                        />
                    </Grid>
                </Grid>
                {workpages!==null && 
                <Workpages 
                        onView={this.handleDialog} 
                        files={workpages.item.pages} 
                        workpages={workpages} 
                        onClose={()=>this.setWorkpages(null)}/>}
                {dialogs.open != null && 
                <ViewWorkPage 
                    isFF={true}
                    opened={dialogs.open} 
                    open={true} 
                    handleClose={(event)=>this.handleDialog('open',null)} />}
                {sendable!==null && 
                <Sended 
                    isFF={true}
                    parentRefresh = {this._refresh}
                    sended={sendable} 
                    items={sendable.pages}
                    onClose={()=>this.setSendable(null)}/>}
            </div>)
    }
}

export default ScheduleProduction;
