import React, { Component } from 'react';
//Material UI
import { Grid } from  "@mui/material";


class Groups extends Component {

    render() {
        return (
            <Grid spacing={2} container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            </Grid>
        </Grid>)
    }
}
export default Groups;