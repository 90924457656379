import React, { useState } from 'react';
import PropTypes from 'prop-types';
//Material UI
import { useTheme } from  '@mui/material';
import { useMediaQuery } from  '@mui/material';
//Components
import {
    Sidebar,
    Topbar,
    ScrollToTop,
    LogoutDialog
} from './components';
import PerfectScrollbar from 'react-perfect-scrollbar'
//Styles
import './style.css';



const Main = props => {
    const { children,snackbar } = props;
    const pathName = children.props.location.pathname;
    const history = children.props.history;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

    const [openSidebar, setOpenSidebar] = useState(false);
    const [openTopScroll, setOpenTopScroll] = useState(false);
    const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

  const handleSidebarOpen = () => {
      setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const handleOpenTopScroll = () => {
      setOpenTopScroll(true);
  };

  const handleCloseTopScroll = (container) => {
      if (container.scrollTop == 0) {
            setOpenTopScroll(false);
      }       
  };

  const handleLogoutDialogOpen = () => {
      setOpenLogoutDialog(true);
  }

  const handleLogoutDialogClose = () => {
        setOpenLogoutDialog(false);
  }

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

    return (
    <div className="main-root">
            <Topbar openSidebar={openSidebar} onSidebarOpen={handleSidebarOpen} onLogoutDialogOpen={handleLogoutDialogOpen} />
            <Sidebar
                pathName={pathName}
                history={history}
                onClose={handleSidebarClose}
                onLogoutDialogOpen={handleLogoutDialogOpen}
                open={shouldOpenSidebar}
                variant={isDesktop ? 'persistent' : 'temporary'}
      />
            <main style={{height:'100%'}}>
                <PerfectScrollbar onYReachEnd={container => handleCloseTopScroll(container)} onScrollY={handleOpenTopScroll} onYReachStart={handleCloseTopScroll}>
                    <div id="to-up"></div>
                    {children}
                </PerfectScrollbar>   
          </main>
            <ScrollToTop open={openTopScroll} />
            <LogoutDialog snackbar={snackbar} open={openLogoutDialog} closeHandler={handleLogoutDialogClose} />
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
