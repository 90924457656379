import {Api,handleResponse,handleError} from 'api';
import { NotificationBar } from 'components';
//Actions
const Actions = {
    GetWorkpages: 'metal/warehouse/workpages',//+{groupId}'
}


export const apiRequest = {
    getWorkpages,
};

async function getWorkpages(selected) {
    return await new Api().call.get(`${Actions.GetWorkpages}/${selected}`).then(handleResponse)
     .then((value) => {
         return value
        }).catch(handleError);
}
