import React from 'react';
//Material UI
import { 
    Dialog,
    Button, 
    IconButton, 
    Typography, 
    DialogTitle,
    DialogActions,
    DialogContent,
    Grid,
    CircularProgress 
} from '@mui/material';
//Material UI Icons
import CloseIcon from '@mui/icons-material/Close';
//Fluent UI
import { TextField } from '@fluentui/react';
//Api
import { apiRequest } from './apiRequest';
//Components
import { DataTable } from 'components/Lists';
//Table columns
import TableColumns from './table_data';

export default function CreateDialog(props) {

    const { open, onClose, initAfterSave, priorities, selected, onPriorityChange, onStatementChange } = props;

    const [name,setName] = React.useState('');
    const [description,setDescription] = React.useState('');
    const [request,setRequest] = React.useState(false);
    const [columns,setColumns] = React.useState(new TableColumns({request,priorities,onPriorityChange,onStatementChange}).getColumns());

    React.useEffect(()=>{
        setColumns(new TableColumns({request,priorities,onPriorityChange,onStatementChange}).getColumns());
    },[request])

  
    /*==== Handle events ====*/
        const handleSave = () =>{
            setRequest(true);

            const model = {saveObjects:selected,name,description};

            apiRequest.saveGroup(model).then(res=>{
                initAfterSave();
                onClose();
            }).catch(error=>{
                setRequest(false);
            })
        }
    /*========================*/

    /*==== Helper methods ====*/
        const fieldsAreValid = () =>{
            if(name===null || name===undefined || name===''){
                return false
            }
            return true;
        }
    /*=========================*/
    return (
            <Dialog onClose={onClose} fullWidth maxWidth="xl"  open={open}>
                <DialogTitle>                 
                    <Typography style={{color:'white',fontWeight:'600'}}>Gyártás létrehozása</Typography>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>  
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                errorMessage={(name===null || name===undefined || name==='') && 'Kötelező megadni'}
                                readOnly={request}
                                name={name}
                                onChange={(event,value)=>setName(value)}
                                label='Gyártás megnevezése'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                readOnly={request}
                                name={description}
                                label='Leírás'
                                onChange={(event,value)=>setDescription(value)}
                                multiline={true}
                                rows={6}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DataTable
                                pagination={10} 
                                items={selected}
                                length={selected.length} 
                                columns={columns}/>  
                        </Grid>
                    </Grid>                
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={request}
                        color='error' 
                        size="small" 
                        variant="contained" 
                        onClick={onClose} 
                    >
                        Vissza
                    </Button>
                    <Button
                        disabled={request || !fieldsAreValid()}
                        color='primary' 
                        size="small" 
                        variant="contained" 
                        onClick={handleSave} 
                    >
                       {request ? <span style={{display:'flex',alignItems:'center',gap:'0.5rem'}}>Létrehozás <CircularProgress size="1rem"/></span>: 'Létrehozás'}
                    </Button>
                </DialogActions>
            </Dialog>
    );
}
