import React, { Component } from 'react';
//Material UI

import {
    Grid,
    CircularProgress,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    ButtonGroup
} from "@mui/material";
//Material UI Icons

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
//Components

import { DataTable } from 'components/Lists';
import { apiRequest } from './apiRequest';
import { Upload, View } from './components';
//Table columns

import TableColumns from './table_data';

class AllWorkstations extends Component {

    state = {
        items: [],
        loading: true,
        dialogs: {
            open: false
        },
        dialogDatas: {
            open: null
        },
        request: false,
        selected: [],
        deleteAction: null,
        columns: [],
        addNew: null,
    }

    componentDidMount() {
        const { selected, request } = this.state;
        this.setColumns(new TableColumns({ checkboxProps: { selected, deleteRequest: request, onSelectChange: this.handleSelectChange }, onOpenDialog: this.handleOpenDialog }).getColumns());

        apiRequest.populateFiles(this).then(res =>
            this.setLoading(false)
        );

    }

    componentDidUpdate(prevProps, prevState) {
        const { filters, selected, request, items } = this.state;

        if (prevState.selected != selected) {
            this.setColumns(new TableColumns({ checkboxProps: { selected, deleteRequest: request, onSelectChange: this.handleSelectChange }, onOpenDialog: this.handleOpenDialog }).getColumns());
        }

        if (prevState.request != request) {
            this.setColumns(new TableColumns({ checkboxProps: { selected, deleteRequest: request, onSelectChange: this.handleSelectChange }, onOpenDialog: this.handleOpenDialog }).getColumns());
        }

        if (prevState.items != items) {
            if (items.length < 1) {
                this.setSelected([]);
            }

        }
    }


    /*==== Setter methods ====*/
    setRequest = (request) => {
        this.setState({
            request
        });
    }

    setColumns = (columns) => {
        this.setState({
            columns
        });
    }

    setItems = (items) => {
        this.setState({
            items
        });
    }

    setSelected = (selected) => {
        this.setState({
            selected
        });
    }

    setDeleteAction = (deleteAction) => {
        this.setState({
            deleteAction
        })
    }

    setLoading = (loading) => {
        this.setState({
            loading
        });
    }

    setAddNew = (addNew) => {
        this.setState({
            addNew
        });
    }
    /*=========================*/



    /*==== Handle user interactions ====*/
    handleSelectChange = (checked, item) => {
        var result = this.state.selected;
        if (checked) {
            result.push(item.id);
        } else {
            var index = result.indexOf(item.id)
            if (index > -1) {
                result.splice(index, 1);
            }
        }

        this.setSelected(result);
    }

    handleCancelDelete = () => {
        this.setDeleteAction(null);
    }


    handleOpenDialog = (props, row) => {
        this.setState(prevState => ({
            dialogs: {
                ...prevState.dialogs,
                [props]: true
            },
            dialogDatas: {
                ...prevState.dialog,
                [props]: row
            }
        }));
    }

    handleDialogClose = (props) => {
        this.setState(prevState => ({
            dialogs: {
                ...prevState.dialogs,
                [props]: false
            },
            dialogDatas: {
                ...prevState.dialog,
                [props]: null
            }
        }));
    };
    /*===================================*/


    /*==== Handle events ====*/
    handleDelete = () => {
        const { selected } = this.state;
        this.setRequest(true);

        //Call api
        apiRequest.deleteAction(this, selected).then(res => {
            this.setDeleteAction(null);
            this.setSelected([]);
            this._refresh();
            this.setRequest(false);
        }).catch(error => {
            this.setRequest(false);
        });
    }
    /*========================*/




    /*==== Helper methods ====*/
    _refresh = () => {
        this.setLoading(true);

        apiRequest.populateFiles(this).then(res =>
            this.setLoading(false)
        )
    }
    /*=========================*/


    render() {
        const { items, loading, columns, selected, dialogDatas, dialogs, deleteAction, request, addNew } = this.state;
        const { snackbar } = this.props;
        return (
            <div>
                <div style={{ padding: '2rem' }}>
                    <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div style={{ boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)' }}>
                                <div style={{ display: 'flex', aligItems: 'center', justifyContent: 'space-between', padding: '1rem', backgroundColor: 'white' }}>
                                    <div>
                                        <ButtonGroup>
                                            <Button
                                                onClick={this.setAddNew}
                                                endIcon={<FileUploadIcon />}
                                                color="primary"
                                                size="small"
                                                disabled={items?.length > 0}
                                                variant="contained"
                                            >
                                                Új feltöltés
                                            </Button>
                                            <Button
                                                onClick={this.setDeleteAction}
                                                disabled={selected.length < 1}
                                                endIcon={<DeleteIcon />}
                                                color="error"
                                                size="small"
                                                variant="contained"
                                            >
                                                Törlés
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                    <div>
                                        <ButtonGroup size="small">

                                            <Button
                                                onClick={this.openInfoCallout}
                                                size="small"
                                                variant="text"
                                            >
                                                <InfoOutlinedIcon color='info' fontSize='small' />
                                            </Button>
                                            <Button
                                                onClick={this._refresh}
                                                size="small"
                                                variant="text"
                                            >
                                                <RefreshOutlinedIcon color='info' fontSize='small' />
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            </div>
                            <DataTable
                                loading={loading}
                                pagination={50}
                                items={items}
                                setItems={this.setItems}
                                length={items.length}
                                selected={selected}
                                columns={columns}
                            />
                        </Grid>
                    </Grid>
                </div>
                {addNew !== null &&
                    <Upload
                        onRefresh={this._refresh}
                        onClose={() => this.setAddNew(null)}
                    />
                }
                {dialogDatas.open != null &&
                    <View
                        opened={dialogDatas.open}
                        open={dialogs.open}
                        handleClose={(event) => this.handleDialogClose('open', event)} />
                }
                <Dialog open={deleteAction !== null}>
                    <DialogTitle>
                        Megerősítés
                    </DialogTitle>
                    <DialogContent dividers={true} style={{ textAlign: 'center' }} >
                        <DialogContentText>
                            {request ? 'Törlés folyamatban...' : 'Biztos törölni szeretnéd ?'}
                        </DialogContentText>
                        {request && <CircularProgress size={'2.0rem'} />}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={request}
                            size='small'
                            variant='contained'
                            color='error'
                            onClick={this.handleCancelDelete}
                        >
                            Nem
                        </Button>
                        <Button
                            disabled={request}
                            size='small'
                            variant='contained'
                            color="primary"
                            onClick={this.handleDelete}
                        >
                            Igen
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>)
    }
}
export default AllWorkstations;