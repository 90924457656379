const Statuses = {
    OsszekeszitesreAtadva:1,
    GyartasbaElkuldve:2,
    Elinditva:3,
    Kesz:4,
    Uj:5,
    KeszMarasNelkul:6,
    Javitando:7,
    Ujragyartando:8,
    TobbiElemreVar:9,
    Osszekeszitve:10,
    Kiszallitva:11,
    KiszallitvaVisszavonva:12,
    Kiszamlazva:13,
    KiszamlazasVisszavonva:14,
    VegellenorzesreVar:15
}

export default Statuses;