import React, { Component } from 'react';
//Material UI
import { 
    Grid,
    Button,
    Dialog,
    IconButton,
    Typography,
    DialogTitle,
    DialogContent
 } from "@mui/material";
//Material UI Icons
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import CloseIcon from '@mui/icons-material/Close';
//Fluent UI
import { 
    Pivot,
    PivotItem 
} from '@fluentui/react';
//Components
import {Added} from './components';
import { DataTable } from 'components/Lists';
import { FilterPanel } from 'components/Surfaces';
import { ViewWorkPage,EditWorkPage } from 'components';
//Models
import {FilterModel} from 'components/Models';
//Table columns
import TableColumns from './table_data';
//Api
import { apiRequest } from './apiRequest';


class EditGroup extends Component {


    state = {
        priorities: [],
        items: [],
        selected: [],
        added:this.props.editable.pages,
        filters:[new FilterModel('id','Azonosító','','text'),
        new FilterModel('name','Megnevezés','','text')],
        loading: {
            priorities: true,
            items: true
        },
        columns:[],    
        pivotKey:'Gyártás',
        dialogs:{edit:null,view:null}
    }

    componentDidMount() {
        const {selected,added} = this.state;
        this.setColumns(new TableColumns({checkboxProps:{selected,added,onSelectChange:this.handleSelectChange},onOpenDialog:this.handleDialog}).getColumns());

        apiRequest.populateFiles(this,{}).then(res=>{
            this.setLoading('items',false);
        });
        apiRequest.populatePriorities(this).then(res=>{
            this.setLoading('priorities',false);
        });
    }

    componentDidUpdate(prevProps,prevState){
        const {filters,selected,items,added} = this.state;
    
        if(prevState.selected!=selected){
          this.setColumns(new TableColumns({checkboxProps:{selected,added,onSelectChange:this.handleSelectChange},onOpenDialog:this.handleDialog}).getColumns());
        }

        if(prevState.added!=added){
            this.setColumns(new TableColumns({checkboxProps:{selected,added,onSelectChange:this.handleSelectChange},onOpenDialog:this.handleDialog}).getColumns());
        }
       
    }

   
    /*==== Setter methods ====*/    
        setColumns = (columns) =>{
            this.setState({
                columns
            });
        } 

        setItems = (items) =>{
            this.setState({
                items
            });
        }

        setSelected = (selected) =>{
            this.setState({
                selected
            });
        }

        setLoading = (prop,value) =>{
            this.setState((prevState)=>({
                loading:{
                    ...prevState.loading,
                    [prop]:value
                }
            }));
        }

        setFilters = (filters) =>{
            this.setState({
            filters
            });
        }

        setAdded = (added) =>{
            this.setState({
                added
            });
        }

        setPivotKey = (pivotKey) =>{
            this.setState({
                pivotKey
            });
        }
    /*=========================*/

 
    /*==== Handle user interactions ====*/
        handleSelectChange = (checked,item) =>{
            var result = this.state.selected;
            if(checked){
                result.push(item);
            }else{
                const temp = result.map(item=>{return item.id});
                var index = temp.indexOf(item.id)
                if (index > -1) {
                result.splice(index, 1);
                }
            }
        
            this.setSelected(result);
        }

        handleAddElement = () =>{
            const {selected,added} = this.state;
            const temp = [...added];

            const _added = added.map(item=>{return item.id});

            const intersection = selected.filter(element => !_added.includes(element.id));
            temp.push(...intersection);

            this.setAdded(temp);
        }

        handlePivotChange = (item,event) =>{
            const {props} = item; 

            this.setPivotKey(props.itemKey);
        }

        handleDialog = (props, value) => {
            this.setState(prevState=>({
                dialogs:{
                    ...prevState.dialogs,
                    [props]:value 
                }
            }));
        }
    /*===================================*/

    /*==== Helper methods ====*/
        _refresh=()=>{
            const {filters} = this.state;

            //Set filter model
            let model;
            filters.map(item=>{ model = {...model,[item.name]:item.value}});
        
            this.setLoading('priorities',true);
            this.setLoading('items',true);
        
            apiRequest.populatePriorities(this).then(res=>
                this.setLoading('priorities',false)
            );

            apiRequest.populateFiles(this,model).then(res =>       
              this.setLoading('items',false)
            );
          }

        addButtonIsDisabled = () =>{
            const {selected,added,items} = this.state;

            if(selected.length<1){
                return true;
            }
            const _added = added.map(item=>{return item.id});
            const _items = items.map(item=>{return item.id});

            const intersection = _items.filter(element => _added.includes(element));
            if(intersection.length===items.length){
                return true;
            }

            return false;
        }

        afterSave = () =>{
            const {onClose,refreshParent} = this.props;

            refreshParent();
            onClose();
        }
    /*=========================*/

    render() {
        const { selected, items, priorities, loading,columns,filters,added,pivotKey,dialogs } = this.state;
        const {snackbar, onClose, editable} = this.props;
        return (   
            <Dialog open={true} maxWidth="xl" fullWidth>
                <DialogTitle>                 
                    <Typography style={{color:'white',fontWeight:'600'}}>{editable.name} - gyártás módosítása</Typography>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>  
                </DialogTitle>
             <DialogContent style={{padding:'1rem'}}>  
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>     
                        <Pivot onLinkClick={this.handlePivotChange} selectedKey={pivotKey}>
                            <PivotItem headerButtonProps={added.length<1 && {'disabled': true,'style': {color: 'grey',cursor:'default'}}} itemKey='Gyártás' itemCount={added.length} headerText="Gyártás">
                                <Added
                                    afterSave={this.afterSave}
                                    editable={editable}
                                    items={added} 
                                    selected={selected}
                                    onDialog={this.handleDialog}
                                    priorities={priorities} 
                                    setSelected={this.setSelected} 
                                    setAdded={this.setAdded} 
                                    setPivotKey={this.setPivotKey} 
                                    refresh={this._refresh}/>
                            </PivotItem>
                            <PivotItem itemKey='Választható munkalapok' headerText="Választható munkalapok">
                                <FilterPanel 
                                    loading={loading}
                                    onSearch={this._refresh}
                                    onChange={this.setFilters} 
                                    filters={filters}/>

                                <div style={{ boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)' }}>
                                    <div style={{ display:'flex',aligItems:'center',justifyContent:'space-between',padding:'1rem',backgroundColor:'white' }}>
                                    <div>
                                        <Button 
                                            disabled={this.addButtonIsDisabled()}
                                            onClick={this.handleAddElement} 
                                            endIcon={<AddCircleRoundedIcon/>} 
                                            color='primary' 
                                            size="small" 
                                            variant="contained"
                                        >
                                            Gyártáshoz adás
                                        </Button>
                                    </div>
                                    <div>
                                        <Button 
                                            onClick={this._refresh} 
                                            size="small" 
                                            variant="text"
                                        >
                                            <RefreshOutlinedIcon color='info'  fontSize='small'/>
                                        </Button>
                                    </div>
                                    </div>
                                </div>
                                <DataTable
                                    loading={loading.items} 
                                    pagination={50} 
                                    items={items}
                                    setItems = {this.setItems}
                                    length={items.length} 
                                    selected={selected} 
                                    columns={columns}
                                />
                            </PivotItem>
                        </Pivot>                                   
                    </Grid>
                </Grid>     
                {dialogs.open != null && 
                <ViewWorkPage 
                    isMetal={true}
                    opened={dialogs.open} 
                    open={true} 
                    handleClose={(event)=>this.handleDialog('open',null)} />} 
                {dialogs.edit != null && 
                <EditWorkPage 
                    isMetal={true}
                    addsOn={{workpage:false,position:true}} 
                    snackbar={snackbar} 
                    opened={dialogs.edit} 
                    open={true} 
                    handleClose={(event) => this.handleDialog('edit', null)} />}                                                        
            </DialogContent></Dialog>)
    }
}
export default EditGroup;
