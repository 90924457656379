import { createTheme } from "@fluentui/react";

const appTheme = createTheme({
    defaultFontStyle: { fontFamily: 'Nunito', fontWeight: 'sans-serif' },
    palette: {
        themePrimary: '#84bd00',
        themeLighterAlt: '#f9fcf2',
        themeLighter: '#e9f4cd',
        themeLight: '#d6eba5',
        themeTertiary: '#b0d756',
        themeSecondary: '#91c518',
        themeDarkAlt: '#77aa00',
        themeDark: '#648f00',
        themeDarker: '#4a6a00',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralSecondaryAlt: '#8a8886',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
      }})

export default {
    appTheme
}