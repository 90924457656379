import React, { Component } from 'react';
//Material UI
import { Grid } from "@mui/material";
//Components
import { Store } from './components';
import { apiRequest } from './apiRequest';
import { Constants } from 'stores';
//Models
import {FilterModel} from 'components/Models';

class Orders extends Component {

    state = {
        store:[],
        filters:[new FilterModel('id','Azonosító','','text'),
        new FilterModel('name','Megnevezés','','text'),
        new FilterModel('group','Csoport','','text'),
        new FilterModel('statuses','Státusz',[],'select',[],true),
        new FilterModel('others','Egyéb',[],'select',[{key:'isReportRepair',text:'Javítandó'},{key:'isReportDeficit',text:'Hiány jelentve'}],false)],
        loading:true,
        statuses:[] 
    }

    componentDidMount() {
        apiRequest.populateOrders(this,{});
        apiRequest.populateStatuses(this);
        this.setUpSingalR();
    }

    componentDidUpdate(prevProps,prevState){
        const { filters, statuses } = this.state;

        if(prevState.statuses!=statuses){
            this.setFilters([new FilterModel('id','Azonosító','','text'),
            new FilterModel('name','Megnevezés','','text'),
            new FilterModel('group','Csoport','','text'),
            new FilterModel('statuses','Státusz',[],'select',statuses,false),
            new FilterModel('others','Egyéb',[],'select',[{key:'isReportRepair',text:'Javítandó'},{key:'isReportDeficit',text:'Hiány jelentve'}],false)])
        }
    }

    componentWillUnmount(){
        const connections = Constants.getSignalRValue();
        
        connections.office.off('ActionAtWarehouse');
        connections.office.off('ActionAtFinance');
    }

    setUpSingalR = () =>{
        const connections = Constants.getSignalRValue();
        connections.office.on('ActionAtWarehouse', (users) => {
            this._refresh();
        });
        connections.office.on('ActionAtFinance', (users) => {
            this._refresh();
        });
      }

    /*==== Setter methods ====*/

        setStore = (store) =>{
            this.setState({
                store
            })
        }

        setFilters = (filters) =>{
            this.setState({
                filters
            });
        }

        setLoading = (loading) =>{
            this.setState({
              loading
            });
        }
    /*=========================*/

    /*==== Helper methods ====*/
     _refresh=()=>{
        const {filters} = this.state;
    
        //Set filter model
        let model;
        filters.map(item=>{ model = {...model,[item.name]:item.value}});
    
        this.setLoading(true);  
        apiRequest.populateOrders(this,model).then(res =>       
          this.setLoading(false)
        )
      }
    /*=========================*/
    

    render() {
        const { store, loading, filters } = this.state;
        const { snackbar } = this.props;
        return (
            <Grid spacing={2} container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Store 
                        onRefreshStore={this._refresh}
                        snackbar={snackbar}
                        loading={loading}
                        filters={filters}
                        setFilters={this.setFilters}
                        setItems={this.setStore}
                        items={store} />
                </Grid>
            </Grid>
        )
    }
}
export default Orders;