import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
//Components
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import { Roles } from 'enums';


import {
    //Workpages
    Workpages as WorkpagesView, 
    //Groups
    SetUpGroup as SetUpGroupView,
    //Tracking
    Tracking as TrackingView,  
    //Finance
    FinanceFeedback as FinanceFeedbackView, 
    //Review
    Review as ReviewView, 
    //Workstations
    Workstations as WorkstationsView,
    //Documents
    Documents as DocumentsView, 
    //WareHouse 
    Feedback as FeedbackView,
    FromGroup as FromGroupView,
    WarehouseTracking as WarehouseTrackingView,
    //Other views
    Home as HomeView,
    SignIn as SignInView,
    NotFound as NotFoundView,
    Unauthorized as UnauthorizedView
} from './views';

function getRotePage(loggedUser) {
    if(loggedUser==null || loggedUser==undefined || loggedUser==''){
        return "/sign-in";
    }else if (loggedUser.roles.includes(Roles.IRODA)) {
        return "/office/home";
    } else if (loggedUser.roles.includes(Roles.RAKTAR)) {
        return "/warehouse/setup/from-group";
    }else{
        return "/sign-in";
    }
}

const Routes = (props) => {
    return (
        <Switch>
            <Redirect
                exact
                from="/"
                to={getRotePage(props.loggedUser)}
            />

            <RouteWithLayout
                component={WorkpagesView}
                exact
                layout={MainLayout}
                path="/work-pages/set-up"
                authorize={true}
                roles={[Roles.IRODA]}
            />

            <RouteWithLayout
                component={WorkstationsView}
                exact
                layout={MainLayout}
                path="/workstations/notes"
                authorize={true}
                roles={[Roles.IRODA]}
            />

            <RouteWithLayout
                component={SetUpGroupView}
                exact
                layout={MainLayout}
                path="/production/set-up"
                authorize={true}
                roles={[Roles.IRODA]}
            />

            <RouteWithLayout
                component={TrackingView}
                exact
                layout={MainLayout}
                path="/tracking"
                authorize={true}
                roles={[Roles.IRODA]}
            />      
            <RouteWithLayout
                component={ReviewView}
                exact
                layout={MainLayout}
                path="/review"
                authorize={true}
                roles={[Roles.IRODA]}
            />
              <RouteWithLayout
                component={DocumentsView}
                exact
                layout={MainLayout}
                path="/documents"
                authorize={true}
                roles={[Roles.IRODA]}
            />

            <RouteWithLayout
                component={FinanceFeedbackView}
                exact
                layout={MainLayout}
                path="/finance/feedback"
                authorize={true}
                roles={[Roles.IRODA]}
            />
            <RouteWithLayout
                component={FromGroupView}
                exact
                layout={MainLayout}
                path="/warehouse/setup/from-group"
                authorize={true}
                roles={[Roles.RAKTAR]}
            />
            <RouteWithLayout
                component={FeedbackView}
                exact
                layout={MainLayout}
                path="/warehouse/feedback"
                authorize={true}
                roles={[Roles.RAKTAR]}
            />
            <RouteWithLayout
                component={WarehouseTrackingView}
                exact
                layout={MainLayout}
                path="/warehouse/tracking"
                authorize={true}
                roles={[Roles.RAKTAR]}
            />
            
            <RouteWithLayout
                component={HomeView}
                exact
                layout={MainLayout}
                path="/office/home"
                roles={[Roles.IRODA]}
                authorize={true}
            />
            <RouteWithLayout
                component={SignInView}
                layout={MinimalLayout}
                path="/sign-in"
                authorize={false}
            />
            <RouteWithLayout
                component={NotFoundView}
                exact
                layout={MinimalLayout}
                path="/not-found"
                authorize={false}
            />
            <RouteWithLayout
                component={UnauthorizedView}
                exact
                layout={MinimalLayout}
                path="/unauthorized"
                authorize={false}
            />
            <Redirect to="/not-found" />
        </Switch>
    );
};

export default Routes;
