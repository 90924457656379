import {Api,handleResponse,handleError} from 'api';
import { NotificationBar } from 'components';
//Actions
const Actions = {
    GetGroups: 'group/store',
    DeleteGroups:'group/delete'
}

export const apiRequest = {
    populateGroups,    
    deleteGroups
};

async function populateGroups(parent,model) {
    await new Api().call.post(Actions.GetGroups,model).then(handleResponse)
        .then((value) => {
            parent.setState({
                items:value
            });
        }).catch(handleError);

}


async function deleteGroups(parent,model) {
    await new Api().call.post(Actions.DeleteGroups,model).then(handleResponse)
        .then((value) => {
            if(value!=null && value!==undefined){
                NotificationBar(value.message, 'top', 'right', 3000, 'success', null);   
            }
        }).catch(handleError);

}

