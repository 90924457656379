import { NotificationBar } from 'components';
import { Types } from 'enums';
import {Api,handleResponse,handleError} from 'api';
const Actions = {
    GetType: 'metal/mMNumbers/type',//+{mmId}
    GetComments: 'metal/mMNumbers/addsOn/get',//+{mmId}
    SaveComment: 'metal/mMNumbers/addsOn/add',
    EditComment:'metal/mMnumbers/addsOn/edit',
    SaveQuantities: 'metal/mMNumbers/type/save',
    GetOthers: 'metal/schedule/order/other',//+{groupId}/{selected}
    SaveOthers: 'metal/schedule/order/other/save',

}

export const apiRequest = {
    populateOther,
    populateComments,
    populateType,
    saveComments,
    editComments,
    saveQuantities,
    saveOther
};

async function populateOther(parent) {

    const { opened, group } = parent.props;
    await new Api().call.get(`${Actions.GetOthers}/${group.id}/${opened.id}`).then(handleResponse)
        .then((value) => {
            parent.setState(prevState => ({
                other: value,
                loading: {
                    ...prevState.loading,
                    other: false,
                }
            }));
        }).catch(handleError);

}

async function populateComments(parent) {

    const { selected } = parent.props;
    await new Api().call.get(`${Actions.GetComments}/${selected}`).then(handleResponse)
        .then((value) => {
            parent.setState(prevState => ({
                comments: value,
                loading: {
                    ...prevState.loading,
                    comment: false,
                }
            }));
        }).catch(handleError);

}

async function populateType(parent) {
    parent.setState(prevState => ({
        types: [],
        loading: {
            ...prevState.loading,
            type: true,
        },
        wingQuantity: 0,
        caseQuantity: 0,
        selectedType: -1,
        modQuantities: false
    }));
    const { selected } = parent.props;
    await new Api().call.get(`${Actions.GetType}/${selected}`).then(handleResponse)
        .then((value) => {
            parent.setState(prevState => ({
                types: value.type,
                selectedType: value.selectedType,
                wingQuantity: value.wingQuantity,
                caseQuantity: value.caseQuantity,
                loading: {
                    ...prevState.loading,
                    type: false,
                }
            }));
        }).catch(handleError);

}

async function saveComments(parent) {
    const { selected } = parent.props;
    const { comment } = parent.state;
    const model = { author: 'dfdfdf', content: comment, orderId: selected };
    await new Api().call.post(Actions.SaveComment, model).then(handleResponse)
        .then((value) => {
            parent.setState({
                comment: ''
            });
        }).catch(handleError);
}

async function editComments(parent,model,newArray) {
    await new Api().call.post(Actions.EditComment, model).then(handleResponse)
        .then((value) => {
            parent.setState({
                comments: newArray
            });
        }).catch(handleError);
}

async function saveQuantities(parent) {
    const { selected } = parent.props;
    const { wingQuantity, caseQuantity, selectedType } = parent.state;
    const _wingQuantity = selectedType.key === Types.SOROLO ? 0 : wingQuantity * 1;
    const _caseQuantity = selectedType.key === Types.SOROLO ? 0 : caseQuantity * 1;
    const model = { wingQuantity: _wingQuantity, caseQuantity: _caseQuantity, orderId: selected, typeId: selectedType.key };
    await new Api().call.post(Actions.SaveQuantities, model).then(handleResponse)
        .then((value) => {
            parent.setState({
                modQuantities: false
            });
            NotificationBar('A mentés megtörtént', 'top', 'right', 3000, 'success', null);
        }).catch((error) => {
            handleError(error);
            parent.PopulateTypeAsync();
        });
}

async function saveOther(parent) {
    const { opened, group } = parent.props;
    const { other } = parent.state;
    const model = { needStatement: other.needStatement, selectedPrioritas: other.selectedPrioritas.key, groupId: group.id, orderId: opened.id };
    await new Api().call.post(Actions.SaveOthers, model).then(handleResponse)
        .then((value) => {
            parent.setState({
                modOther: false
            });
            NotificationBar('A mentés megtörtént', 'top', 'right', 3000, 'success', null);
        }).catch((error) => {
            handleError(error);
            parent.PopulateOtherAsync();
        });
}