import {Api,handleResponse,handleError} from 'api';
//Actions
const Actions = {
    GetFiles:  'createGroup/store',
    GetPriorities: 'createGroup/priorities'
}


export const apiRequest = {
    populateFiles,
    populatePriorities
};

async function populateFiles(parent,model) {
    const {editable} = parent.props;
    await new Api().call.post(Actions.GetFiles,model).then(handleResponse)
        .then((value) => {
            let temp = [...editable.pages];
            temp.push(...value);
            parent.setState({
                items:temp,
            });
        }).catch(handleError);

}

async function populatePriorities(parent) {
    await new Api().call.get(Actions.GetPriorities).then(handleResponse)
        .then((value) => {
            parent.setState({
                priorities: value
            });
        }).catch(handleError);

}
