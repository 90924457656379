import React from 'react';
//Material UI
import { 
    Tooltip,
    CircularProgress,
    Popper,
    Fade,
    Dialog,
    Button, 
    IconButton, 
    Typography, 
    Table, 
    TableHead, 
    TableRow, 
    TableCell, 
    TableBody ,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';
//Icons
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


export default function UploadDialog(props) {

    const { open, data, handleClose } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [poperOpen, setPoperOpen] = React.useState(false);
    const [errorIndex,setErrorIndex] = React.useState(null);

    const handleClick = (event,index) => {
        setAnchorEl(event.currentTarget);
        setPoperOpen(!poperOpen);
        setErrorIndex(index);
    };

    const handleClickAway = () => {
        setPoperOpen(false);
        setErrorIndex(null);
    }

    const onClose = () => {
        setAnchorEl(null);
        setPoperOpen(false);
        setErrorIndex(null);
        handleClose();
    }
    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth='sm'
        >
            <DialogTitle>                 
                <Typography style={{color:'white',fontWeight:'600'}}>File-ok feltöltése</Typography>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>  
            </DialogTitle>
            <DialogContent dividers>
                <div style={{ textAlign: 'center' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox" align="center">
                                    Megnevezés
                                </TableCell>
                                <TableCell padding="checkbox" align="center">
                                    Állapot
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.length>0 && data.map((row,index) => (
                                <TableRow key={row.name}>
                                    <TableCell align="center" padding="checkbox" >
                                        {row.name}
                                    </TableCell>
                                    <TableCell  align="center" padding="checkbox" >
                                        {row.success === null && <CircularProgress size={30} color="primary" />}
                                        {(row.success === true && row.warningMessage==null) && 
                                        <Tooltip title="Sikeres feltöltés!">
                                            <div> 
                                                <IconButton size="small" color="primary">
                                                    <CheckCircleIcon />
                                                </IconButton>
                                            </div>
                                        </Tooltip>}
                                        {(row.success === true && row.warningMessage!=null) && 
                                        <Tooltip title="Sikeres feltöltés!">
                                            <div> 
                                                <IconButton size="small" color="warning" onClick={(event)=>handleClick(event,index)}>
                                                    <WarningIcon />
                                                </IconButton>
                                            </div>
                                        </Tooltip>}
                                        {row.success === false && 
                                        <Tooltip title="Sikertelen feltöltés, a hibaüzenet megtekintéséhez kattints ide!">
                                            <div> 
                                                <IconButton size="small" color="error" onClick={(event)=>handleClick(event,index)}>
                                                    <ErrorIcon />
                                                </IconButton>
                                            </div>
                                        </Tooltip>  }
                                    </TableCell>
                                </TableRow>
                            ))}
                           
                        </TableBody>
                    </Table>                   
                </div>
            </DialogContent>
            <DialogActions>
                <Button 
                    disabled={!(data.filter(row => row.success!==null).length==data.length)}
                    size="small"
                    variant="contained" 
                    color="primary"
                    onClick={onClose} 
                >
                    Ok
                </Button>
            </DialogActions>
            <Popper 
                style={{zIndex:1400}}  
                open={poperOpen} 
                anchorEl={anchorEl} 
                placement='bottom' 
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transition
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <div style={{backgroundColor:'white',boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'}}>
                            <div style={{display:'flex',padding:'0rem 0.5rem', justifyContent:'space-between', alignItems:'center',backgroundColor:'#84BD00',color:'white',boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'}}>
                                <Typography style={{color:'white',fontSize:'0.8rem'}}>
                                    Hibaüzenet
                                </Typography>
                                <IconButton style={{color:'white'}} size="small" onClick={handleClickAway}>
                                    <CloseIcon style={{fontSize:'1rem'}} size="small" /> 
                                </IconButton>
                             </div>
                             <div style={{padding:'0.5rem'}}>
                                {(errorIndex!=null && data[errorIndex].success!=true) && 
                                <Typography style={{fontSize:'1rem'}}>
                                    {data[errorIndex].progressMessage}
                                </Typography>}
                                {errorIndex!=null && 
                                <Typography style={{fontSize:'1rem'}}>
                                    {data[errorIndex].warningMessage}
                                </Typography>}
                             </div>   
                        </div>                      
                    </Fade>
                )}
            </Popper>
        </Dialog>
    );
}
