import React, { Component } from 'react';
//Material UI
import { 
    Grid,  
    CircularProgress,
    Backdrop
} from  "@mui/material";
//Components
import {
    Store,
    OpenDialog 
} from './components';
//Models
import {FilterModel} from 'components/Models';
//Api
import { apiRequest } from './apiRequest';


//MaterialUI

class MetalFromGroup extends Component {

   state = {
      store: [],
      filters:[new FilterModel('id','Azonosító','','text'),
        new FilterModel('group','Csoport','','text'),
        new FilterModel('statuses','Státusz',[],'select',[],true)],
      loading: true,
      statuses:[],
      request:false,
      viewDialog: null,
    }
   

    componentDidMount() {
        apiRequest.populateStore(this,{});
        apiRequest.populateStatuses(this);
    }

    componentDidUpdate(prevProps,prevState){
        const { filters, statuses } = this.state;
    
        if(prevState.statuses!=statuses){
            this.setFilters([new FilterModel('id','Azonosító','','text'),
            new FilterModel('group','Csoport','','text'),
            new FilterModel('statuses','Státusz',[],'select',statuses,false)])
        }
    }

    /*==== Setter methods ====*/

        setStore = (store) =>{
            this.setState({
                store
            })
        }

        setFilters = (filters) =>{
            this.setState({
            filters
            });
        }

        setRequest = (value) =>{
            this.setState({
                request:value
            });
        }
   /*========================*/

 
   /*==== Handle user interactions ====*/
        handleClickOpen = (item) => {
            this.setState({
                viewDialog:item
            });
        }
        
        handleClose = () => {
            this.setState({
                viewDialog:null
            });
        }
    /*===================================*/

    /*==== Helper methods ====*/
        initStates = () =>{
            this.setState({
                loading: true,
                viewDialog:null
            });    
        }

        refreshStore = () => {
            const {filters} = this.state;
        
            //Set filter model
            let model;
            filters.map(item=>{ model = {...model,[item.name]:item.value}});

            this.initStates();
            apiRequest.populateStore(this,model);
        }
    /*=========================*/

    render() {
        const { request, loading, store, viewDialog, filters } = this.state;
        return (
                <div style={{padding:'2rem'}}>
                    <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Store
                                setFilters={this.setFilters}
                                filters={filters}
                                setRequest={this.setRequest}
                                onRefreshStore={this.refreshStore} 
                                onView={this.handleClickOpen} 
                                loading={loading} 
                                items={store} 
                                setItems={this.setStore}
                            />
                        </Grid>
                        {viewDialog != null && 
                            <OpenDialog
                            opened={viewDialog} 
                            open={true} 
                            handleClose={this.handleClose} />}
                        <Backdrop style={{ zIndex: 1200 + 1,color: '#fff'}} open={request}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </Grid>
                </div>
            )
    }

   
}
export default MetalFromGroup;