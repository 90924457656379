import React from 'react';
import PropTypes from 'prop-types';
//Material UI
import {
    AppBar,
    Toolbar,
} from '@mui/material';


const Topbar = props => {
  const { className, ...rest } = props;

  return (
    <AppBar
      {...rest}
      style={{ boxShadow: 'none', backgroundColor:'#84BD00' }}
      color="primary"
      position="fixed"
    >
      <Toolbar>
        <img
          alt="Logo"
          src="/images/logos/logo--app--white.png"
          height="45"
          width="50"
        />
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
