import React, { Component } from 'react';
//Material UI
import {
    Divider,
    Typography,
    Grid,
    CircularProgress,
    Button
} from '@mui/material';
//Fluent UI
import { TextField } from '@fluentui/react/lib/TextField';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { Label } from '@fluentui/react';
//Components
import { Types } from 'enumsFF';
//Api
import {apiRequest} from './apiRequest';



class AddsOn extends Component {


    state = {
        loading: {
            type: true,
            comment: true,
        },
        comment: '',
        comments: [],
        types: [],
        wingQuantity: 0,
        caseQuantity: 0,
        selectedType: -1,
    }


    componentDidMount() {
        apiRequest.populateComments(this);
        apiRequest.populateType(this);
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.selected !== prevProps.selected) {
            apiRequest.populateComments(this);
            apiRequest.populateType(this);
        }
        if (this.state.comment != prevState.comment && (this.state.comment === "" || this.state.comment === null)) {
            apiRequest.populateComments(this);
        }

    }

    commentHandleChange = (event) => {
        const value = event.target.value;
        this.setState({
            comment: value
        });
    }

    saveCommentClickHandler = () => {
        apiRequest.saveComments(this);
    }

    changeHandler = (event, props) => {
        const value = event.target.value;
        this.setState({
            [props]: value
        });
    }

    typeChangeHandler = (event) => {
        const value = event.target.value;
        this.setState({
            selectedType: value
        });
    }

    handleCommentChange = (index,value) =>{
        const {comments} = this.state;
        const temp = comments;
        temp[index].content = value;
        this.setState({
            comments:temp
        });
    }

    handleCancelModify = (index) =>{
        const {comments} = this.state;
        const temp = comments;
        temp[index].content = temp[index].originalContent;
        this.setState({
            comments:temp
        });
    }

    handleSaveModifiedComment = (index) =>{
        const {comments} = this.state;
        const temp = comments;
        temp[index].originalContent = temp[index].content;
        const model={id: temp[index].id,content:temp[index].content}

        apiRequest.editComments(this,model,temp);
    }

    render() {
        const { comment, comments, types, selectedType, wingQuantity, caseQuantity, loading } = this.state;
        const { positions, handleChange, selected } = this.props;
        return (
            <div style={{boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',padding:'0.5rem',marginBottom:'1rem'}}>
                 <div style={{display:'flex',flexDirection:'column',gap:'1rem'}}>
                    <div>
                        <Typography style={{ fontFamily: 'Nunito', fontSize: '1.0rem',fontWeight: 'bold'}}>
                            Pozíció
                        </Typography>
                        <Divider  />
                        <Dropdown 
                            style={{marginTop:'0.5rem'}}                  
                            options={positions}
                            selectedKey={selected}
                            onChange={handleChange}
                        />
                        </div>
                        <div>
                            <div style={{display:'flex',alignItems:'center',gap:'0.5rem'}}>
                                <Label>Pozíció típusa</Label> 
                                {loading.type && <CircularProgress size="1rem" />}
                            </div>
                            <Dropdown
                                options={types}
                                disabled={true}
                                selectedKey={selectedType ? selectedType.key : undefined}
                                onChange={this.typeChangeHandler}
                            />
                        </div>  
                    </div>
                    {selectedType.key != Types.SOROLO && 
                    <Grid style={{marginTop: '1rem'}} container spacing={2}>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <TextField 
                                type="number" 
                                onChange={(event) => this.changeHandler(event, 'caseQuantity')} 
                                errorMessage={(!loading.type && caseQuantity <= 0) && "Nem megadható érték"} 
                                suffix={loading.type ? <CircularProgress size="1rem" /> : 'db'} 
                                disabled={true} 
                                value={caseQuantity} 
                                label="Tok" />
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <TextField 
                                type="number" 
                                onChange={(event) => this.changeHandler(event, 'wingQuantity')} 
                                errorMessage={(!loading.type && wingQuantity <= 0) && "Nem megadható érték"} 
                                suffix={loading.type ? <CircularProgress size="1rem" /> : 'db'} 
                                disabled={true} 
                                value={wingQuantity} 
                                label="Szárny" />
                        </Grid>
                    </Grid>}
                    {comments.length > 0 && 
                    <div>
                        <Typography style={{ fontFamily: 'Nunito', fontSize: '1.0rem',fontWeight: 'bold',marginTop:'1rem'}}>
                            Kiegészítések
                        </Typography>
                        <Divider />
                    </div>}
                    {comments.map((comment, index) => 
                    <div key={comment.id} style={{paddingBottom:'1.0rem'}}>
                        <TextField                     
                            id="outlined-multiline-static"
                            label={comment.author+' - '+comment.createdTime}
                            name="comment"
                            multiline
                            InputProps={{
                                readOnly: !comment.ownComment,
                            }}
                            onChange={(event)=>this.handleCommentChange(index,event.target.value)}
                            value={comment.content}
                            rows={3}
                            variant="outlined"
                        />
                        {comment.originalContent!==comment.content &&   
                        <div style={{display:'flex', justifyContent:'flex-end',margin:'0.5rem'}}>
                            <Button 
                                color="error" 
                                variant="contained" 
                                size="small" 
                                onClick={()=>this.handleCancelModify(index)}
                            >
                                Mégsem
                            </Button>
                            <Button 
                                color="primary" 
                                variant="contained" 
                                size="small" 
                                onClick={()=>this.handleSaveModifiedComment(index)}
                            >
                                Mentés
                            </Button>
                        </div>}                  
                    </div>)}
                    <Typography style={{ fontFamily: 'Nunito', fontSize: '1.0rem',fontWeight: 'bold',marginTop:'1rem'}}>
                        Új hozzáadása
                    </Typography>
                    <Divider />
                    <TextField
                        id="tf-comment"
                        label="Megjegyzés"
                        name="comment"
                        multiline
                        value={comment}
                        rows={5}
                        variant="outlined"
                        onChange={(event) => this.changeHandler(event, 'comment')}
                    />
                    <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',padding:'0.5rem'}}>
                        <Button
                            disabled={(comment !== null && comment !== '') ? false : true} 
                            color="primary" 
                            variant="contained" 
                            size="small" 
                            onClick={this.saveCommentClickHandler}
                        >
                            Rögzítés
                        </Button>
                    </div>
            </div>
        )
    }


   

}
export default AddsOn;


