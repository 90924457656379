import {Api,handleResponse,handleError} from 'api';
//Actions
const Actions = {
    GetItems:'curtain-wall/review/review-points',
}


export const apiRequest = {
    getItems,    
};

async function getItems(boxId, orderId) {
    return await new Api().call.get(`${Actions.GetItems}/${boxId}/${orderId}`).then(handleResponse)
        .then((value) => {
            return value
        }).catch(handleError);

}


