import React, { Component } from "react";
//Fluent UI
import { Pivot, PivotItem } from '@fluentui/react';
//Material UI
import { Grid } from "@mui/material";
//Components
import { PageTitle } from 'components/Surfaces';
import { Orders, Groups } from './components';
//Material UI Icons
import ForwardIcon from '@mui/icons-material/Forward';


class MetalTracking extends Component{

    render(){
        return( 
            <div style={{padding:'2rem'}}>
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}> 
                        <Pivot className="pivot-top-selected">
                            <PivotItem headerText="Munkalaponként">
                                <Orders/>
                            </PivotItem>
                            <PivotItem headerText="Csoportonként">
                                <Groups/>
                            </PivotItem>
                        </Pivot>
                    </Grid>
                </Grid>
            </div>)
    }
}

export default MetalTracking;