//React
import React from 'react';
//Fluent UI
import { Text } from "@fluentui/react";
//Material UI
import { 
  IconButton, 
  Tooltip 
} from '@mui/material';
//Material UI Icons
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

export default class TableColumns{

  constructor(props){
    this.props = props;
  }


  getColumns = () =>{
    const {onProblem, request} = this.props;
    return [           
    {
      key: 'column1',
      name: 'Ellenőrzési szempontok',
      fieldName: 'reviewPont',
      minWidth: 300,
      maxWidth: 300,
      isRowHeader: true,
      isResizable: true,
      isPadded: true,
      data: 'string',
      onRender: (item) => (
        <Text>{item.reviewPont}</Text>
      )
    },
    {
      key: 'column2',
      name: 'Megfelelt',
      fieldName: 'isOk',
      minWidth: 300,
      maxWidth: 300,
      isResizable: true,
      isPadded: true,
      data: 'string',
      onRender: (item) => (
        item.isOk && 
        <CheckCircleOutlineIcon color="success"/>
      )
    },
    {
      key: 'column3',
      name: 'Nem felelt meg',
      fieldName: 'fail',
      minWidth: 300,
      maxWidth: 300,
      isResizable: true,
      isPadded: true,
      data: 'string',
      onRender: (item) => (
        !item.isOk && 
        <Tooltip title="Probléma megtekintése">
          <IconButton color="error" disabled={request} onClick={()=>onProblem(item.problemItems)}>
            <CancelIcon />
          </IconButton>
        </Tooltip>
      )
    },
    {
      key: 'column4',
      name: 'Ellenőrizte',
      fieldName: 'reviewer',
      minWidth: 300,
      maxWidth: 300,
      isResizable: true,
      isPadded: true,
      data: 'string',
      onRender: (item) => (<Text>{item.reviewer}</Text>)
    },
    ]
  }
}