import React from 'react';
//Material UI
import { Chip } from  "@mui/material";

export class Status{
    id;
    color;
    label;
    size;
    icon;
    constructor(id,color,label,size,icon){
        this.id=id;
        this.color=color;
        this.label = label;
        this.size = size;
        this.icon = icon;
    }

    getChip(size){
        return <StatusChip color={this.color} label={this.label} size={size || this.size} icon={this.icon} />
    }
    getValueForSelect(){
        return {id:this.id,label:this.label};
    }
}

 function StatusChip(props){
    const { color, label, size, icon } = props;

    return( 
        <Chip
            size={size}
            icon={icon}
            label={label}
            color={color}
        />)
    ;
} 