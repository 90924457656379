import {Api,handleResponse,handleError} from 'api';
//Actions
const Actions = {
    GetNotes:  'gui/notifications',
    HideNote: 'gui/hide-note',
    ConfirmNote:'gui/confirm-note',
}


export const apiRequest = {
    populateNotes,
    hideNotes,
    confirmNotes
};

async function populateNotes(parent) {
    await new Api().call.get(Actions.GetNotes).then(handleResponse)
        .then((value) => {
            parent.setState({
                newNotes:value.newNotes,
                allNotes:value.allNotes,
                notifications:value.notes
            })          
        }).catch(handleError);

}

async function hideNotes(id,parent) {
    await new Api().call.post(`${Actions.HideNote}/${id}`).then(handleResponse)
        .then((value) => {
            populateNotes(parent);           
        }).catch(handleError);

}

async function confirmNotes(id,parent) {
    await new Api().call.post(`${Actions.ConfirmNote}/${id}`).then(handleResponse)
        .then((value) => {
            populateNotes(parent);             
        }).catch(handleError);

}
