import {Api,handleResponse,handleError} from 'api';
//Actions
const Actions = {
    CancelRequest:'curtain-wall/warehouse/shipped-cancel'
}

export const apiRequest = {
    cancel
};

async function cancel(model) {
    await new Api().call.post(Actions.CancelRequest,model).then(handleResponse)
        .then((value) => {
        }).catch(handleError);
}
