import {Api,handleResponse,handleError} from 'api';
const Actions = {
    OpenPdfWithId: 'workstations/pdf', //+{Id}
}

export const apiRequest = {
    populatePdf,
};

async function populatePdf(parent) {
    const { workpageId } = parent.props;
    await new Api().call.get(`${Actions.OpenPdfWithId}/${workpageId}`).then(handleResponse)
    .then((value) => {
        //Create arraybuffer
        if (value.file != "") {
            parent.initPdf(value)
        }
    }).catch(handleError);
}
