import {Api,handleResponse,handleError} from 'api';
import { NotificationBar } from 'components';
//Actions
const Actions = {
    SaveGroup: 'metal/group/edit',
}


export const apiRequest = {
    saveGroup
};

async function saveGroup(model) {

    await new Api().call.post(Actions.SaveGroup, model).then(handleResponse)
        .then((value) => {
            NotificationBar('A módosítás megtörtént', 'top', 'right', 3000, 'success', null);
        }).catch((error) => {
            console.log(error);
            if(error.response!=null && error.response.data!=null && error.response.data!=null && error.response.data.message!=null && error.response.data.message!==undefined){
                NotificationBar(error.response.data.message, 'top', 'right', 3000, error.response.data.type, null);
            }else{
                NotificationBar('Hiba történt a módosítás során', 'top', 'right', 3000, 'error', null);
            }      
            throw Error(error);
        });

}
