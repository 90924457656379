//React
import React from 'react';
//Fluent UI
import { 
  Text,
  Dropdown
} from "@fluentui/react";
//Material UI
import { 
    Checkbox,
    Button,
    IconButton
} from "@mui/material";
//Material UI Icons
import PreviewIcon from '@mui/icons-material/Preview';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
 
 /*====Render container with buttons====*/
  const ActionContainer = (props) =>{
    const {onOpenDialog,item} = props;

  return <div style={{display:'flex',alignItems:'center',gap:'0.5rem'}}>
  <Button onClick={()=>onOpenDialog("open",item)} style={{fontSize:'0.75rem'}} startIcon={<PreviewIcon fontSize='small'/>}  size='small' variant='contained' color="primary">
      Megtekintés
  </Button>
  <Button onClick={()=>onOpenDialog("edit",item)} style={{fontSize:'0.75rem'}} startIcon={<EditIcon fontSize='small'/>} size='small' variant='contained' color="warning">
      Kiegészítés
  </Button>
  </div>
  }
/*=====================================*/

export default class TableColumns{

  constructor(props){
    this.props = props;
  }


  getColumns = () =>{
    const {onStatementChange,onOpenDialog,onDelete,priorities,onPriorityChange} = this.props;
    return [
      {
        key: 'column0',
        name: 'Delete',
        fieldName: 'delete',
        minWidth: 50,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        isIconOnly:true,
        onRender: (item) => (<IconButton onClick={()=>onDelete(item)}><DeleteIcon color='error'/></IconButton>)
      },     
      {
      key: 'column1',
      name: '#',
      fieldName: 'id',
      minWidth: 20,
      maxWidth: 20,
      isRowHeader: true,
      isResizable: true,
      data: 'number',
      isPadded: true,
      onRender: (item) => (<Text>{item.id}</Text>)
    },             
    {
      key: 'column2',
      name: 'Megnevezés',
      fieldName: 'name',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (<Text>{item.name}</Text>)
    },
    {
      key: 'column3',
      name: 'Feltöltve',
      fieldName: 'uploadedTime',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (<Text>{item.uploadedTime}</Text>)
    },
    {
      key: 'column4',
      name: 'Műveletek',
      fieldName: 'actions',
      minWidth: 300,
      maxWidth: 300,
      isResizable: true,
      onRender: (item) => (<ActionContainer onOpenDialog={onOpenDialog} item={item}/>)
    },
    {
      key: 'column5',
      name: 'Prioritás',
      fieldName: 'priority',
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => ( <Dropdown
        selectedKey={item.priorityId}
        onChange={(event,option)=>onPriorityChange(event,option,item)}
        options={priorities}
      />)
    },
    {
      key: 'column6',
      name: 'Nyilatkozat',
      fieldName: 'needStatement',
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (<Checkbox icon={<RadioButtonUncheckedIcon/>} checkedIcon={<RadioButtonCheckedIcon/>} color="primary" checked={item.needStatement} onChange={(event,checked)=>onStatementChange(item,checked)}/>)
    },
    ]
  }
}