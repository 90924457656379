import React from 'react';
//Material UI
import { 
    Dialog,
    Button, 
    IconButton, 
    Typography, 
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    CircularProgress 
} from '@mui/material';
//Material UI Icons
import CloseIcon from '@mui/icons-material/Close';
//Api
import { apiRequest } from './apiRequest';

export default function AlertDialog(props) {

    const { open, onClose, initAfterSave, saveObjects,editable } = props;

    const [request,setRequest] = React.useState(false);

  
    /*==== Handle events ====*/
        const handleSave = () =>{
            setRequest(true);

            const model = {id:editable.id,saveObjects};

            apiRequest.saveGroup(model).then(res=>{
                initAfterSave();
                onClose();
            }).catch(error=>{
                setRequest(false);
            })
        }
    /*========================*/

    return (
            <Dialog onClose={onClose} open={open}>
                <DialogTitle>                 
                    <Typography style={{color:'white',fontWeight:'600'}}>Megerősítés</Typography>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>  
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        Biztos módosítani szeretnéd ?
                    </DialogContentText>               
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={request}
                        color='error' 
                        size="small" 
                        variant="contained" 
                        onClick={onClose} 
                    >
                        Nem
                    </Button>
                    <Button
                        disabled={request}
                        color='primary' 
                        size="small" 
                        variant="contained" 
                        onClick={handleSave} 
                    >
                       {request ? <span style={{display:'flex',alignItems:'center',gap:'0.5rem'}}>Módosítás <CircularProgress size="1rem"/></span>: 'Igen'}
                    </Button>
                </DialogActions>
            </Dialog>
    );
}
