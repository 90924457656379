//React
import React from 'react';
//Fluent UI
import { Text } from "@fluentui/react";
//Material UI
import {
  Button, 
  IconButton 
} from "@mui/material";
//Enums
import {Statuses} from 'enums';
import { Status } from 'components';
//Material UI Icons
import DoneIcon from '@mui/icons-material/Done';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const statuses =[
  new Status(Statuses.OsszekeszitesreAtadva,'warning','Összekészíthető','small',<InfoIcon/>),
  new Status(Statuses.Osszekeszitve,'success','Összekészítve','small',<MoveToInboxIcon/>),
  new Status(Statuses.GyartasbaElkuldve,'info','Gyártásban','small',<DoneIcon/>)
]

 
 /*==== Render status chip ====*/
  const StatusChip = (props) =>{
    const {item} = props;

    switch(item.statuszId){
      case Statuses.OsszekeszitesreAtadva:
        return statuses.find(item=>item.id==Statuses.OsszekeszitesreAtadva).getChip();
      case Statuses.Osszekeszitve:
        return statuses.find(item=>item.id==Statuses.Osszekeszitve).getChip();
      case Statuses.GyartasbaElkuldve:
        return statuses.find(item=>item.id==Statuses.GyartasbaElkuldve).getChip();
    }
    return '';
  }
/*=====================================*/


/*==== Render action container ====*/
const ActionContainer = (props) =>{
  const { item, onAction, loading } = props;
  return (
    <div style={{dsiplay:'flex',margin:'auto',alignContent:'center'}}>
      {item.statuszId==Statuses.OsszekeszitesreAtadva &&
      <Button 
        onClick={()=>onAction(item.id,item.statuszId)} 
        size="small" 
        disabled={loading}
        style={{fontSize:'0.75rem'}}
        variant="contained" 
        color="info"
      >
        Összekészítve
      </Button>}
      {item.statuszId==Statuses.Osszekeszitve &&
      <Button 
        onClick={()=>onAction(item.id,item.statuszId)} 
        size="small" 
        disabled={loading}
        style={{fontSize:'0.75rem'}}
        variant="contained" 
        color="primary"
      >
        Gyártásba adva
      </Button>}
    </div>
  )
}
/*=================================*/



export default class TableColumns{

  constructor(props){
    this.props = props;
  }


  getColumns = () =>{
    const { onOpen, onWorkpages, onAction, loading } = this.props;
    return [
      {
      key: 'column1',
      name: '#',
      fieldName: 'id',
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'number',
      onRender: (item) => (<Text>{item.id}</Text>)
    },             
    {
      key: 'column2',
      name: 'Csoport',
      fieldName: 'csoportMegnevezes',
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      isSortable:true,
      isPadded: true,
      data: 'string',
      onRender: (item) => (<Text>{item.csoportMegnevezes}</Text>)
    },
    {
      key: 'column3',
      name: 'Munkalapok',
      fieldName: 'munkalapokSzama',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (<div style={{display:'flex', alignItems:'center',gap:'0.5rem'}}>
        <Text>{item.munkalapokSzama} db</Text>
        <IconButton size='small' color='info' onClick={(event)=>onWorkpages({item,target:event.currentTarget})}>
          <MoreVertIcon />
        </IconButton>
        </div>)
    },
    {
      key: 'column4',
      name: 'Státusz',
      fieldName: 'status',
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: (item) => (<StatusChip item={item}/>)
    },
    {
      key: 'column5',
      name: 'Műveletek',
      fieldName: 'actions',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      data: 'string',
      isPadded: true, 
      onRender: (item) => (<ActionContainer loading={loading} item={item} onAction={onAction}/>)
    },
    {
      key: 'column6',
      name: 'Módosítva',
      fieldName: 'mappedModositva',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isPadded: true,
      isSortable:true,
      data: 'string',
      onRender: (item) => (<Text>{item.mappedModositva}</Text>)
    },
    {
      key: 'column7',
      name: 'Létrehozva',
      fieldName: 'mappedLetrehozva',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isPadded: true,
      isSortable:true,  
      data: 'string',
      onRender: (item) => (<Text>{item.mappedLetrehozva}</Text>)
    }
    ]
  }
}