import React, {
    useState,
    useEffect
} from 'react';
import {
    Link as RouterLink,
    withRouter
} from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
//Services
import { authenticationService } from 'services/authentication';
//Material UI
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  InputAdornment, 
  CircularProgress,
  FormHelperText 
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
//Style
import './style.css';

const schema = {
    userName: {
    presence: { allowEmpty: false, message: 'kötelező' },
    length: {
      maximum: 64
    }
  },
  password: {
      presence: { allowEmpty: false, message: 'kötelező' },
    length: {
      maximum: 128
    }
  }
};



const SignIn = props => {
    const { history } = props;

    const [loginInProgress, setLoginInProgress] = React.useState(false);
    const [showPassword,setShowPassword] = React.useState(false);

    const [formState, setFormState] = useState({
      isValid: false,
      values: {},
      touched: {},
      errors: {}
    });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  async function handleSignIn(event){
      setLoginInProgress(true);
      event.preventDefault();
      try {
          const response = await authenticationService.login(formState.values.userName, formState.values.password);
      }
      catch (error) {
          setLoginInProgress(false);
      }
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  function handleShowPassword(){
      setShowPassword(!showPassword);
  }

  const handleMouseDownPassword = (event) => {
      event.preventDefault();
  };

  return (
    <div className="login-root">                           
    <div className="login-container">             
        <form onSubmit={handleSignIn}  className="login-form">       
            <div className="login-form-logos-comtainer">
                <img 
                    alt="Knab panzio_logo" 
                    src="/images/logos/logo--app.png"/>
            </div>
            <p className="login-form-company">Abex <span>TTR</span></p>
            <p className="login-form-title">Bejelentkezés</p>
            <FormControl  className="login-form-inputs" size="small" variant="outlined">
                <InputLabel htmlFor="tf_userName">Felhasználónév</InputLabel>
                <OutlinedInput
                    className="login-form-inputs-bg"
                    id="tf_userName"               
                    name="userName"
                    type="text"
                    error={hasError('userName')}
                    value={formState.values.userName || ''}
                    onChange={handleChange}
                    label="Felhasználónév"
                    style={{borderRadius:'0px'}}
                />
                   {hasError('userName') && 
                   <FormHelperText error>
                      Felhasználónév megadása kötelező
                  </FormHelperText>}
            </FormControl>
            <FormControl  
                className="login-form-inputs" 
                size="small" 
                variant="outlined"
            >
                <InputLabel htmlFor="tf_password">Jelszó</InputLabel>
                <OutlinedInput
                    className="login-form-inputs-bg"
                    id="tf_password"
                    name="password"     
                    error={hasError('password')}       
                    type={showPassword ? 'text' : 'password'}
                    value={formState.values.password || ''}
                    onChange={handleChange}
                    style={{borderRadius:'0px'}}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        color="secondary"
                        aria-label="Mutasd a jelszót"
                        onClick={handleShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                        </IconButton>
                    </InputAdornment>
                    }
                    label="Jelszó"
                />
                {hasError('password') && 
                <FormHelperText error>
                    Jelszó megadása kötelező
                </FormHelperText>}
            </FormControl>
            <Button
                aria-label="Bejelentkezés" 
                variant="contained" 
                disabled={!formState.isValid || loginInProgress}
                type="submit"
            >
                {loginInProgress  ? 
                    <div className="login-loading">
                        <span>Bejelentkezés</span>
                        <CircularProgress size="1rem"/>
                    </div> : 
                    'Bejelentkezés'
                }
            </Button>
        </form>
        <img 
            alt="login_bg" 
            className="login-container-img" 
            src="/images/auth.png"/>
    </div>
</div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);
