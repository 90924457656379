import React, { Component } from 'react';
//Material UI
import { Button} from "@mui/material";
//Material UI Icons
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
//Components
import { DataTable } from 'components/Lists';
//Table columns
import TableColumns from './table_data';
import { AlertDialog } from './components';



class Added extends Component {


    state = {
        filters:[],
        columns:[],
        open:false,
    }

    componentDidMount() {
        const {priorities,onDialog} = this.props;
        this.setColumns(new TableColumns({priorities,onDelete:this.handleDeleteElement,onPriorityChange:this.handlePriorityChange,onStatementChange:this.handleStatementChange,onOpenDialog:onDialog}).getColumns());
    }

    componentDidUpdate(prevProps,prevState){
        const {open} = this.state;
        const {items,setPivotKey,onDialog,priorities} = this.props;

        if(prevProps.items!==items){
            if(items.length<1) setPivotKey('Választható munkalapok');
        }

        if(prevProps.priorities!==priorities){
            this.setColumns(new TableColumns({priorities,onDelete:this.handleDeleteElement,onPriorityChange:this.handlePriorityChange,onStatementChange:this.handleStatementChange,onOpenDialog:onDialog}).getColumns());
        }
    }

    /*==== Setter methods ====*/  
        setColumns = (columns) =>{
            this.setState({
            columns
            });
        } 

        setShowSelect = (showSelect) =>{
            this.setState({
            showSelect
            });
        }

        setOpen = (open) =>{
            this.setState({
                open
            });
        }
    /*=========================*/

    /*==== Handle user interactions ====*/
        handleDeleteElement = (item) =>{
            const {setAdded,setSelected,selected,items} = this.props;

            var _items = [...items].filter(element=>element.id!==item.id);
            var _selected = [...selected].filter(element=>element.id!==item.id);

            setAdded(_items);
            setSelected(_selected);
        }

        handleStatementChange = (item,checked) =>{
            const {setAdded,items} = this.props;

            var _items = [...items];
            var index = _items.indexOf(item);
            _items[index] = { ...item };
            _items[index].needStatement = checked;

            setAdded(_items);
        }

        handlePriorityChange = (event,option,item) =>{
            const {setAdded,items} = this.props;

            var _items = [...items];
            var index = _items.indexOf(item);
            _items[index] = { ...item };
            _items[index].priorityId = option.key;

            setAdded(_items);
        }

        handleOpen = () => {
            this.setOpen(true)
        }

        handleClose = () =>{
            this.setOpen(false)
        }
    /*===================================*/

    render() {
        const { columns,open } = this.state;
        const {items,editable,afterSave} = this.props;

        return (<div>                     
                     <div style={{ boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)' }}>
                        <div style={{ display:'flex',aligItems:'center',justifyContent:'space-between',padding:'1rem',backgroundColor:'white' }}>
                            <div>
                                <Button 
                                    onClick={this.handleOpen} 
                                    endIcon={<ArrowCircleRightIcon/>} 
                                    color='primary' 
                                    size="small" 
                                    variant="contained"
                                >
                                    Gyártás módosítása
                                </Button>
                            </div>
                            <div>                             
                            </div>
                        </div>
                    </div>
                    <DataTable
                        loading={false} 
                        pagination={50} 
                        items={items}
                        length={items.length} 
                        selected={[]} 
                        columns={columns}/>  
                    {open && 
                    <AlertDialog 
                        open={open} 
                        editable={editable} 
                        onClose={this.handleClose} 
                        saveObjects={items} 
                        initAfterSave={afterSave}/>}                                                                      
                </div>)
    }
}
export default Added;
