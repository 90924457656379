import { authenticationService } from 'services';
import { NotificationBar } from 'components';
import {UserStore} from 'stores';


export default function handleError(error) {

    if(error.response){
      if ([401, 403].indexOf(error.response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          authenticationService.logout();    
      }else{    
        if(error.response.data){
          if(error.response.data.message){        
            NotificationBar(error.response.data.message, 'top', 'right', 4000, 'error', null);
          }else{
            NotificationBar("Váratlan hiba történt", 'top', 'right', 3000, 'error', null);
          }       
        }else{
          NotificationBar("Váratlan hiba történt", 'top', 'right', 3000, 'error', null);
        }
      }
    }else if(error.request){
      if(error.toJSON().message==='Network Error'){
        NotificationBar("A kiszolgáló nem elérhető", 'top', 'right', 3000, 'error', null);
      }else{
        NotificationBar("Váratlan hiba történt", 'top', 'right', 3000, 'error', null);
      }
      console.log(error.toJSON());
    }else{
      console.log('Error', error.message);
      NotificationBar("Váratlan hiba történt", 'top', 'right', 3000, 'error', null);
    }
    return Promise.reject(error);
}