import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
//Material UI
import {
    AppBar,
    Toolbar,
    Badge,
    Hidden,
    IconButton,
    Typography,
    Tooltip,
    ListItem,
    List,
    Popover,
    ListItemText,
    ListSubheader,
    Divider,
    Button,
    ListItemSecondaryAction
} from '@mui/material';
//Material UI Icons
import MenuIcon from  '@mui/icons-material/Menu';
import NotificationsIcon from  '@mui/icons-material/NotificationsOutlined';
import InputIcon from  '@mui/icons-material/Input';
import MenuOpenIcon from  '@mui/icons-material/MenuOpen';
//Components
import {apiRequest} from './apiRequest';
import {Constants} from 'stores';
//Styles
import './style.css';

function NoteMenu(props){

  const {anchorEl,handleClose,data,handleConfirmNote,handleHideNote} = props;
   
  return <Popover
    id="notes-menu"
    anchorEl={anchorEl}
    keepMounted
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
      open={Boolean(anchorEl)}
    onClose={handleClose}
  >
      <Typography variant="h5" gutterBottom>
        Értesítések
      </Typography>
      <Divider/>
      {data!==null && data!==undefined && data.length<1 ?    
      <Typography variant="h4" gutterBottom>
        Nincs új értesítés
      </Typography>: 
      <List>
        {(data!==null && data!==undefined) && data.map((item,index)=>(
          <React.Fragment key={item.subHeader}>
            <ListSubheader>{item.subHeader}</ListSubheader>
            <Divider/>
            {item.items.map((subItem,index)=>(
              <ListItem key={subItem.id}>
                <ListItemText primary={subItem.primary} secondary={subItem.secondary} />             
                <ListItemSecondaryAction>
                 {subItem.confirmation===false && 
                  <Button onClick={()=>handleConfirmNote(subItem.id)} variant="outlined">
                      Nyugta
                  </Button>}
                  <span style={{padding:'2px'}}></span>
                  <Button onClick={()=>handleHideNote(subItem.id)} variant="outlined">
                      Elrejt
                  </Button>         
                </ListItemSecondaryAction>
             </ListItem>
            ))}
          </React.Fragment>
        ))}
      </List>}
</Popover>
}

class Topbar extends Component{

  constructor(props){
    super(props);
    this.state={
      notes:null,
      newNotes:null,
      allNotes:0,
      notifications:[]
    }
  }

  componentDidMount(){
    apiRequest.populateNotes(this);
    this.setUpSingalR();
  }

  componentWillUnmount(){
    const connections = Constants.getSignalRValue();

    connections.factory.on('ReportMissing');
  }

  componentDidUpdate(prevProps,prevState){
    const {allNotes} = this.state;
    if(prevState.allNotes!=allNotes){
      if(allNotes==0){
        this.setState({
          notes:null
        });
      }
    }
  }

  handleOpen=(event)=>{
    const target = event.currentTarget;
    this.setState({
      notes:target
    });
  }

  handleClose=()=>{
    this.setState({
      notes:null
    });
  }

  handleHideNote=(id)=>{
    apiRequest.hideNotes(id,this);
  }

  handleConfirmNote=(id)=>{
    apiRequest.confirmNotes(id,this);
  }

  setUpSingalR = () =>{
    const connections = Constants.getSignalRValue();
    connections.factory.on('ReportMissing', (users) => {
      apiRequest.populateNotes(this);
    });
  }

  render(){
    const { pathName, className, onSidebarOpen, openSidebar, onLogoutDialogOpen, ...rest } = this.props;
    const {notes,newNotes,allNotes,notifications} = this.state;
    return(
      <AppBar className='nav-top-bar' color='primary' {...rest}>
          <Toolbar style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
            <div style={{display:'flex', gap:'0.5rem', alignItems:'center'}}>
                <img
                   alt="Logo"
                   height="45"
                   width="50"
                   src="/images/logos/logo--app--white.png"
                 />                 
                <Hidden lgDown>
                    <Typography style={{fontWeight:'bold',color:'white'}}>Abex TTR</Typography>
                </Hidden>
                <Hidden lgUp>
                    <Typography style={{fontWeight:'bold',color:'white'}}>TTR</Typography>
                </Hidden>
            </div>
            <div>
            <Hidden lgDown>
            <Tooltip title="Értesítés">
              <span>
              <IconButton
                disabled={(allNotes===undefined || allNotes===null || allNotes<1) ? true : false}
                color="inherit"
                onClick={this.handleOpen}
              >
                <Badge
                  badgeContent={newNotes}
                  color="primary"                 
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              </span>
            </Tooltip>
            <NoteMenu 
              handleClose={this.handleClose} 
              anchorEl={notes} 
              data={notifications}
              handleHideNote={this.handleHideNote}
              handleConfirmNote={this.handleConfirmNote}
            />
            <Tooltip title="Kijelentkezés">
              <IconButton
                  color="inherit"
                  onClick={onLogoutDialogOpen}
              >
                <InputIcon />
              </IconButton>
            </Tooltip>
          </Hidden>
          <Hidden lgUp>                
            <IconButton
              color="inherit"
              onClick={onSidebarOpen}
            >
              {!openSidebar ? <MenuIcon /> : <MenuOpenIcon />}      
            </IconButton>
          </Hidden>
            </div>
      </Toolbar>
    </AppBar>
    );
  }
}

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
