class FilterModel{
    constructor(name,label,value,type,options,loading){
      this.name = name;
      this.label = label;
      this.value = value;
      this.type = type;
      this.options = options;
      this.loading = loading;
    }
  }

export default FilterModel;