import {Api,handleResponse,handleError} from 'api';
import { NotificationBar } from 'components';
//Actions
const Actions = {
    Send: 'metal/warehouse/send',//+{groupId}'
    Collect: 'metal/warehouse/collect'//+{groupId}'
}


export const apiRequest = {
    sendGroup,
    collectGroup,
};

async function sendGroup(selected,refresh,setRequest) {
    await new Api().call.post(`${Actions.Send}/${selected}`,null).then(handleResponse)
     .then((value) => {
          NotificationBar('A lejelentés megtörtént', 'top', 'right', 3000, 'success', null);
        refresh();
        setRequest(false);
        }).catch(handleError);
}

async function collectGroup(selected,refresh,setRequest) {
    await new Api().call.post(`${Actions.Collect}/${selected}`,null).then(handleResponse)
     .then((value) => {
          NotificationBar('A továbbítás megtörtént', 'top', 'right', 3000, 'success', null);
        refresh();
        setRequest(false);
        }).catch(handleError);

}
