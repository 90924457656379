const Types = {
    AJTO: 1,
    AJTOTOK:2,
    AJTOSZARNY:3,
    ABLAK:4,
    ABLAKTOK:5,
    ABLAKSZARNY:6,
    SOROLO:7
}

export default Types;
